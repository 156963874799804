import React from 'react';

import { ChevronDoubleDownIcon, ChevronDoubleUpIcon, ChevronDownIcon, ChevronUpIcon } from '@vlabs/icons';
import PropTypes from 'prop-types';

import './OrderSelector.sass';

const move = (arr, from, to) => {
  arr.splice(to, 0, arr.splice(from, 1)[0]);
  return arr;
};

function OrderSelector({
  list,
  setList,
  keyMap,
}) {
  return (
    <div className="OrderSelector">
      {list.map((column, idx) => {
        return (
          <div className="OrderSelector__Item" key={column}>
            {keyMap[column] || column}
            <div className="OrderSelector__Controls">
              {idx === 0 && (
                <>
                  <div className="OrderSelector__Empty" />
                  <div className="OrderSelector__Empty" />
                </>
              )}
              {idx > 0 && (
                <>
                  <button
                    type="button"
                    className="OrderSelector__Up"
                    onClick={() => { setList([...move(list, idx, 0)]); }}
                  >
                    {idx > 0 && <ChevronDoubleUpIcon />}
                  </button>
                  <button
                    type="button"
                    className="OrderSelector__Up"
                    onClick={() => { setList([...move(list, idx, idx - 1)]); }}
                  >
                    {idx > 0 && <ChevronUpIcon />}
                  </button>
                </>
              )}
              {idx === list.length - 1 && (
                <>
                  <div className="OrderSelector__Empty" />
                  <div className="OrderSelector__Empty" />
                </>
              )}
              {idx < list.length - 1 && (
                <>
                  <button
                    type="button"
                    className="OrderSelector__Down"
                    onClick={() => { setList([...move(list, idx, idx + 1)]); }}
                  >
                    {idx < list.length - 1 && <ChevronDownIcon />}
                  </button>
                  <button
                    type="button"
                    className="OrderSelector__Down"
                    onClick={() => { setList([...move(list, idx, list.length - 1)]); }}
                  >
                    {idx < list.length - 1 && <ChevronDoubleDownIcon />}
                  </button>
                </>
              )}
            </div>
          </div>
        );
      })}
    </div>
  );
}

OrderSelector.propTypes = {
  list: PropTypes.arrayOf(PropTypes.string),
  setList: PropTypes.func.isRequired,
  keyMap: PropTypes.shape({}),
};

OrderSelector.defaultProps = {
  list: [],
  keyMap: {},
};

export default OrderSelector;
