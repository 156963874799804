import React from 'react';

import {
  Page, Control, Margin,
} from '@vlabs/uikit';
import { FormProvider, useForm } from 'react-hook-form';
import { useTranslation } from 'react-i18next';

import './ListForm.sass';

function ListForm({
  name = '',
  onSubmit = () => {},
}) {
  const { t } = useTranslation();
  const { handleSubmit, register, formState: { errors } } = useForm({ defaultValues: { name } });
  return (
    <FormProvider>
      <form onSubmit={handleSubmit(onSubmit)}>
        <Page title={<Margin left bottom>{t('Список')}</Margin>} className="ListForm">
          <Margin display="block">
            <Control.Input
              label={t('Название списка')}
              id="name"
              {...register('name', {
                required: t('Название списка обязательно для заполнения'),
                maxLength: { value: 255, message: t('Должно быть не более {{count}} символов', { count: 255 }) },
              })}
              errors={errors}
            />
          </Margin>
          <Margin display="block">
            <Control.Button type="submit" fullWidth>
              {t('Сохранить')}
            </Control.Button>
          </Margin>
        </Page>
      </form>
    </FormProvider>
  );
}

export default ListForm;
