import React from 'react';

import { DownloadIcon } from '@vlabs/icons';
import PropTypes from 'prop-types';

const DownloadCell = ({
  url,
  row,
}) => {
  if (!url || !row) return null;

  const taskStatus = row.status;

  if (taskStatus !== 2) return null;

  return (
    <a href={url} download target="_self" style={{ color: 'unset' }}>
      <DownloadIcon
        data-testid="resultCell"
        className="Icon"
      />
    </a>
  );
};

DownloadCell.propTypes = {
  url: PropTypes.string,
  row: PropTypes.shape({
    status: PropTypes.oneOf([0, 1, 2, 3, 4]),
  }),
};

DownloadCell.defaultProps = {
  url: undefined,
  row: undefined,
};

export { DownloadCell };
