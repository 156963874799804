/* eslint-disable jsx-a11y/no-static-element-interactions */
/* eslint-disable jsx-a11y/click-events-have-key-events */
import React, { useCallback } from 'react';

import { Table, Panel, TableCells } from '@vlabs/uikit';
import cn from 'classnames';
import i18n from 'i18next';
import PropTypes from 'prop-types';
import { useTranslation } from 'react-i18next';
import { connect } from 'react-redux';

import { selectAccountRole } from 'features/accounts/selectors';
import { selectExtendedEvents } from 'features/extended-events/selectors';
import { selectScenarioOptions } from 'features/scenarios/selectors';
import { selectVehicleAttributes } from 'store/vehicle/selectors';

import CamCell from 'components/cam-cell/CamCell';
import InfoCell from 'components/info-cell/InfoCell';
import { updateExtendedParams } from 'features/extended-events/extendedEventsSlice';
import { can } from 'utils/can';

import '../events/EventsPage.sass';

const ExtendedEventsTable = ({
  scenarioIndicatorTypeOptions = [],
  scenarioOptions = [],
  eventsData,
  setSince,
  setUntil,
  setPageSize,
  role,
  onShowScenario,
  isFiltersVisible,
}) => {
  const { t } = useTranslation();

  const renderIdCell = useCallback(
    ({ row, value }) => (
      <a className="EventsPage__Link" href={`/extended-events/${row?.original?.id}`}>
        {value}
      </a>
    ), [],
  );

  const renderScenarioCell = useCallback(({ row, value: scenarioId }) => (
    can(role, 'scenarios:read') ? (
      <div
        className="EventsPage__Link"
        onClick={() => onShowScenario(row?.original?.scenarioId)}
      >
        {scenarioOptions.find(({ value }) => value === scenarioId)?.label || ''}
      </div>
    ) : (
      scenarioOptions.find(({ value }) => value === scenarioId)?.label || ''
    )
  ), [onShowScenario, role, scenarioOptions]);

  const renderEventTypeCell = useCallback(({ value: { indicatorTypeId, objectType } }) => {
    if (objectType === 'fire') {
      return i18n.t('Возгорание');
    }

    return (
      scenarioIndicatorTypeOptions.find(({ value }) => value === indicatorTypeId)?.label
      || ''
    );
  }, [scenarioIndicatorTypeOptions]);

  return (
    <div
      className={cn({
        EventsPage__Col: true,
        EventsPage__Col_full: !isFiltersVisible,
      })}
    >
      <Panel>
        <Table
          {...eventsData}
          paginationType="timeBased"
          setPageSize={setPageSize}
          setSince={setSince}
          setUntil={setUntil}
          columns={[
            {
              Header: t('ID'),
              accessor: 'id',
              Cell: renderIdCell,
              width: 35,
            },
            {
              Header: t('Камера'), accessor: 'cam', Cell: CamCell, width: 30,
            },
            {
              Header: t('Дата'),
              accessor: (row) => row.registeredAt || row.entryTime,
              Cell: TableCells.DateCell,
              width: 30,
            },
            {
              Header: t('Сценарий'),
              accessor: 'scenarioId',
              Cell: renderScenarioCell,
              width: 30,
            },
            {
              Header: t('Тип события'),
              accessor: (row) => ({
                indicatorTypeId: row?.indicatorTypeId,
                objectType: row?.objectType,
              }),
              Cell: renderEventTypeCell,
              width: 30,
            },
            {
              Header: t('Информация'),
              accessor: (values) => values,
              Cell: InfoCell,
            },
          ]}
        />
      </Panel>
    </div>
  );
};

const dropdownOptionPropTypes = PropTypes.shape({
  id: PropTypes.oneOfType([PropTypes.string, PropTypes.number]),
  value: PropTypes.oneOfType([PropTypes.string, PropTypes.number]),
});

ExtendedEventsTable.propTypes = {
  scenarioIndicatorTypeOptions: PropTypes.arrayOf(dropdownOptionPropTypes),
  scenarioOptions: PropTypes.arrayOf(dropdownOptionPropTypes),
  eventsData: PropTypes.shape({
    data: PropTypes.oneOfType([PropTypes.object, PropTypes.arrayOf(PropTypes.any)]),
  }),
  setSince: PropTypes.func,
  setUntil: PropTypes.func,
  setPageSize: PropTypes.func,
  role: PropTypes.number,
  onShowScenario: PropTypes.func,
  isFiltersVisible: PropTypes.bool,
};
ExtendedEventsTable.defaultProps = {
  scenarioIndicatorTypeOptions: [],
  scenarioOptions: [],
  eventsData: {},
  setSince: undefined,
  setUntil: undefined,
  setPageSize: undefined,
  role: undefined,
  onShowScenario: undefined,
  isFiltersVisible: true,
};

const $ExtendedEventsTable = connect((state) => ({
  ...selectVehicleAttributes(state),
  scenarioOptions: selectScenarioOptions(state),
  eventsData: selectExtendedEvents(state),
  role: selectAccountRole(state),
}), (dispatch) => ({
  setPageSize: (pageSize) => dispatch(updateExtendedParams({ pageSize })),
  setSince: (since) => dispatch(updateExtendedParams({ since })),
  setUntil: (until) => dispatch(updateExtendedParams({ until })),
}))(ExtendedEventsTable);

export {
  $ExtendedEventsTable as ExtendedEventsTable,
};
