import { v4 as uuidv4 } from 'uuid';

import WSClient from '../WSClient';

export class LunaCarsWSClient extends WSClient {
  constructor(baseURL) {
    super(baseURL);
    this.subscribers = {};
  }

  set onmessage(func) {
    if (!this.socket) {
      throw new Error('Socket is not connected');
    }

    this.socket.onmessage = ({ data }) => {
      const parsedMessage = JSON.parse(data);

      Object.values(this.subscribers)
        .forEach(({ triggerOn, callback }) => {
          if (triggerOn !== undefined && !triggerOn(parsedMessage)) return;
          callback(parsedMessage);
        });
    };
  }

  async disconnect() {
    await super.disconnect();
    if (this.socket) {
      this.subscribers = {};
    }
  }

  subscribe(callback, triggerOn) {
    const subscriptionId = uuidv4();

    this.subscribers[subscriptionId] = { callback, triggerOn };
    return {
      subscriptionId,
      unsubscribe: () => this.unsubscribe(subscriptionId),
    };
  }

  unsubscribe(subscriptionId) {
    if (subscriptionId === undefined) return;
    delete this.subscribers[subscriptionId];
  }

  destroy() {
    this.subscribers = {};
    this.disconnect();
  }
}
