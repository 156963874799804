import React from 'react';

import {
  Page, Control, Margin, regexp, Divider,
} from '@vlabs/uikit';
import { useForm } from 'react-hook-form';
import { useTranslation } from 'react-i18next';

import { lunaCarsClient } from 'api';

import './AccountForm.sass';

function AccountForm({
  name = '',
  email = '',
  role = undefined,
  roleOptions = [],
  onSubmit = () => {},
  onPasswordReset = undefined,
  isCreating = undefined,
}) {
  const { t } = useTranslation();
  const {
    control,
    register,
    handleSubmit,
    formState: { errors },
  } = useForm({
    defaultValues: { name, email, role },
  });

  const checkEmailExistance = async (value) => {
    if (onPasswordReset) return true;

    try {
      const response = await lunaCarsClient.accounts.exists({ email: value });
      return response.status === 200;
    } catch (e) {
      return false;
    }
  };

  const validateEmail = async (value) => {
    if (isCreating) return true;

    return !(await checkEmailExistance(value)) || t('Пользователь с таким email уже существует');
  };

  return (
    <form onSubmit={handleSubmit(onSubmit)}>
      <Page title={<Margin left>{t('Пользователь')}</Margin>} className="AccountForm">
        <Margin>
          <Divider small />
          <Control.Input
            label={t('ФИО')}
            id="account_name"
            {...register('name', {
              required: t('ФИО обязательно к заполнению'),
              maxLength: { value: 255, message: t('Должно быть не более {{count}} символов', { count: 255 }) },
            })}
            errors={errors}
          />
          <Divider small />
          <Control.Input
            label="Email"
            id="account_email"
            {...register('email', {
              required: t('Email обязателен к заполнению'),
              pattern: {
                value: regexp.email,
                message: t('Неверный формат ввода email'),
              },
              validate: {
                isUnregistered: validateEmail,
              },
            })}
            errors={errors}
          />
          <Divider small />
          <Control.Select
            label={t('Роль')}
            id="account_role"
            name="role"
            rules={{ required: t('Роль обязательна к заполнению') }}
            control={control}
            options={roleOptions}
            errors={errors}
          />
          <Divider />

          <Control.Button type="submit" fullWidth>
            {t('Сохранить')}
          </Control.Button>
          <Divider small />
          {onPasswordReset && (
            <Control.Button kind="warning" onClick={onPasswordReset} fullWidth>
              {t('Сбросить пароль')}
            </Control.Button>
          )}
        </Margin>
      </Page>
    </form>
  );
}

export default AccountForm;
