import { createSlice } from '@reduxjs/toolkit';
import i18next from 'i18next';
import { toast } from 'react-toastify';

import { lunaCarsClient } from 'api';
import { flattenFilters } from 'utils/utils';

import { REFETCH_INTERVAL } from './constants';

const extendedEventsSlice = createSlice({
  name: 'extendedEvents',
  initialState: {
    data: [],
    meta: {},
    filters: [],
    pageSize: 10,
    pageSizeOptions: [10, 25, 50],
    since: undefined,
    until: undefined,
    allIds: [],
    paginationType: 'timeBased',
    isFoldOpen: true,
    socketSubscriptionId: undefined,
  },
  reducers: {
    setRestApiData(state, { payload: { data, meta } }) {
      state.data = data;
      state.meta = meta;
      state.allIds = data.map(({ id }) => id);
    },
    setAsyncApiData: {
      reducer(state, { payload: { data, meta } }) {
        data.forEach((event, i) => {
          if (meta?.[i]?.operation === 'create') {
            state.data = [event, ...state.data].slice(0, state.pageSize);
          }
          if (meta?.[i]?.operation === 'update') {
            const index = state.data.findIndex(({ id }) => id === event.id);
            if (index === -1) return;
            state.data[index] = event;
          }
        });
      },
    },
    setParams(state, {
      payload: {
        // since и until поменяны местами, потому что направление списка от "свежих" к "старым"
        since: until,
        until: since,
        pageSize,
        filters,
        isFoldOpen,
      } = {},
    }) {
      if (filters !== undefined) {
        state.filters = filters;
        if (filters.length === 0) return;
      }
      if (isFoldOpen !== undefined) state.isFoldOpen = isFoldOpen;
      if (pageSize !== undefined) state.pageSize = pageSize;
      state.since = since ? since.registeredAt : undefined;
      state.until = until ? until.registeredAt : undefined;
    },
    setSocketSubscriptionId(state, { payload }) {
      if (state.socketSubscriptionId !== undefined) {
        lunaCarsClient.wsExtendedEvents.unsubscribe(state.socketSubscriptionId);
      }
      state.socketSubscriptionId = payload;
    },
  },
});

export default extendedEventsSlice.reducer;

export const { setAsyncApiData, setParams, setRestApiData } = extendedEventsSlice.actions;

export const fetchExtendedEvents = async (params) => lunaCarsClient.extendedEvents.showAll(params);

export const fetchExtendedEventsRest = async (dispatch, getState) => {
  const {
    extendedEvents: {
      pageSize, filters, since, until,
    },
  } = getState();

  // eslint-disable-next-line prefer-const
  let { data, status } = await lunaCarsClient.extendedEvents.showAll({
    ...flattenFilters(filters),
    since,
    until,
    pageSize,
  });
  if (status === 201) {
    toast.info(i18next.t('Задача  была создана, для просмотра перейдите в "Задачи"'));
    return;
  }
  if (data?.data?.length < pageSize && since !== undefined) {
    data = await lunaCarsClient.extendedEvents.showAll({
      ...flattenFilters(filters),
      pageSize,
    })
      .then(({ data: firstPageData }) => firstPageData);
  }

  await dispatch(setRestApiData(data));
};

export const updateExtendedEventUserNotes = async (eventId, data) => {
  try {
    await lunaCarsClient.extendedEvents.update(eventId, data);
    toast.success(i18next.t('Комментарий успешно добавлен'));
  } catch (error) {
    toast.error(`${i18next.t('Серверная ошибка')}: ${JSON.stringify(error.response?.data)}`);
    throw error;
  }
};

export const connectAsyncExtendedEvents = async (dispatch, getState) => {
  const {
    appSettings: { data },
  } = getState();
  if (data?.enabledWebSocketUpdates) {
    const { subscriptionId } = lunaCarsClient.wsExtendedEvents.subscribe((msg) => dispatch(setAsyncApiData(msg)));
    dispatch(extendedEventsSlice.actions.setSocketSubscriptionId(subscriptionId));
  } else {
    const subscriptionId = setInterval(() => {
      dispatch(fetchExtendedEventsRest);
    }, REFETCH_INTERVAL);
    dispatch(extendedEventsSlice.actions.setSocketSubscriptionId(subscriptionId));
  }
};

export const disconnectAsyncExtendedEvents = (dispatch, getState) => {
  const { extendedEvents: { subscriptionId } } = getState();
  lunaCarsClient.wsExtendedEvents.unsubscribe(subscriptionId);
  dispatch(extendedEventsSlice.actions.setSocketSubscriptionId(undefined));
};

export const updateExtendedParams = (params) => async (dispatch, getState) => {
  const { extendedEvents: { filters: stateFilters } } = getState();
  const { filters } = params;

  // Если у происходит переход на нулевую страницу без фильтров, то подключаем повторно
  // websocket для непрерывного обновления данных. В случае если задан какой-либо из фильтров,
  // либо пользователь находится на странице, отличной от 0 — отключаем непрерывное обновление.
  const isAnyStateFilters = stateFilters.length !== 0 && filters === undefined;
  let isAnyFilters = false;

  if (filters !== undefined) {
    isAnyFilters = filters.some(
      ({ value }) => value !== '' && value?.length !== 0,
    );
  }

  await dispatch(setParams(params));

  // При юзкейсе, когда мы находимся на страинце, отличной от нулевой и меняем размер страницы
  // генерируется 2 события — одно для смены размера страницы, другое для перехода на страницу
  // с индексом 0, в результате чего выполняется подряд 2 запроса к серверу, один из которых
  // зачастую оказывается нерелевантным. (http://git.visionlabs.ru/frontend/uikit/-/issues/25)
  await dispatch(fetchExtendedEventsRest);

  const { extendedEvents: { meta: { sinceAvailable } } } = getState();
  if (!sinceAvailable && !isAnyStateFilters && !isAnyFilters) {
    dispatch(connectAsyncExtendedEvents);
  } else if (!isAnyStateFilters) {
    dispatch(disconnectAsyncExtendedEvents);
  }
};
