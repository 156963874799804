import { createSlice } from '@reduxjs/toolkit';

import { lunaCarsClient } from 'api';

const settingsSlice = createSlice({
  name: 'settings',
  initialState: {
    data: null,
  },
  reducers: {
    setSettings(state, { payload }) {
      state.data = payload;
    },
  },
});

export default settingsSlice.reducer;

export const {
  setSettings,
} = settingsSlice.actions;

export const fetchSettings = async (dispatch) => {
  try {
    const { data } = await lunaCarsClient.settings.showAll();
    dispatch(setSettings(data));
  } catch (error) {
    if (!error.response) throw error;
  }
};
