import React, { useEffect } from 'react';

import {
  Page, Divider, Grid, GridCol, GridRow, Fold, Control, SettingsItemWrapper,
} from '@vlabs/uikit';
import { useFormContext } from 'react-hook-form';
import { useTranslation } from 'react-i18next';

import ItemSubForm from 'features/scenarios/ItemSubForm';

import './ScenarioForm.sass';

function ScenarioForm({
  scenarioIndicatorTypeOptions,
  scenarioTypeOptions,
  listOptions,
}) {
  const { t } = useTranslation();
  const {
    register,
    control,
    formState: { errors },
    setValue,
    watch,
    getValues,
  } = useFormContext();

  const onAction = (
    <Control.Button
      type="submit"
      form="scenario-form"
      hollow
      className="ScenarioForm__OnActionBtn"
    >
      {t('Сохранить')}
    </Control.Button>
  );
  useEffect(() => {
    const sub = watch((data, { name }) => {
      if (name === 'scenarioType') {
        setValue('indicatorTypeId', '');
      }
    });

    return () => sub.unsubscribe();
  // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [watch, getValues]);

  return (
    <Page
      actions={onAction}
      breadcrumbs={[
        { caption: t('Сценарии'), link: '/scenarios' },
        { caption: watch('title') || t('Создание сценария') },
      ]}
    >
      <Divider small />
      <Grid>
        <GridRow>
          <GridCol cols={6}>
            <Fold title={t('Общие')} isOpen>
              <SettingsItemWrapper title={t('Наименование')}>
                <Control.Input
                  {...register('title', {
                    required: t('Поле обязательно для заполнения'),
                  })}
                  errors={errors}
                />
              </SettingsItemWrapper>
              <SettingsItemWrapper title={t('Тип сценария')}>
                <div className="ScenarioForm__Dropdown">
                  <Control.Select
                    name="scenarioType"
                    options={scenarioTypeOptions}
                    control={control}
                    rules={{ required: t('Поле обязательно для заполнения') }}
                    errors={errors}
                  />
                </div>
              </SettingsItemWrapper>
              {watch('scenarioType')?.value === 'event' && (
              <SettingsItemWrapper title={t('Тип события')}>
                <div className="ScenarioForm__Dropdown">
                  <Control.Select
                    name="indicatorTypeId"
                    control={control}
                    options={scenarioIndicatorTypeOptions.filter(({ type }) => type === 'event')}
                    rules={{ required: t('Поле обязательно для заполнения') }}
                    errors={errors}
                  />
                </div>
              </SettingsItemWrapper>
              )}
              {watch('scenarioType')?.value === 'incident' && (
              <SettingsItemWrapper title={t('Тип инцидента')}>
                <div className="ScenarioForm__Dropdown">
                  <Control.Select
                    name="indicatorTypeId"
                    control={control}
                    options={scenarioIndicatorTypeOptions.filter(({ type }) => type === 'incident')}
                    rules={{ required: t('Поле обязательно для заполнения') }}
                    errors={errors}
                  />
                </div>
              </SettingsItemWrapper>
              )}
              <SettingsItemWrapper title={t('Статус')}>
                <Control.Switch
                  name="statusIsOn"
                  control={control}
                  errors={errors}
                />
              </SettingsItemWrapper>
            </Fold>
          </GridCol>

          <GridCol cols={6}>
            <Fold title={t('Связанные списки')} isOpen>
              <ItemSubForm
                filteredListName="lists"
                listOptions={listOptions}
              />
            </Fold>
          </GridCol>
        </GridRow>
      </Grid>
    </Page>
  );
}
export default ScenarioForm;
