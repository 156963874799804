import { handleError } from 'components/error-toasts';

export const registerErrorHandlers = () => {
  window.addEventListener('error', (event) => {
    handleError(event.reason);
    event.preventDefault();
  });

  window.addEventListener('unhandledrejection', (event) => {
    handleError(event.reason);
    event.preventDefault();
  });
};
