import React, { useState } from 'react';

import { Control, Divider, ROI } from '@vlabs/uikit';
import PropTypes from 'prop-types';
import { TwitterPicker } from 'react-color';
import { useController, useForm } from 'react-hook-form';
import { useTranslation } from 'react-i18next';

const DEFAULT_DROI_COLOR = '#008000';

const DroiForm = ({
  name,
  onSubmit,
  selectedRegion,
  // ROI
  roi,
  onCancel,
  previewUri,
}) => {
  const { t } = useTranslation();

  const {
    points,
    tagName = '',
    eventRegistration = false,
    displayColor = DEFAULT_DROI_COLOR,
  } = (selectedRegion || {});

  const [droi, setDroi] = useState(points);

  const {
    handleSubmit,
    register,
    control,
    formState: { errors },
  } = useForm({
    defaultValues: {
      points,
      tagName,
      eventRegistration,
    },
  });

  const { field: roiField } = useController({
    name,
    control,
    defaultValue: points,
  });

  const { field: colorField } = useController({
    name: 'displayColor',
    control,
    defaultValue: displayColor,
  });

  const $onSubmit = (event) => {
    event.stopPropagation();
    roiField.onChange(droi);
    handleSubmit(onSubmit)(event);
  };

  return (
    <form
      id="droi-form"
      onSubmit={$onSubmit}
    >
      <ROI
        roi={roi}
        onCancel={onCancel}
        preview={previewUri}
        onROISave={setDroi}
        droi={roiField.value}
        color={colorField.value}
        isNormalized={false}
        maxModalSizeRatio={0.6}
        editable
      />

      <Divider />

      <Control.Input
        label={t('Название зоны распознавания')}
        errors={errors}
        {...register('tagName', {
          required: t('Название зоны обязательно для заполнения'),
          maxLength: {
            value: 255,
            message: t('Должно быть не более {{count}} символов', { count: 255 }),
          },
        })}
      />

      <Divider />

      <span>
        {t('Цвет')}
      </span>

      <TwitterPicker
        color={colorField.value}
        onChangeComplete={({ hex }) => colorField.onChange(hex)}
        colors={[
          '#008000', '#0000FF', '#DDA0DD', '#800000', '#FF6347', '#800080',
          '#FFA500', '#008080', '#FA8072', '#000080', '#D2691E', '#FF80FF',
        ]}
        width="auto"
        triangle="hide"
      />

      <Divider />

      <Control.Button
        type="submit"
        fullWidth
        form="droi-form"
        disabled={!droi?.length}
      >
        {t('Сохранить')}
      </Control.Button>
    </form>
  );
};

DroiForm.propTypes = {
  name: PropTypes.string.isRequired,
  onSubmit: PropTypes.func,
  selectedRegion: PropTypes.shape({
    points: PropTypes.arrayOf(PropTypes.shape({
      x: PropTypes.number,
      y: PropTypes.number,
    })),
    tagName: PropTypes.string,
    eventRegistration: PropTypes.bool,
    displayColor: PropTypes.string,
  }),
  roi: PropTypes.object,
  onCancel: PropTypes.func,
  previewUri: PropTypes.string,
};
DroiForm.defaultProps = {
  onSubmit: () => { },
  selectedRegion: undefined,
  roi: undefined,
  onCancel: undefined,
  previewUri: undefined,
};

export { DroiForm };
