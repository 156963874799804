import React, { useCallback, useEffect, useMemo, useState } from 'react';

import { BackspaceIcon, ChevronDownIcon, ChevronRightIcon, CircleInfoIcon, SquareCheckIcon, SquareEmptyIcon, SquareMinusIcon, SquarePlusIcon } from '@vlabs/icons';
import { Control, Grid, GridCol, GridRow, Margin, openConfirmPopup, Page, Tab, Tabs, Tooltip } from '@vlabs/uikit';
import PropTypes from 'prop-types';
import CheckboxTree from 'react-checkbox-tree';
import { useTranslation } from 'react-i18next';
import { connect } from 'react-redux';

import { selectXLSXExportSettings } from 'store/export-settings/selectors';

import './ExportForm.sass';
import OrderSelector from './OrderSelector';

function ExportForm({
  settings,
  onSubmit,
  recordType,
}) {
  const { t } = useTranslation();
  const [checkedColumns, setCheckedColumns] = useState(settings.columns.defaults);
  const [expandedColumns, setExpandedColumns] = useState([]);
  const [checkedGroups, setCheckedGroups] = useState(settings.groups.defaults);
  const [expandedGroups, setExpandedGroups] = useState([]);

  const handleSubmit = useCallback(async () => {
    try {
      await onSubmit({
        exportSettings: {
          columns: checkedColumns,
          groups: checkedGroups,
          recordType,
        },
      });
    } catch (e) {
      if (!e?.response?.data) throw e;
      const { validColumns, validGroups } = e.response.data;
      if (validColumns || validGroups) {
        openConfirmPopup({
          title: t('events:export.confirm.invalid settings.title'),
          message: (
            <span>
              {t('events:export.confirm.invalid settings.body')}
            </span>
          ),
          type: 'warning',
          onConfirm: () => {
            onSubmit({
              exportSettings: {
                columns: validColumns || checkedColumns,
                groups: validGroups || checkedGroups,
                recordType,
              },
            });
          },
        });
        return;
      }
      throw e;
    }
  }, [onSubmit, checkedColumns, checkedGroups, recordType, t]);

  const resetColumns = useCallback(
    () => { setCheckedColumns(settings.columns.defaults); },
    [setCheckedColumns, settings.columns.defaults],
  );
  const resetGroups = useCallback(
    () => { setCheckedGroups(settings.groups.defaults); },
    [setCheckedGroups, settings.groups.defaults],
  );
  useEffect(() => { resetColumns(); }, [settings.columns.defaults, resetColumns]);
  useEffect(() => { resetGroups(); }, [settings.groups.defaults, resetGroups]);

  const icons = useMemo(() => ({
    check: <SquareCheckIcon />,
    uncheck: <SquareEmptyIcon />,
    halfCheck: <SquareMinusIcon />,
    expandClose: <ChevronRightIcon />,
    expandOpen: <ChevronDownIcon />,
    expandAll: <SquarePlusIcon />,
    collapseAll: <SquareMinusIcon />,
  }), []);

  return (
    <Page className="ExportForm">
      <Margin bottom display="block">
        <h5>
          {t('events:export.title')}
        </h5>
      </Margin>
      <div className="ExportForm__SelectableFields">
        <Grid>
          <GridRow>
            <GridCol>
              <h6>
                {t('events:export.columns.title')}
                <Tooltip
                  tooltipProps={{ effect: 'solid', place: 'right' }}
                  className="Tooltip_export"
                  data-tip={t('events:export.columns.tip')}
                  id="columnsTooltip"
                >
                  <CircleInfoIcon className="Tooltip_icon" />
                </Tooltip>
              </h6>
              <Tabs>
                <Tab header={t('events:export.columns.presense')} name="columnsPresence">
                  <CheckboxTree
                    nodes={settings.columns.tree}
                    checked={checkedColumns}
                    expanded={expandedColumns}
                    onCheck={(checked) => setCheckedColumns(checked)}
                    onExpand={(expanded) => setExpandedColumns(expanded)}
                    icons={icons}
                    showNodeIcon={false}
                    showExpandAll
                  />
                </Tab>
                <Tab header={t('events:export.columns.order')} name="columnsorder">
                  <OrderSelector
                    list={checkedColumns}
                    setList={setCheckedColumns}
                    keyMap={settings.columns.keyMap}
                  />
                </Tab>
              </Tabs>
            </GridCol>
            <GridCol>
              <h6>
                {t('events:export.groups.title')}
                <Tooltip
                  tooltipProps={{ effect: 'solid', place: 'right' }}
                  className="Tooltip_export"
                  data-tip={t('events:export.groups.tip')}
                  id="groupsTooltip"
                >
                  <CircleInfoIcon className="Tooltip_icon" />
                </Tooltip>
              </h6>
              <Tabs>
                <Tab header={t('events:export.columns.presense')} name="groupsPresence">
                  <CheckboxTree
                    nodes={settings.groups.tree}
                    checked={checkedGroups}
                    expanded={expandedGroups}
                    onCheck={(checked) => setCheckedGroups(checked)}
                    onExpand={(expanded) => setExpandedGroups(expanded)}
                    icons={icons}
                    showNodeIcon={false}
                    showExpandAll
                  />
                </Tab>
                <Tab header={t('events:export.columns.order')} name="groupsOrder">
                  <OrderSelector
                    list={checkedGroups}
                    setList={setCheckedGroups}
                    keyMap={settings.groups.keyMap}
                  />
                </Tab>
              </Tabs>
            </GridCol>
          </GridRow>
        </Grid>
      </div>
      <div className="ExportForm__Footer">
        <div className="ExportForm__Controls">
          <Control.Button
            onClick={handleSubmit}
          >
            {t('events:export.submit')}
          </Control.Button>
          <button
            type="button"
            className="ExportForm__ResetFieldsBtn"
            onClick={() => {
              resetColumns();
              resetGroups();
            }}
          >
            {t('events:export.reset')}
            <BackspaceIcon />
          </button>
        </div>
      </div>
    </Page>
  );
}

ExportForm.propTypes = {
  settings: PropTypes.shape({
    groups: PropTypes.shape({
      tree: PropTypes.arrayOf(PropTypes.shape({})),
      defaults: PropTypes.arrayOf(PropTypes.string),
      keyMap: PropTypes.shape({}),
    }),
    columns: PropTypes.shape({
      tree: PropTypes.arrayOf(PropTypes.shape({})),
      defaults: PropTypes.arrayOf(PropTypes.string),
      keyMap: PropTypes.shape({}),
    }),
  }),
  onSubmit: PropTypes.func.isRequired,
  recordType: PropTypes.oneOf(['event', 'incident']).isRequired,
};

ExportForm.defaultProps = {
  settings: {
    groups: [],
    columns: [],
  },
};

export default connect((state) => ({
  settings: selectXLSXExportSettings(state),
}))(ExportForm);
