import React from 'react';

import {
  ClockIcon, ListIcon, VideoIcon,
  PersonsIcon, TriangleExclamationIcon,
  ClipboardIcon, LogOutIcon, SlidersIcon,
} from '@vlabs/icons';
import {
  Layout, Account, AccountMenuLink, AccountMenuButton,
} from '@vlabs/uikit';
import { useTranslation } from 'react-i18next';
import '../translations/i18n';
import { useDispatch, useSelector } from 'react-redux';

import { selectAccountRole, selectAccount } from 'features/accounts/selectors';
import { selectUnreadCount } from 'features/incidents/selectors';
import { selectSettings } from 'store/app-settings/selectors';

import { ROLE_OPTIONS } from 'api-bindings/luna-cars/constants';
import { logout } from 'features/accounts/accountSlice';
import { can } from 'utils/can';

const Navigation = () => {
  const { t } = useTranslation();

  const { model } = useSelector(selectAccount);
  const role = useSelector(selectAccountRole);
  const unreadCount = useSelector(selectUnreadCount);
  const settings = useSelector(selectSettings);
  const { isAuthenticated } = useSelector(selectAccount);
  const dispatch = useDispatch();

  if (!isAuthenticated) return null;

  return (
    <Layout.Navbar>
      {can(role, 'events:read') && settings?.enabledExtendedEvents && (
        <Layout.NavbarLink
          icon={<ClockIcon />}
          to="/extended-events"
        >
          {t('Расширенные события')}
        </Layout.NavbarLink>
      )}

      {can(role, 'events:read') && (
        <Layout.NavbarLink
          icon={<ClockIcon />}
          to="/events"
        >
          {t('События')}
        </Layout.NavbarLink>
      )}

      {can(role, 'incidents:read') && (
        <Layout.NavbarLink
          icon={<TriangleExclamationIcon />}
          to="/incidents"
          counter={unreadCount}
        >
          {t('Инциденты')}
        </Layout.NavbarLink>
      )}

      {can(role, 'lists:read') && (
        <Layout.NavbarLink
          icon={<ListIcon />}
          to="/lists"
        >
          {t('Списки')}
        </Layout.NavbarLink>
      )}

      {can(role, 'scenarios:read') && (
        <Layout.NavbarLink
          icon={<SlidersIcon />}
          to="/scenarios"
        >
          {t('Сценарии')}
        </Layout.NavbarLink>
      )}

      {can(role, 'cams:read') && (
        <Layout.NavbarLink
          icon={<VideoIcon />}
          to="/cams"
        >
          {t('Камеры')}
        </Layout.NavbarLink>
      )}

      {can(role, 'accounts:read') && (
        <Layout.NavbarLink
          icon={<PersonsIcon />}
          to="/accounts"
        >
          {t('Пользователи')}
        </Layout.NavbarLink>
      )}

      <Account name={model.name} role={ROLE_OPTIONS[role]?.label}>
        {can(role, 'tasks:read') && (
        <AccountMenuLink to="/tasks" icon={<ClipboardIcon />}>
          {t('Задачи')}
        </AccountMenuLink>
        )}
        <AccountMenuButton
          onClick={() => dispatch(logout)}
          icon={<LogOutIcon />}
        >
          {t('Выйти')}
        </AccountMenuButton>
      </Account>
    </Layout.Navbar>
  );
};

export default Navigation;
