/* eslint-disable jsx-a11y/no-static-element-interactions */
/* eslint-disable jsx-a11y/click-events-have-key-events */
import React, { useEffect, useCallback } from 'react';

import {
  Page,
  Panel,
  Table,
  TableCells,
  openConfirmPopup,
  Divider,
  TableFilters,
} from '@vlabs/uikit';
import qs from 'qs';
import { useTranslation } from 'react-i18next';
import { connect, useDispatch } from 'react-redux';
import { useHistory } from 'react-router-dom';

import { selectVehicleAttributes } from 'store/vehicle/selectors';

import { SCENARIO_TYPE_OPTIONS, SCENARIO_STATUS_OPTIONS } from 'api-bindings/luna-cars/constants';
import HandlersCell from 'components/handlers-cell/HandlersCell';
import ScenarioListsCell from 'components/scenario-lists-cell/ScenarioListsCell';
import { actionColumnProps } from 'utils/helpers';

import {
  setCurrentScenario, setHandlers, deleteScenario,
  updatePage, updatePageSize, updateFilters, defaultScenario,
} from './scenariosSlice';
import { selectScenarios } from './selectors';

import './ScenarioListPage.sass';

function ScenarioListPage({
  handlerTypeOptions = [],
  setHandlers = undefined,
  scenariosData = [],
  deleteScenario = undefined,
  setFilters = undefined,
  setPageSize = undefined,
  setPageIndex = undefined,
}) {
  const { t } = useTranslation();
  const history = useHistory();
  const dispatch = useDispatch();

  useEffect(() => {
    dispatch(setHandlers({ data: [], meta: {} }));
    dispatch(setCurrentScenario(defaultScenario));
  }, [dispatch, setHandlers]);

  const onCreateScenario = () => history.push('/scenarios/create');
  const onEditScenario = useCallback(({ id }) => history.push(`/scenarios/${id}`), [history]);

  const onDeleteScenario = (scenario) => {
    const message = (
      <span>
        {t('Вы уверены, что хотите удалить сценарий')}
        &nbsp;
        <strong>{scenario.title}</strong>
        ?
      </span>
    );

    const onConfirm = async () => {
      await deleteScenario(scenario.id);
    };

    openConfirmPopup({
      title: t('Удаление сценария'),
      message,
      type: 'delete',
      onConfirm,
    });
  };

  const onShowEvents = useCallback(({ id, scenarioType }) => {
    const params = {
      id: 'scenarioId',
      value: [id],
    };
    if (scenarioType === 'event') {
      history.push({
        pathname: '/events',
        search: qs.stringify(params),
        state: { id },
      });
    }
    if (scenarioType === 'incident') {
      history.push({
        pathname: '/incidents',
        search: qs.stringify(params),
        state: { id },
      });
    }
  }, [history],
  );

  const renderTitleCell = useCallback(
    ({ row, value }) => (
      <div className="ScenarioListPage__Link" onClick={() => onEditScenario(row?.original)}>
        {value}
      </div>
    ), [onEditScenario],
  );

  const renderCountCell = useCallback(
    ({ row, value }) => (
      <div className="ScenarioListPage__Link" onClick={() => onShowEvents(row?.original)}>
        {value}
      </div>
    ), [onShowEvents],
  );

  const renderListsCell = useCallback(
    (row) => <ScenarioListsCell lists={row?.lists} />,
    [],
  );

  const renderHandlersCell = useCallback(
    (row) => (
      <HandlersCell
        handlers={row?.handlerTemplates}
        handlerTypeOptions={handlerTypeOptions}
      />
    ), [handlerTypeOptions],
  );

  const renderStatusCell = useCallback(
    ({ value }) => (value
      ? (<span className="ScenarioListPage__IsOn">{t('Включен')}</span>)
      : (<span className="ScenarioListPage__IsOff">{t('Отключен')}</span>)
    ), [t],
  );

  return (
    <Page
      breadcrumbs={[{ caption: t('Сценарии') }]}
    >
      <Divider small />
      <Panel>
        <Table
          data={scenariosData.data}
          filters={scenariosData.filters}
          pageIndex={scenariosData.pageIndex}
          pageSize={scenariosData.pageSize}
          pageSizeOptions={scenariosData.pageSizeOptions}
          setFilters={setFilters}
          setPageIndex={setPageIndex}
          setPageSize={setPageSize}
          paginationType="offsetBased"
          columns={[
            {
              Header: t('Наименование'),
              accessor: 'title',
              Cell: renderTitleCell,
              Filter: TableFilters.TextFilter(),
            },
            {
              Header: t('Тип'),
              accessor: 'scenarioType',
              Cell: ({ value: scenarioType }) => {
                const scenarioTypeOption = SCENARIO_TYPE_OPTIONS[scenarioType];
                return scenarioTypeOption ? scenarioTypeOption.label : scenarioType;
              },
              Filter: TableFilters.OneOfFilter({ options: SCENARIO_TYPE_OPTIONS.raw }),
            },
            {
              Header: t('Количество'),
              accessor: 'registeredObjectsCount',
              Cell: renderCountCell,
            },
            { Header: t('Списки'), accessor: renderListsCell },
            {
              Header: t('Обработчики'),
              accessor: renderHandlersCell,
            },
            { Header: t('Дата создания'), accessor: 'createdAt', Cell: TableCells.DateCell, Filter: TableFilters.DateFilter() },
            {
              Header: t('Статус'),
              accessor: 'statusIsOn',
              Cell: renderStatusCell,
              Filter: TableFilters.OneOfFilter({ options: SCENARIO_STATUS_OPTIONS.raw }),
            },
            actionColumnProps({
              id: 'edit',
              Cell: TableCells.EditCell,
              width: 30,
            }),
            actionColumnProps({
              id: 'delete',
              Cell: TableCells.DeleteCell,
              width: 30,
            }),
          ]}
          onActions={{
            onAddRow: { handler: onCreateScenario },
            onEditRow: { handler: onEditScenario },
            onDeleteRow: { handler: onDeleteScenario },
          }}
        />
      </Panel>
    </Page>
  );
}
export default connect((state) => ({
  ...selectVehicleAttributes(state),
  scenariosData: selectScenarios(state),
}),
(dispatch) => ({
  setHandlers: (handlers) => dispatch(setHandlers(handlers)),
  deleteScenario: (scenarioId) => dispatch(deleteScenario(scenarioId)),
  setFilters: (filters) => dispatch(updateFilters({ filters })),
  setPageSize: (pageSize) => dispatch(updatePageSize({ pageSize })),
  setPageIndex: (pageIndex) => dispatch(updatePage({ pageIndex })),
}))(ScenarioListPage);
