import { createSlice } from '@reduxjs/toolkit';
import i18next from 'i18next';
import { toast } from 'react-toastify';

import { lunaCarsClient } from 'api';
import { flattenFilters } from 'utils/utils';

import { REFETCH_INTERVAL } from './constants';

const incidentsSlice = createSlice({
  name: 'incidents',
  initialState: {
    data: [],
    meta: {
      unreadCount: 0,
    },
    filters: [],
    pageSize: 10,
    pageSizeOptions: [10, 25, 50],
    since: undefined,
    until: undefined,
    allIds: [],
    filterListIds: [],
    paginationType: 'timeBased',
    isFoldOpen: true,
    asyncDataSubscriptionId: undefined,
    unreadCountSubscriptionId: undefined,
  },
  reducers: {
    setRESTData(state, { payload: { data, meta } }) {
      state.data = data;
      state.meta = meta;
      state.allIds = data.map(({ id }) => id);
    },
    setAsyncData: {
      reducer(state, { payload: { data, meta } }) {
        const removedIndexArray = [];
        data.forEach((incident, i) => {
          if (state.filterListIds?.length === 0) return;
          if (!incident.lists.find(({ id }) => state.filterListIds.includes(id))) {
            removedIndexArray.push(i);
          }
        });
        removedIndexArray.reverse().forEach((i) => {
          data.splice(i, 1);
          meta.splice(i, 1);
        });
        data.forEach((incident, i) => {
          if (meta?.[i]?.operation === 'create') {
            state.data = [incident, ...state.data].slice(0, state.pageSize);
          }
          if (meta?.[i]?.operation === 'update') {
            const index = state.data.findIndex(({ id }) => id === incident.id);
            if (index === -1) return;
            state.data[index] = incident;
          }
        });
      },
    },
    setUnreadCount(state, { payload }) {
      state.meta.unreadCount = payload;
    },
    setParams(state, {
      payload: {
        // since и until поменяны местами, потому что направление списка от "свежих" к "старым"
        since: until, until: since, pageSize, filters, isFoldOpen,
      } = {},
    }) {
      if (filters !== undefined) {
        state.filters = filters;
        if (filters.length === 0) return;
        state.filterListIds = filters?.find(({ id }) => id === 'listId')?.value;
      }
      if (isFoldOpen !== undefined) state.isFoldOpen = isFoldOpen;
      if (pageSize !== undefined) state.pageSize = pageSize;
      state.since = since ? since.registeredAt : undefined;
      state.until = until ? until.registeredAt : undefined;
    },
    updateIncident(state, { payload: { id, data } }) {
      if (!data.isUnread) { state.meta.unreadCount -= 1; }
      const incident = state.data.find(({ id: storedId }) => storedId === id);
      Object.assign(incident, data);
    },
    setAsyncDataSubscriptionId(state, { payload }) {
      if (state.asyncDataSubscriptionId !== undefined) {
        if (typeof payload === 'number') {
          clearInterval(state.asyncDataSubscriptionId);
        } else {
          lunaCarsClient.wsIncidents.unsubscribe(state.asyncDataSubscriptionId);
        }
      }
      state.asyncDataSubscriptionId = payload;
    },
    setUnreadCountSubscriptionId(state, { payload }) {
      if (state.unreadCountSubscriptionId !== undefined) {
        lunaCarsClient.wsIncidents.unsubscribe(state.unreadCountSubscriptionId);
      }
      state.unreadCountSubscriptionId = payload;
    },
  },
});

export default incidentsSlice.reducer;

export const {
  setAsyncData,
  setParams,
  setRESTData,
  updateIncident,
} = incidentsSlice.actions;

export const fetchIncidentsREST = async (dispatch, getState) => {
  const {
    incidents: {
      pageSize, filters, since, until,
    },
  } = getState();
  let { data } = await lunaCarsClient.incidents.showAll({
    ...flattenFilters(filters),
    since,
    until,
    pageSize,
  });
  if (data?.data?.length < pageSize && since !== undefined) {
    data = await lunaCarsClient.incidents.showAll({
      ...flattenFilters(filters),
      pageSize,
    })
      .then(({ data: firstPageData }) => firstPageData);
  }
  await dispatch(setRESTData(data));
};

export const updateIncidentUserNotes = async (incidentId, data) => {
  try {
    await lunaCarsClient.incidents.update(incidentId, data);
    toast.success(i18next.t('Комментарий успешно добавлен'));
  } catch (error) {
    toast.error(`${i18next.t('Серверная ошибка')}: ${JSON.stringify(error.response?.data)}`);
    throw error;
  }
};

export const connectAsyncIncidents = async (dispatch, getState) => {
  const {
    appSettings: { data },
  } = getState();
  if (data?.enabledWebSocketUpdates) {
    const { subscriptionId } = lunaCarsClient.wsIncidents.subscribe(
      (msg) => dispatch(setAsyncData(msg)),
      ({ data }) => data !== undefined,
    );
    dispatch(incidentsSlice.actions.setAsyncDataSubscriptionId(subscriptionId));
  } else {
    const subscriptionId = setInterval(() => {
      dispatch(fetchIncidentsREST);
    }, REFETCH_INTERVAL);
    dispatch(incidentsSlice.actions.setAsyncDataSubscriptionId(subscriptionId));
  }
};

export const disconnectAsyncIncidents = (dispatch, getState) => {
  const { events: { subscriptionId } } = getState();
  lunaCarsClient.wsIncidents.unsubscribe(subscriptionId);
  dispatch(incidentsSlice.actions.setAsyncDataSubscriptionId(undefined));
};

export const connectUnreadIncidentsCount = (dispatch) => {
  const { subscriptionId } = lunaCarsClient.wsIncidents.subscribe(
    ({ unreadCount }) => dispatch(incidentsSlice.actions.setUnreadCount(unreadCount)),
    ({ unreadCount }) => unreadCount !== undefined,
  );
  dispatch(incidentsSlice.actions.setUnreadCountSubscriptionId(subscriptionId));
};

export const disconnectUnreadIncidentsCount = (dispatch) => {
  const { subscriptionId } = lunaCarsClient.wsIncidents.subscribe(
    ({ unreadCount }) => dispatch(incidentsSlice.actions.setUnreadCount(unreadCount)),
    ({ unreadCount }) => unreadCount !== undefined,
  );
  dispatch(incidentsSlice.actions.setUnreadCountSubscriptionId(subscriptionId));
};

export const updateParams = (params) => async (dispatch, getState) => {
  const { incidents: { filters: stateFilters } } = getState();
  const { filters } = params;

  // Если у происходит переход на нулевую страницу без фильтров, то подключаем повторно
  // websocket для непрерывного обновления данных. В случае если задан какой-либо из фильтров,
  // либо пользователь находится на странице, отличной от 0 - отключаем непрерывное обновление.
  const isAnyStateFilters = stateFilters.length !== 0 && filters === undefined;
  let isAnyFiltersExceptLists = false;

  if (filters !== undefined) {
    const filtersExceptLists = filters.filter(({ id }) => id !== 'listId');
    isAnyFiltersExceptLists = filtersExceptLists.some(
      ({ value }) => value !== '' && value?.length !== 0,
    );
  }

  await dispatch(setParams(params));

  // При юзкейсе, когда мы находимся на страинце, отличной от нулевой и меняем размер страницы
  // генерируется 2 события — одно для смены размера страницы, другое для перехода на страницу
  // с индексом 0, в результате чего выполняется подряд 2 запроса к серверу, один из которых
  // зачастую оказывается нерелевантным. (http://git.visionlabs.ru/frontend/uikit/-/issues/25)
  await dispatch(fetchIncidentsREST)
    .then(() => {
      const { incidents: { meta: { sinceAvailable } } } = getState();
      if (!sinceAvailable && !isAnyStateFilters && !isAnyFiltersExceptLists) {
        dispatch(connectAsyncIncidents);
      } else if (!isAnyStateFilters) {
        dispatch(disconnectAsyncIncidents);
      }
    });
};

export const readIncident = (id) => (dispatch) => {
  dispatch(updateIncident({ id, data: { isUnread: false } }));
  lunaCarsClient.incidents.read(id).catch(() => {
    dispatch(updateIncident({ id, data: { isUnread: true } }));
  });
};
