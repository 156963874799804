import React from 'react';

import { MapIcon } from '@vlabs/icons';
import { Control } from '@vlabs/uikit';

import './Cam.sass';

const OpenMapCell = (
  onOpenMapHandler,
  row,
) => (
  <Control.RoundButton
    data-testid="openMapCell"
    variant="dimmed"
    disabled={!row.geolocation}
    onClick={
      row.geolocation
        ? () => onOpenMapHandler(row.geolocation)
        : undefined
    }
    icon={<MapIcon />}
    className="Cam__Icon"
  />
);

export default OpenMapCell;
