/* eslint-disable consistent-return */
/* eslint-disable array-callback-return */
/* eslint-disable prefer-destructuring */
/* eslint-disable no-use-before-define */
import { v4 as uuidv4 } from 'uuid';

const $expandParameter = (parameter, template, camera) => {
  if (template.validation?.type === 'zoneSelector') {
    return {
      ...template,
      ...parameter,
      value: ({
        label: camera?.regions?.find(({ id }) => id === parameter?.value)?.tagName,
        value: parameter?.value,
      }),
    };
  }
  if (template.validation?.type === 'string' && template.validation?.choices) {
    let value;
    if (template.validation?.isMulti === true) {
      value = Array.isArray(parameter.value) ? parameter.value : [parameter.value];
      value = value.map((parameterValue) => ({
        label: template.validation?.choices?.find((option) => option.value === parameterValue)?.label,
        value: parameterValue,
      }));
    } else {
      value = {
        label: template.validation?.choices?.find((option) => option.value === parameter?.value)?.label,
        value: parameter?.value,
      };
    }
    return {
      ...template,
      ...parameter,
      value,
    };
  }
  return { ...template, ...parameter, value: parameter?.value };
};

const $expandGroup = (group, template, camera) => {
  let parameters;
  if (group.value?.length || !template.actions?.some(({ type: actionType }) => actionType === 'extensible')) {
    parameters = $expandDataParametersWithTemplate(group?.value, template?.parameters, camera);
  }
  return { ...template, ...group, parameters };
};

const $expandDataParametersWithTemplate = (
  dataParameters,
  templateOptions,
  camera,
) => templateOptions?.map((template) => {
  const filteredData = dataParameters?.filter(
    ({ systemName: parameterName }) => template?.systemName === parameterName,
  );

  if (!filteredData?.length && template.type === 'parameter') {
    return [{ ...template, value: template.validation?.default }];
  }
  if (!filteredData?.length
    && template.type === 'group'
    && template.actions?.some(({ type: actionType }) => actionType === 'extensible')
  ) {
    return [{ ...template, parameters: [] }];
  }
  if (!filteredData?.length
    && template.type === 'group'
    && !template.actions?.some(({ type: actionType }) => actionType === 'extensible')) {
    return [{ ...template, parameters: $expandDataParametersWithTemplate([], template.parameters, camera) }];
  }

  return filteredData.map((values) => {
    if (template.type === 'parameter') return $expandParameter(values, template, camera);
    if (template.type === 'group') return $expandGroup(values, template, camera);
    return { ...template };
  });
});

const $flatParameters = (values) => values?.map((parameterArray) => {
  if (parameterArray?.some(({ type }) => type === 'parameter')) {
    return parameterArray[0];
  }

  if (parameterArray?.some(({ type }) => type === 'group')) {
    const { id, parameters, ...group } = parameterArray[0];

    if (!parameters?.length) return { ...group, parameters: [] };

    if (parameters?.length) {
      return ({
        ...group,
        parameters: parameterArray.map(({ id, parameters }) => {
          const subParameters = $flatParameters(parameters);
          return ({ id, parameters: subParameters });
        }),
      });
    }
  }
});

export const updateRemoteValues = (data, handlerTemplate, index) => {
  const parametersArray = $expandDataParametersWithTemplate(
    data?.parameters,
    handlerTemplate?.parameters,
    data,
  );
  const flatParameters = $flatParameters(parametersArray);

  return ({
    ...data,
    handlerTemplate,
    parameters: flatParameters,
    camera: {
      ...data?.camera,
      value: data?.camera?.id,
      label: data?.camera?.name,
    },
    index,
  });
};

export const updateTemplate = (parameters) => parameters?.map((parameter) => {
  if (parameter?.type === 'parameter') return parameter;
  if (parameter?.type === 'group') {
    const updatedParameter = updateTemplate(parameter.parameters);
    return { ...parameter, parameters: [{ id: uuidv4(), parameters: updatedParameter }] };
  }
  return undefined;
});

export const convertSubgroupFromApi = (parameters) => parameters.map(({ type, validation, ...parameter }) => {
  if (type === 'parameter') {
    const { type: parameterType, default: defaultValue } = validation;
    if (parameterType === 'bool') return { ...parameter, type, validation, value: defaultValue || false };
    return { ...parameter, type, validation, value: defaultValue !== undefined ? defaultValue : '' };
  }
  if (type === 'group') {
    return {
      ...parameter,
      type,
      parameters: [{
        id: uuidv4(),
        parameters: convertSubgroupFromApi(parameter.parameters),
      }],
    };
  }
  return undefined;
});
