/* eslint-disable jsx-a11y/no-static-element-interactions */
/* eslint-disable jsx-a11y/click-events-have-key-events */
import React, { useState, useEffect } from 'react';

import {
  Grid, GridRow, GridCol, Divider, Page,
} from '@vlabs/uikit';
import parseISO from 'date-fns/parseISO';
import { useTranslation } from 'react-i18next';
import { connect } from 'react-redux';
import { useParams, useHistory } from 'react-router-dom';

import { selectAccountRole } from 'features/accounts/selectors';
import { selectScenarioOptions } from 'features/scenarios/selectors';
import { selectVehicleAttributes } from 'store/vehicle/selectors';

import { lunaCarsClient } from 'api';
import { HAS_FLASHING_LIGHTS_OPTIONS, SCENARIO_TYPE_OPTIONS, CLASS_TYPE_OPTIONS } from 'api-bindings/luna-cars/constants';
import CommentField from 'components/comment-field/CommentField';
import Meta from 'components/meta/Meta';
import PanelBlock from 'components/panel-block/PanelBlock';
import { can } from 'utils/can';

import FrameList from './FrameList';
import { updateIncidentUserNotes } from './incidentsSlice';

import './IncidentPage.sass';

function IncidentPage({
  countryOptions,
  vehicleBrandOptions,
  vehicleColorOptions,
  vehicleColorTypeOptions,
  vehicleModelOptions,
  vehicleEmergencyTypeOptions,
  publicTransportTypeOptions,
  specialTransportTypeOptions,
  vehicleTypeOptions,
  scenarioIndicatorTypeOptions,
  // handlerTypeOptions,
  scenarioOptions,
  role,
}) {
  const { t } = useTranslation();
  const history = useHistory();
  const { incidentId } = useParams();
  const [event, setEvent] = useState({ data: [] });
  // FIXME: Костыльная проверка: если поле detections это {} и в нем есть поле type значит событие типа Пожар
  const isFireEvent = Boolean(event?.detections?.type);

  useEffect(() => {
    lunaCarsClient.incidents.show(incidentId)
      .then(({ data: { data } }) => setEvent(data));
  }, [incidentId]);

  const onShowScenario = (scenarioId) => {
    history.push(`/scenarios/${scenarioId}`);
  };

  const onUserNotesSubmit = async (userNotes) => {
    await updateIncidentUserNotes(incidentId, { userNotes });
    setEvent({ ...event, userNotes });
  };

  const buildCountryLabel = ({ countryId, features }) => {
    let countryLabel = countryOptions.find(({ value: id }) => countryId === id)?.label;
    if (!countryLabel) return '-';

    const licensePlateFeature = features?.find(({ type }) => type === 'rus_spec_type' || type === 'emirate_name')?.title;

    // Костыльно проверяем, зашит ли тип номера в название страны
    if (licensePlateFeature && countryLabel.indexOf('(') === -1) {
      countryLabel = `${countryLabel} (${licensePlateFeature})`;
    }

    return countryLabel;
  };

  return (
    <Page breadcrumbs={[
      { caption: t('Инциденты'), link: '/incidents' },
      { caption: incidentId },
    ]}
    >
      <div className="IncidentPage__Container">
        <PanelBlock title={t('Общие')} isOpen>
          <Grid>
            <GridRow>
              <GridCol cols={6}>
                <div className="IncidentPage__SubContainer">
                  {event.id && <Meta label={t('ID инцидента')} value={event.id} />}
                  {event.registeredAt && (<Meta label={t('Дата, время регистрации')} value={parseISO(event.registeredAt).toLocaleString()} />)}
                  {/* event.entryTime && (<Meta label={t('Дата входа')} value={parseISO(event.entryTime).toLocaleString()} />) */}
                  {/* event.exitTime && (<Meta label={t('Дата выхода')} value={parseISO(event.exitTime).toLocaleString()} />) */}
                  {event.cam && <Meta label={t('Камера')} value={event.cam?.name} />}
                  {event.scenarioId && (
                  <Meta
                    label={t('Сценарий')}
                    value={(can(role, 'scenarios:read')
                      ? (
                        <div className="IncidentPage__Link" onClick={() => onShowScenario(event.scenarioId)}>
                          {scenarioOptions.find(({ value }) => event.scenarioId === value)?.label || ''}
                        </div>
                      )
                      : scenarioOptions.find(({ value }) => event.scenarioId === value)?.label || '')}
                  />
                  )}
                  {event.scenarioId && <Meta label={t('Тип сценария')} value={SCENARIO_TYPE_OPTIONS[scenarioOptions.find(({ value: id }) => event.scenarioId === id)?.scenarioType]?.label} />}
                  {event.indicatorTypeId && <Meta label={event.type === 'event' ? t('Тип события') : t('Тип инцидента')} value={scenarioIndicatorTypeOptions.find(({ value: id }) => event.indicatorTypeId === id)?.label} />}
                  {/* event.scenarioId && scenarioOptions.find(({
                    value: id,
                  }) => event.scenarioId === id)?.handlerTemplates.length > 0 && (
                  <Meta
                    label={t('Обработчик')}
                    value={scenarioOptions
                      .find(({ value: id }) => event.scenarioId === id)?.handlerTemplates
                      .map((handler) => (
                        <div>
                          {handlerTypeOptions.find(({ value }) => value === handler)?.label}
                        </div>
                      ))}
                  />
                  ) */}
                  <Meta label={t('Комментарий')} value={<CommentField userNotes={event.userNotes} onUserNotesSubmit={onUserNotesSubmit} />} />

                  <Divider small />

                  {event.vehicleTrackId && <Meta label={t('Трек ID')} value={event.vehicleTrackId} />}
                  {event.frameId >= 0 && <Meta label={t('ID кадра')} value={event.frameId} />}
                  {event.direction && (<Meta label={t('Направление выезда')} value={JSON.stringify(event.direction)} />)}
                </div>
              </GridCol>
              <GridCol cols={6}>
                <div className="IncidentPage__Content">
                  {event.vehicleBestshot && (
                  <div className="IncidentPage__Image">
                    <img
                      className="ExtendedFrames__Shot"
                      src={event.vehicleBestshot}
                      alt="Бестшот"
                    />
                  </div>
                  )}
                  {event.fragmentGroups?.length >= 0 && (
                    event.fragmentGroups?.map(({ id, label, fragments }) => (
                      <FrameList
                        id={id}
                        label={label}
                        fragments={fragments}
                        type={scenarioIndicatorTypeOptions.find(({ value: id }) => event.indicatorTypeId === id)?.label}
                      />
                    ))
                  )}
                  {(!event.fragmentGroups || event.fragmentGroups.length === 0) && (
                  <>
                    <div className="IncidentPage__EmptyContent">{t('Кадры отсутствуют')}</div>
                    <Divider small />
                  </>
                  )}
                </div>
              </GridCol>
            </GridRow>
          </Grid>
        </PanelBlock>
        <Divider />
        <PanelBlock title={t('Атрибуты')} isOpen>
          <Grid>
            <GridRow>
              <GridCol cols={6}>
                <div className="IncidentPage__SubContainer">
                  {event.vehicleTypeId && (
                  <Meta label={t('Тип')} value={vehicleTypeOptions.find(({ value: id }) => event.vehicleTypeId === id)?.label} accuracyValue={event.vehicleTypeAccuracy} />
                  )}
                  {event.vehicleBrandId && (
                  <Meta label={t('Марка')} value={vehicleBrandOptions.find(({ value: id }) => event.vehicleBrandId === id)?.label} accuracyValue={event.vehicleBrandAccuracy} />
                  )}
                  {event.vehicleModelId && (
                  <Meta label={t('Модель')} value={vehicleModelOptions.find(({ value: id }) => event.vehicleModelId === id)?.label} accuracyValue={event.vehicleModelAccuracy} />
                  )}
                  {event.vehicleEmergency?.type && (
                    <Meta
                      label={t('Экстренная служба')}
                      pairs={[
                        {
                          value: vehicleEmergencyTypeOptions.find(({
                            value: id,
                          }) => event.vehicleEmergency.type === id)?.label,
                          accuracy: event.vehicleEmergency.typeAccuracy,
                        },
                        {
                          value: HAS_FLASHING_LIGHTS_OPTIONS[event.vehicleEmergency.hasFlashingLights]?.label,
                          accuracy: event.vehicleEmergency.hasFlashingLightsAccuracy,
                        },
                      ]}
                    />
                  )}
                  {event.vehicleAxlesAmount > 0 && (
                  <Meta label={t('Количество осей')} value={event.vehicleAxlesAmount} accuracyValue={event.vehicleAxlesAccuracy} />
                  )}
                  {event.publicTransportTypeId && (
                  <Meta label={t('Общ. транспорт')} value={publicTransportTypeOptions.find(({ value: id }) => event.publicTransportTypeId === id)?.label} accuracyValue={event.publicTransportTypeAccuracy} />
                  )}
                  {event.specialTransportTypeId && (
                  <Meta label={t('Спец. техника')} value={specialTransportTypeOptions.find(({ value: id }) => event.specialTransportTypeId === id)?.label} accuracyValue={event.specialTransportTypeAccuracy} />
                  )}
                  {event.vehicleColorTypeId && (
                  <Meta
                    label={t('Тип цвета')}
                    value={vehicleColorTypeOptions.find(({ value: id }) => event.vehicleColorTypeId === id)?.label}
                    accuracyValue={event.vehicleColorTypeAccuracy}
                  />
                  )}
                  {event.vehicleColors?.length > 0 && (
                  <Meta
                    label={t('Цвет')}
                    pairs={event.vehicleColors.map((value) => ({
                      value: vehicleColorOptions.find(({ value: id }) => value.vehicleColorId === id)?.label,
                      accuracy: value.vehicleColorAccuracy,
                    }))}
                  />
                  )}
                  {event.vehicleAxlesAmount > 0 && (
                  <Meta label={t('Количество осей')} value={event.vehicleAxlesAmount} accuracyValue={event.vehicleAxlesAccuracy} />
                  )}
                  {/* event.vehicleLicensePlate && (
                  <Meta label={t('Номер')} value={event.vehicleLicensePlate} accuracyValue={event.vehicleLicensePlateAccuracy} />
                  ) */}
                  {/* event.countryId && (
                  <Meta label={t('Страна')} value={`${country}${licensePlateFeature ? ` (${licensePlateFeature})` : ''}`} accuracyValue={event.countryAccuracy} />
                  ) */}
                  {event.numberOfObjects && (
                    <Meta label={t('Количество ТС')} value={event.numberOfObjects} />
                  )}
                  {isFireEvent && (
                  <>
                    {event?.detections?.meta?.fire !== undefined && <Meta label={t('Огонь')} value={event.detections.meta.fire} />}
                    {event?.detections?.meta?.blackSmoke !== undefined && <Meta label={t('Черный дым')} value={event.detections.meta.blackSmoke} />}
                    {event?.detections?.meta?.whiteSmoke !== undefined && <Meta label={t('Белый дым')} value={event.detections.meta.whiteSmoke} />}
                    {event?.detections?.meta?.noFire !== undefined && <Meta label={t('Нет возгорания')} value={event.detections.meta.noFire} />}
                  </>
                  )}
                </div>
              </GridCol>
              <GridCol cols={6}>
                {event.vehicleBestshot && (
                  <img
                    className="EventPage__FireEventImage"
                    src={event.vehicleBestshot}
                    alt="fire event img"
                  />
                )}
                {!event.vehicleBestshot && (
                  <>
                    <div className="EventPage__EmptyContent">
                      {t('Кадры отсутствуют')}
                    </div>
                    <Divider small />
                  </>
                )}
              </GridCol>
            </GridRow>
          </Grid>
        </PanelBlock>

        <Divider />
        <PanelBlock title={t('Регистрационные знаки')}>
          <div className="IncidentPage__SubContainer">
            {event.vehicleLicensePlates?.map((plate) => (
              <>
                <div className="IncidentPage__Plate">
                  <div className="IncidentPage__Plate_number">
                    <Meta label={t('ГРЗ')} value={`${plate.symbols} {${CLASS_TYPE_OPTIONS[plate.classType]?.label}}`} accuracyValue={plate.accuracy} />

                    {plate.countryId && (
                    <Meta
                      label={t('Страна')}
                      value={buildCountryLabel({
                        countryId: plate.countryId,
                        features: plate.licensePlateFeature,
                      })}
                      accuracyValue={plate.countryAccuracy}
                    />
                    )}
                  </div>
                  <img
                    className="IncidentPage__Plate_image"
                    src={plate.image}
                    alt="plate img"
                  />
                </div>
                <div className="IncidentPage__Border" />
              </>
            ))}
          </div>
        </PanelBlock>

        {event.speeds?.length > 0 && (
        <>
          <Divider />
          <PanelBlock title={t('Скорость по полосам')}>
            <div className="IncidentPage__SubContainer">
              {event.speeds.map(({ title, speed }) => (
                <Meta label={title} value={`${speed} ${t('км/ч')}`} />
              ))}
            </div>
          </PanelBlock>
        </>
        )}
      </div>
    </Page>
  );
}

export default connect((state) => ({
  ...selectVehicleAttributes(state),
  scenarioOptions: selectScenarioOptions(state),
  role: selectAccountRole(state),
}))(IncidentPage);
