/* eslint-disable jsx-a11y/no-static-element-interactions */
/* eslint-disable jsx-a11y/click-events-have-key-events */
import React, { useState } from 'react';

import { Delete2Icon } from '@vlabs/icons';
import { Page, Control, Margin } from '@vlabs/uikit';
import cn from 'classnames';
import { useForm } from 'react-hook-form';
import { useTranslation } from 'react-i18next';

import ImageSelector from 'components/file-selectors/ImageSelector';
import SettingFilter from 'components/setting-filter/SettingFilter';

import st from './SearchByImageForm.module.sass';

const MAX_IMAGE_FILE_SIZE = 5 * 1024 * 1024; // 5 Мб
const SUPPORTED_FORMATS = [
  'image/jpeg',
  'image/jpg',
  'image/png',
];

function SearchByImageForm({
  image = undefined,
  imageId = undefined,
  imageAccuracyGte = '',
  imageAccuracyLte = '',
  onUpload = undefined,
  onSubmit = undefined,
}) {
  const { t } = useTranslation();
  const { handleSubmit, reset, register, watch, formState: { errors } } = useForm({
    defaultValues: {
      imageAccuracyGte,
      imageAccuracyLte,
    },
  });
  const [currentImage, setCurrentImage] = useState(image);
  const [currentImageId, setCurrentImageId] = useState(imageId);
  const { imageAccuracyGte: accuracyGte, imageAccuracyLte: accuracyLte } = watch();

  const $onUpload = async (file) => {
    setCurrentImage(file);
    if (!file) {
      setCurrentImageId(undefined);
      reset();
      return;
    }
    const newImageId = await onUpload(file);
    setCurrentImageId(newImageId);
  };

  const $onDeleteAccuracies = () => {
    if (accuracyGte === '' && accuracyLte === '') return;
    reset({ imageAccuracyGte: '', imageAccuracyLte: '' });
  };

  const accuracyValidator = (value) => {
    return ((value === undefined || (value <= 1 && value >= 0)) || t('Введите значение от {{min}} до {{max}}', { min: 0, max: 1 }));
  };

  return (
    <form onSubmit={handleSubmit((values) => onSubmit({
      image: currentImage,
      imageInfo: {
        imageId: currentImageId,
        ...values,
      },
    }))}
    >
      <Page className={st.Form}>
        <Margin left bottom>
          <h6>
            {t('Изображение транспортного средства')}
          </h6>
        </Margin>

        <Margin display="block">
          <ImageSelector
            imageUrl={image?.binary}
            maxImageSize={MAX_IMAGE_FILE_SIZE}
            supportedImageTypes={SUPPORTED_FORMATS}
            onChange={$onUpload}
          />
        </Margin>
        {currentImage?.raw && (
        <Margin display="block">
          <SettingFilter label={t('Точность')}>
            <div className={st.Flex}>
              <div className={st.Full}>
                <Control.Input
                  id="imageAccuracyGte"
                  {...register('imageAccuracyGte', {
                    validate: {
                      accuracyValidator,
                    },
                  })}
                  step="0.01"
                  placeholder="0"
                  errors={errors}
                  type="number"
                />
              </div>
              :
              <div className={st.Full}>
                <Control.Input
                  id="imageAccuracyLte"
                  {...register('imageAccuracyLte', {
                    validate: {
                      accuracyValidator,
                    },
                  })}
                  step="0.01"
                  placeholder="1"
                  errors={errors}
                  type="number"
                />
              </div>
            </div>
          </SettingFilter>
        </Margin>
        )}
        <Margin display="block">
          <div className={st.Flex}>
            <Control.Button
              // disabled={!currentImageId}
              type="submit"
            >
              {t('Применить')}
            </Control.Button>
            {(currentImageId || imageId) && (
            <div onClick={$onDeleteAccuracies}>
              <Delete2Icon
                className={cn({
                  [st.Icon]: true,
                  [st.Icon_disabled]: accuracyGte === '' && accuracyLte === '',
                })}
              />
              <div className={cn({
                [st.Link]: true,
                [st.Link_disabled]: accuracyGte === '' && accuracyLte === '',
              })}
              >
                {t('Удалить точность')}
              </div>
            </div>
            )}
          </div>
        </Margin>
      </Page>
    </form>
  );
}

export default SearchByImageForm;
