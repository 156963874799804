import React from 'react';

import st from './Meta.module.sass';

const Meta = ({ label, value }) => (
  <div className={st.Meta}>
    <div className="Subtitle-1">{label}</div>
    <div className="Body-2">{value}</div>
  </div>
);

export default Meta;
