import { combineReducers } from '@reduxjs/toolkit';

import appSlice from 'app/store/appSlice';
import accountSlice from 'features/accounts/accountSlice';
import camsSlice from 'features/cams/camsSlice';

import eventsSlice from '../features/events/eventsSlice';
import extendedEventsSlice from '../features/extended-events/extendedEventsSlice';
import incidentsSlice from '../features/incidents/incidentsSlice';
import listsSlice from '../features/lists/listsSlice';
import scenariosSlice from '../features/scenarios/scenariosSlice';
import appSettingsSlice from './app-settings/appSettingsSlice';
import exportSettings from './export-settings/store';
import vehicleAttributesSlice from './vehicle/vehicleAttributesSlice';

export default combineReducers({
  account: accountSlice,
  vehicleAttributes: vehicleAttributesSlice,
  cams: camsSlice,
  incidents: incidentsSlice,
  events: eventsSlice,
  extendedEvents: extendedEventsSlice,
  lists: listsSlice,
  scenarios: scenariosSlice,
  appSettings: appSettingsSlice,
  exportSettings,
  app: appSlice,
});
