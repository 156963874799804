import { createSelector } from '@reduxjs/toolkit';

export const selectAccount = (state) => state.account;

export const selectIsAuthenticated = createSelector(
  selectAccount,
  ({ isAuthenticated }) => isAuthenticated,
);
export const selectAccountModel = createSelector(
  selectAccount,
  ({ model }) => model,
);
export const selectAccountRole = createSelector(
  selectAccountModel,
  (model) => (model ? model.role : null),
);
