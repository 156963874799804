/* eslint-disable no-use-before-define */
const $filterEmptyValues = (parameters) => parameters.filter(({ type, value }) => {
  if (type === 'parameter') return value !== undefined && value !== '';
  return true;
});

const $handleParameter = (parameter) => {
  const { validation, type, systemName, value } = parameter;
  if (value === undefined || value === '') return { type, systemName, value };
  if (validation?.type === 'zoneSelector') return { type, systemName, value: value?.value };
  if (validation?.type === 'string' && validation?.choices) {
    if (validation?.isMulti === true) {
      let arrayValue = Array.isArray(value) ? value : [value];
      arrayValue = arrayValue.filter((v) => !!v);
      return { type, systemName, value: arrayValue.map((option) => option.value) };
    }
    return { type, systemName, value: value?.value };
  }
  if (validation?.type === 'int' || validation?.type === 'float') return { type, systemName, value: Number(value) };
  return { type, systemName, value };
};

const $handleGroup = (group) => {
  return group.parameters.map(({ id, parameters }) => {
    return ({
      id,
      type: 'group',
      systemName: group.systemName,
      isEnabled: group.isEnabled,
      value: formatParametersToApi(parameters),
    });
  });
};

export const formatParametersToApi = (parameters) => {
  const updatedValues = parameters
    .map(({ type, value, systemName, validation, parameters: subParameters, isEnabled }) => {
      if (type === 'parameter') return $handleParameter({ validation, type, systemName, value });
      if (type === 'group') return $handleGroup({ type, systemName, value, parameters: subParameters, isEnabled });
      return {};
    });
  const flatParameters = updatedValues.flat();
  return $filterEmptyValues(flatParameters);
};
