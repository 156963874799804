export default function vehicleAttributeAccessor(vehicleValue, options) {
  if (Array.isArray(vehicleValue)) {
    return vehicleValue
      .map((id) => {
        const result = options.find(({ value }) => value === id);
        return result ? result.label : id;
      });
  }

  const result = options.find(({ value }) => value === vehicleValue);
  return result ? result.label : vehicleValue;
}

export function vehicleBrandModelAccessor(brandValue, brandOptions, modelValue, modelOptions, key) {
  if (Array.isArray(brandOptions) && Array.isArray(modelOptions)
    && Array.isArray(brandValue) && Array.isArray(modelValue)
  ) {
    let models = [];
    const brandModelArr = brandValue.map((brandId) => {
      const brand = {};
      brand.id = brandId;
      brand.value = [];
      return brand;
    });

    models = modelValue.map((id) => modelOptions.find(({ value }) => value === id) || {});

    if (Array.isArray(models)) {
      models.forEach(({ label, vehicleBrandId }) => {
        brandModelArr.forEach((brand) => {
          if (brand.id === vehicleBrandId) {
            brand.value.push(label);
          }
        });
      });
    }

    const result = brandModelArr.map(({ id, value }) => {
      const brandName = brandOptions.find(({ value: val }) => val === id).label;
      return { name: brandName, value, key };
    });
    return result;
  }
  return [];
}
