/* eslint-disable react/no-array-index-key */
import React from 'react';

import { PlusIcon, DeleteIcon, CircleInfoIcon } from '@vlabs/icons';
import { SettingsItemWrapper, Divider, Fold, Tooltip, Control } from '@vlabs/uikit';
import { useFieldArray, useFormContext } from 'react-hook-form';
import { useTranslation } from 'react-i18next';
import { v4 as uuidv4 } from 'uuid';

import Parameter from 'components/parameter/Parameter';
import { convertSubgroupFromApi } from 'features/scenarios/handlerFromApi';

import st from './Group.module.sass';

export default function Group({
  index,
  regionOptions,
  nameForm,
  handlerTemplate,
}) {
  const { t } = useTranslation();
  const { control, watch, getValues, formState: { errors } } = useFormContext();
  const { remove, append } = useFieldArray({ name: `${nameForm}.parameters` });
  const values = getValues(nameForm);

  return (
    <div className={st.Wrapper}>
      <Fold title={<span className="Subtitle-1">{values?.title}</span>} isOpen>
        {values?.isEnabled !== undefined && (
          <div className={st.Toggle}>
            <Control.Switch
              name={`${nameForm}.isEnabled`}
              control={control}
              errors={errors}
            />
            <Tooltip tooltipProps={{ effect: 'solid', place: 'right' }} className={st.Tooltip} data-tip={values?.isEnabled ? t('Группа активна') : t('Группа не активна')}>
              <CircleInfoIcon className={st.Tooltip_icon} />
            </Tooltip>
          </div>
        )}

        {values?.parameters?.map(({ parameters }, index2) => {
          return (
            <div key={`${index}_${index2}`}>
              {parameters?.map((parameter, index3) => {
                if (parameter?.type === 'parameter') {
                  return (
                    <div key={`${parameter.systemName}_${index3}`}>
                      <Parameter
                        {...parameter}
                        index={index3}
                        name={`${nameForm}.parameters.${index2}.parameters.${index3}.value`}
                        regionOptions={regionOptions}
                        isEnabled={watch(nameForm)?.isEnabled}
                      />
                    </div>
                  );
                }

                if (parameter?.type === 'group') {
                  const handlerTemplateParameters = handlerTemplate?.parameters;
                  const [templateParameters] = handlerTemplateParameters ?? [];
                  return (
                    <div className={st.Subtask} key={`${parameter.title}_${index2}`}>
                      <Group
                        {...parameter}
                        index={index2}
                        regionOptions={regionOptions}
                        handlerTemplate={templateParameters?.parameters?.[index3]}
                        nameForm={`parameters.${index}.parameters.${index2}.parameters.${index3}`}
                      />
                    </div>
                  );
                }

                return null;
              })}

              {values.actions?.some(({ type: actionType }) => actionType === 'extensible') && (
                <div className={st.Icon_wrapper}>
                  <DeleteIcon
                    className={st.Icon}
                    onClick={() => remove(index2)}
                  />
                </div>
              )}

              {values.actions?.some(({ type: actionType }) => actionType === 'extensible') && (
                <div className={st.Border} />
              )}
            </div>
          );
        })}

        {values.actions?.some(({ type: actionType }) => actionType === 'extensible') && (
          <SettingsItemWrapper>
            <PlusIcon
              className={st.Icon}
              onClick={() => {
                // TODO: быстрый фикс
                const parameter = handlerTemplate?.parameters?.[0]?.id
                  ? handlerTemplate?.parameters?.[0]
                  : handlerTemplate;
                const parameters = convertSubgroupFromApi(parameter?.parameters);
                append({ id: uuidv4(), parameters });
              }}
            />
          </SettingsItemWrapper>
        )}
      </Fold>

      <Divider small />
    </div>
  );
}
