/* eslint-disable jsx-a11y/no-noninteractive-element-interactions */
/* eslint-disable jsx-a11y/click-events-have-key-events */
import React, { useState } from 'react';

import { Modal } from '@vlabs/uikit';

import { FRAGMENT_TYPE_OPTIONS } from 'api-bindings/luna-cars/constants';
import FragmentForm from 'components/fragment-form/FragmentForm';

import '../events/EventFrames.sass';

const ExtendedEventFrames = ({
  label,
  fragments,
  type,
}) => {
  const [isModalOpen, setIsModalOpen] = useState(false);
  const [image, setImage] = useState({});

  const filterFragments = (values) => {
    if (!(type === 'Человек' || type === 'Пешеход' || type === 'Животное')) return values;
    return values.filter(({ type }) => type !== 'license_plate_crop');
  };

  const closeROIModal = () => {
    setIsModalOpen(false);
    setImage(null);
  };

  const openModal = (fragment) => {
    setImage({ ...fragment });
    setIsModalOpen(true);
  };

  return (
    <>
      <Modal
        isOpen={isModalOpen}
        onRequestClose={closeROIModal}
        appElement={document.getElementById('root')}
      >
        <FragmentForm {...image} eventType={type} />
      </Modal>

      <div className="EventFrames">
        <h6 className="EventFrames__Title">
          {label}
        </h6>

        {filterFragments(fragments).map((fragment) => {
          if (!fragment.image) return null;

          let fragmentLabel = FRAGMENT_TYPE_OPTIONS[fragment.type]?.label || fragment?.label;

          if (fragment.type === 'vehicle_crop') {
            if (type === 'Человек' || type === 'Пешеход') {
              fragmentLabel = 'Кроп пешехода';
            }
            if (type === 'Животное') {
              fragmentLabel = 'Кроп животного';
            }
          }

          return (
            <div className="EventFrames__ImageWrapper" key={fragment.image}>
              <img
                className="EventFrames__Image"
                src={fragment.image}
                alt={fragment.label}
                onClick={() => openModal(fragment)}
              />
              <div className="EventFrames__ImageLabel">
                {fragmentLabel}
              </div>
            </div>
          );
        })}
      </div>
    </>
  );
};

export { ExtendedEventFrames };
