import React from 'react';

export default function HandlersCell({
  handlers,
  handlerTypeOptions,
}) {
  return (
    <div>
      {handlers && handlers.length > 0 && handlers.map((id) => (
        <div key={id}>
          {handlerTypeOptions?.find(({ value }) => value === id)?.label}
        </div>
      ))}
    </div>
  );
}
