import React from 'react';

import { PlayerStopInCircleIcon } from '@vlabs/icons';

import { STATUS_OPTIONS } from 'api-bindings/luna-cars/constants';

import st from './CancelCell.module.sass';

const CancelCell = (
  onCancelHandler,
  { row },
) => {
  const { original: { status } } = row;
  const { value: statusValue } = STATUS_OPTIONS[status];
  return (
    (statusValue === 0 || statusValue === 1) && (
      <PlayerStopInCircleIcon
        data-testid="cancelCell"
        className={st.Icon}
        onClick={() => onCancelHandler(row.original)}
      />
    )
  );
};

export default CancelCell;
