import React from 'react';

import PropTypes from 'prop-types';

import './BrandModelCell.sass';

const BrandModelRow = (brand, models, key) => (
  <div className="BrandModelCell__Row" key={`${key}_${brand}`}>
    {!models?.length && (
      <div key={brand}>
        {brand}
      </div>
    )}
    {models?.map((model) => (
      <div key={model}>
        {`${brand} ${model}`}
      </div>
    ))}
  </div>
);
function BrandModelCell({
  value,
}) {
  return (
    <div>
      {value.map(
        ({ name: brand, value: models, key }) => BrandModelRow(brand, models, key),
      )}
    </div>
  );
}

BrandModelCell.propTypes = {
  value: PropTypes.arrayOf(PropTypes.any),
};
BrandModelCell.defaultProps = {
  value: [],
};
export default BrandModelCell;
