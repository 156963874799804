export const roles = {
  USER: 0,
  ADMIN: 1,
};

export const rules = {
  [roles.USER]: {
    static: [
      'events:read', 'events:export',
      'lists:read',
      'list:read',
      'incidents:read',
      'cams:read',
      'tasks:read',
    ],
  },
  [roles.ADMIN]: {
    static: [
      'events:read', 'events:export', 'events:autocomplete',
      'lists:read', 'lists:create', 'lists:update', 'lists:delete',
      'list:read', 'list:create', 'list:update', 'list:delete',
      'incidents:read',
      'cams:read', 'cams:create', 'cams:update', 'cams:delete', 'cams:restart',
      'tasks:read',
      'accounts:read', 'accounts:create', 'accounts:update', 'accounts:delete',
      'scenarios:read',
    ],
  },
};
