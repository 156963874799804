import { useCallback, useReducer, useRef } from 'react';

import { useParams } from 'react-router-dom';

import { lunaCarsClient } from 'api';

const actionTypes = {
  UPADATE: 'update',
  CREATE: 'create',
};

const MAX_RENDERABLE_ITEMS = 50;

const reducer = (previousState, action) => {
  switch (action.type) {
    case actionTypes.UPADATE: {
      const index = previousState?.findIndex(({ id }) => id === action.payload.id);
      if (index === -1 || index === undefined) return previousState;
      previousState.splice(index, 1, action.payload);

      return [...previousState];
    }
    case actionTypes.CREATE: {
      // slice используется для ограничения количества отображаемых событий
      return [action.payload, ...previousState].slice(0, MAX_RENDERABLE_ITEMS);
    }
    default: {
      throw new Error(`action ${action.type} is not registered`);
    }
  }
};

export const useSocketHandler = (type) => {
  const { camId } = useParams();
  const socket = useRef(null);

  const [state, dispatch] = useReducer(reducer, []);
  const unsubscribe = useRef(null);

  const subscribe = useCallback(() => {
    if (socket.current !== null) {
      socket.current.destroy();
    }
    socket.current = lunaCarsClient.createWSClient(`/${type}?cameraId=${camId}`);
    socket.current.connect();

    const eventsHandler = ({ data, meta }) => {
      data.forEach((event, i) => {
        if (meta?.[i]?.operation === 'create') {
          dispatch({ payload: event, type: actionTypes.CREATE });
        }
        if (meta?.[i]?.operation === 'update') {
          dispatch({ payload: event, type: actionTypes.UPADATE });
        }
      });
    };

    const triggerOn = (msg) => {
      if (msg?.meta === undefined) return false;
      return true;
    };

    if (unsubscribe.current !== null) unsubscribe();
    const subscription = socket.current.subscribe(eventsHandler, triggerOn);
    unsubscribe.current = subscription.unsubscribe;
    return unsubscribe.current;
  }, [camId, type]);

  const destroy = useCallback(() => {
    if (socket.current === null) return;
    socket.current.destroy();
  }, []);

  return {
    data: state,
    subscribe,
    destroy,
  };
};
