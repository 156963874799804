import React, { useCallback, useState, useEffect } from 'react';

import {
  Page, Table, TableCells, Panel, useTableHook, openConfirmPopup, Modal, Divider,
} from '@vlabs/uikit';
import { useTranslation } from 'react-i18next';
import { connect } from 'react-redux';
import { useParams } from 'react-router-dom';
import { toast } from 'react-toastify';

import { selectAccountRole } from 'features/accounts/selectors';
import { selectVehicleAttributes } from 'store/vehicle/selectors';

import { lunaCarsClient } from 'api';
import { HAS_FLASHING_LIGHTS_OPTIONS, PAGE_SIZE_OPTIONS } from 'api-bindings/luna-cars/constants';
import ArrayCell from 'components/array-cell/ArrayCell';
import BestshotCell from 'components/bestshot/Bestshot';
import BrandModelCell from 'components/brand-model-cell/BrandModelCell';
import vehicleAttributeAccessor, { vehicleBrandModelAccessor } from 'components/vehicleAttributeAccessor';
import ListItemForm from 'features/lists/ListItemForm';
import ListItemsFilterForm from 'features/lists/ListItemsFilterForm';
import { can } from 'utils/can';
import { actionColumnProps } from 'utils/helpers';

function stringToBoolean(value) {
  if (value === 'true') {
    return true;
  } if (value === 'false') {
    return false;
  }
  return undefined;
}

function ListPage({
  role,
  vehicleTypeOptions = [],
  vehicleBrandOptions = [],
  vehicleModelOptions = [],
  vehicleColorOptions = [],
  vehicleEmergencyTypeOptions = [],
  countryOptions = [],
}) {
  const { t } = useTranslation();
  const { listId } = useParams();
  const [listName, setListName] = useState('');
  const [modalIsOpen, setModalIsOpen] = useState(false);
  const [selectedListItem, setSelectedListItem] = useState(undefined);
  const closeModal = () => {
    setSelectedListItem(undefined);
    setModalIsOpen(false);
  };

  useEffect(() => {
    lunaCarsClient.lists.show(listId).then(
      ({ data: { data: { name } } }) => setListName(name),
    );
  }, [listId]);

  const onFetch = ({ page = 0, ...params }) => lunaCarsClient.lists
    .showAllItems(listId, { page: page + 1, ...params })
    .then(({ data }) => data);
  const tableProps = useTableHook(useCallback(onFetch, [listId]));
  const { filters, setFilters } = tableProps;

  const onDelete = (toDelete) => {
    let message;
    let onConfirm;
    if (toDelete instanceof Array) {
      message = (
        <span>
          {`${t('Вы уверены, что хотите удалить')}`}
        &nbsp;
          <strong>{toDelete.length}</strong>
        &nbsp;
          {`${t('выбранных записей списка')}`}
          ?
        </span>
      );
      onConfirm = async () => {
        await Promise.all(toDelete.map((listItem) => lunaCarsClient.lists.deleteItem(listId, listItem.id)));
        tableProps.onFetch();
      };
    } else {
      const listItem = toDelete;
      if (listItem.vehicleLicensePlate) {
        message = (
          <span>
            {`${t('Вы уверены, что хотите удалить запись с номером ТС')}`}
            &nbsp;
            <strong>{listItem.vehicleLicensePlate}</strong>
            ?
          </span>
        );
      } else {
        message = (
          <span>
            {`${t('Вы уверены, что хотите удалить запись?')}`}
          </span>
        );
      }
      onConfirm = async () => {
        await lunaCarsClient.lists.deleteItem(listId, listItem.id);
        tableProps.onFetch();
      };
    }
    openConfirmPopup({
      title: t('Удаление записи спиская'),
      message,
      type: 'delete',
      onConfirm,
    });
  };

  const onShowEditForm = (listItem) => {
    if (listItem) {
      setSelectedListItem({
        ...listItem,
        countryIds: countryOptions
          .filter(({ value }) => listItem.countryIds.includes(value)),
        vehicleTypeIds: vehicleTypeOptions
          .filter(({ value }) => listItem.vehicleTypeIds.includes(value)),
        vehicleBrandIds: vehicleBrandOptions
          .filter(({ value }) => listItem.vehicleBrandIds.includes(value)),
        vehicleModelIds: vehicleModelOptions
          .filter(({ value }) => listItem.vehicleModelIds.includes(value)),
        vehicleColorIds: vehicleColorOptions
          .filter(({ value }) => listItem.vehicleColorIds.includes(value)),
        vehicleEmergencyTypeIds: vehicleEmergencyTypeOptions
          .filter(({ value }) => listItem.vehicleEmergencyTypeIds.includes(value)),
      });
    }
    setModalIsOpen(true);
  };
  const onAfterFormSubmit = () => {
    closeModal();
    tableProps.onFetch();
  };
  const onFormSubmit = (values) => {
    const request = new FormData();
    const appendArray = (attribute) => request.append(
      attribute,
      values[attribute].map(({ value }) => value),
    );
    if (values.vehicleLicensePlate) request.append('vehicleLicensePlate', values.vehicleLicensePlate);
    if (values.countryIds)appendArray('countryIds');
    if (values.vehicleTypeIds) appendArray('vehicleTypeIds');
    if (values.vehicleBrandIds) appendArray('vehicleBrandIds');
    if (values.vehicleModelIds) appendArray('vehicleModelIds');
    if (values.vehicleColorIds) appendArray('vehicleColorIds');
    if (values.vehicleEmergencyTypeIds) appendArray('vehicleEmergencyTypeIds');
    if (values.vehicleEmergencyHasFlashingLight) request.append('vehicleEmergencyHasFlashingLight', stringToBoolean(values.vehicleEmergencyHasFlashingLight.value));
    if (values.imageAccuracy) request.append('imageAccuracy', values.imageAccuracy);
    if (values?.image?.[0]) request.append('image', values.image[0]);
    if (values.imageUri && !(values.image === undefined || values?.image?.[0])) request.append('imageUri', values.imageUri);
    if (selectedListItem) {
      lunaCarsClient.lists.updateItem(listId, selectedListItem.id, request)
        .then(onAfterFormSubmit)
        .catch(({ response: { status } }) => {
          if (status === 413) {
            toast.error(t('Размер загружаемого файла слишком большой'));
          }
        });
    } else {
      lunaCarsClient.lists.createItem(listId, request)
        .then(onAfterFormSubmit)
        .catch(({ response: { status, data } }) => {
          if (status === 413) {
            toast.error(t('Размер загружаемого файла слишком большой'));
          } else {
            toast.error(`${t('Серверная ошибка')}: ${data}`);
          }
        });
    }

    setSelectedListItem(undefined);
  };
  const onFiltersSubmit = (fields) => {
    const formatted = Object.entries(fields).map(([id, field]) => {
      let value = field;
      if (Array.isArray(field)) {
        value = field.map((fieldValue) => {
          // Check if fieldValue is value of MultiDropdown
          if (typeof fieldValue === 'object' && fieldValue !== null && 'value' in fieldValue) {
            return fieldValue.value;
          }
          return fieldValue;
        });
      }
      return { id, value: value === null ? undefined : value };
    });
    setFilters(formatted);
  };
  const onFiltersReset = () => setFilters([]);

  return (
    <Page title={listName}>
      <Modal
        isOpen={modalIsOpen}
        onRequestClose={closeModal}
        appElement={document.getElementById('root')}
      >
        <ListItemForm
          onSubmit={onFormSubmit}
          vehicleTypeOptions={vehicleTypeOptions}
          vehicleBrandOptions={vehicleBrandOptions}
          vehicleModelOptions={vehicleModelOptions}
          vehicleColorOptions={vehicleColorOptions}
          vehicleEmergencyTypeOptions={vehicleEmergencyTypeOptions}
          countryOptions={countryOptions}
          {...selectedListItem}
        />
      </Modal>
      <Divider />
      <Panel>
        <ListItemsFilterForm
          vehicleTypeOptions={vehicleTypeOptions}
          vehicleBrandOptions={vehicleBrandOptions}
          vehicleModelOptions={vehicleModelOptions}
          vehicleColorOptions={vehicleColorOptions}
          vehicleEmergencyTypeOptions={vehicleEmergencyTypeOptions}
          countryOptions={countryOptions}
          onReset={onFiltersReset}
          onSubmit={onFiltersSubmit}
          {...filters}
        />
      </Panel>
      <Divider />
      <Panel>
        <Table
          {...tableProps}
          paginationType="offsetBased"
          pageSizeOptions={PAGE_SIZE_OPTIONS}
          columns={[
            { Header: t('Фото'), accessor: (row) => BestshotCell(row.imageUri) },
            { Header: t('Номер'), accessor: 'vehicleLicensePlate' },
            {
              Header: t('Страна'),
              accessor: (row) => vehicleAttributeAccessor(row.countryIds, countryOptions),
              Cell: ArrayCell,
            },
            {
              Header: t('Тип'),
              accessor: (row) => vehicleAttributeAccessor(row.vehicleTypeIds, vehicleTypeOptions),
              Cell: ArrayCell,
            },
            {
              Header: t('Марка'),
              accessor: (row) => vehicleBrandModelAccessor(
                row.vehicleBrandIds,
                vehicleBrandOptions,
                row.vehicleModelIds,
                vehicleModelOptions,
                row.id,
              ),
              Cell: BrandModelCell,
            },
            {
              Header: t('Экстренная служба'),
              accessor: (row) => vehicleAttributeAccessor(row.vehicleEmergencyTypeIds, vehicleEmergencyTypeOptions),
              Cell: ArrayCell,
            },
            {
              Header: t('Проблесковый маячок'),
              // eslint-disable-next-line react/no-unstable-nested-components
              accessor: (row) => (<div>{HAS_FLASHING_LIGHTS_OPTIONS[`${row.vehicleEmergencyHasFlashingLight}`]?.label}</div>),
            },
            {
              Header: t('Цвет'),
              accessor: (row) => vehicleAttributeAccessor(row.vehicleColorIds, vehicleColorOptions),
              Cell: ArrayCell,
            },
            actionColumnProps({ id: 'edit', Cell: TableCells.EditCell, width: 30 }),
            actionColumnProps({ id: 'delete', Cell: TableCells.DeleteCell, width: 30 }),
          ]}
          onActions={{
            onClickRow: { handler: onShowEditForm, can: () => can(role, 'list:update') },
            onAddRow: { handler: onShowEditForm, can: () => can(role, 'list:create') },
            onEditRow: { handler: onShowEditForm, can: () => can(role, 'list:update') },
            onDeleteRow: { handler: onDelete, can: () => can(role, 'list:delete') },
          }}
        />
      </Panel>
    </Page>
  );
}

export default connect((state) => ({
  ...selectVehicleAttributes(state),
  role: selectAccountRole(state),
}))(ListPage);
