import { i18n as uikitI18n } from '@vlabs/uikit';

import { api } from 'api-bindings/i18n';

import carsApi from './carsApi.json';
import events from './events.json';
import translation from './translation.json';
import translationOld from './translation_old.json';

export default {
  events,
  carsApi,
  translationOld,
  translation,
  ...uikitI18n.en,
  ...api.en,
};
