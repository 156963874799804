import React from 'react';

import cn from 'classnames';

import { CAMERA_STATUS_OPTIONS } from 'api-bindings/luna-cars/constants';

import './Cam.sass';

const CamStatusCell = (
  status,
) => {
  const { description: statusLabel } = CAMERA_STATUS_OPTIONS[status];
  return (
    <div className={cn({
      Cam__Status: true,
      [`Cam__Status_${statusLabel}`]: statusLabel,
    })}
    />
  );
};

export default CamStatusCell;
