/* eslint-disable jsx-a11y/no-static-element-interactions */
/* eslint-disable jsx-a11y/click-events-have-key-events */
import React, { useEffect, useState } from 'react';

import { Page } from '@vlabs/uikit';
import { formatISO } from 'date-fns';
import PropTypes from 'prop-types';
import qs from 'qs';
import { useTranslation } from 'react-i18next';
import { connect } from 'react-redux';
import { useHistory } from 'react-router-dom';
import { toast } from 'react-toastify';

import { selectCamOptions } from 'features/cams/selectors';
import { selectIncidentsFilters } from 'features/incidents/selectors';
import { selectListOptions } from 'features/lists/selectors';
import { selectScenarioOptions } from 'features/scenarios/selectors';
import { selectSettings } from 'store/app-settings/selectors';
import { selectVehicleAttributes } from 'store/vehicle/selectors';

import { lunaCarsClient } from 'api';
import IncidentsFilterForm from 'features/incidents/forms/IncidentsFilterForm';
import { updateParams } from 'features/incidents/incidentsSlice';
import { flattenFilters } from 'utils/utils';

import IncidentsTable from './IncidentsTable';

function IncidentsPage({
  vehicleTypeOptions = [],
  vehicleBrandOptions = [],
  vehicleModelOptions = [],
  vehicleColorOptions = [],
  vehicleEmergencyTypeOptions = [],
  publicTransportTypeOptions = [],
  specialTransportTypeOptions = [],
  licensePlateFeatureOptions = [],
  scenarioIndicatorTypeOptions = [],
  scenarioOptions = [],
  countryOptions = [],
  camOptions = [],
  listOptions = [],
  filters,
  setFilters,
  settings,
}) {
  const { t } = useTranslation();
  const history = useHistory();
  const [isFiltersVisible, setIsFiltersVisible] = useState(true);
  useEffect(() => {
    if (window.location.search) {
      setFilters(
        [qs.parse(window.location.search, { ignoreQueryPrefix: true })],
      );
    }
    // eslint-disable-next-line
  }, [window.location.search]);

  const getMappedFilters = (fields) => {
    const filterredFields = Object.entries(fields);
    const mappedFilters = filterredFields.map(([id, field]) => {
      let value = field;

      if (Array.isArray(field)) {
        value = field.map((fieldValue) => {
          // Check if fieldValue is value of MultiDropdown
          if (typeof fieldValue === 'object' && fieldValue !== null && 'value' in fieldValue) {
            return fieldValue.value;
          }
          return fieldValue;
        });
      }

      if (!Array.isArray(field) && (id === 'recordId' || id === 'recordIdExclude')) {
        return { id, value: value ? value.split(',') : value };
      }

      if (['registeredAfter', 'registeredBefore'].includes(id) && value) {
        return { id, value: formatISO(value?.[0]) };
      }

      return { id, value };
    });
    return mappedFilters;
  };

  const onFilter = (fields) => {
    setFilters(getMappedFilters(fields));
  };

  const onExport = async (fields) => {
    const filtersOnExport = flattenFilters(getMappedFilters(fields));
    const resp = await lunaCarsClient.exportResource.export({
      ...filtersOnExport, exportFormat: 'xlsx',
    });
    if (resp.status === 201) {
      toast.info(t('Задача  была создана, для просмотра перейдите в "Задачи"'));
    }
  };

  const onReset = () => {
    window.history.pushState({}, document.title, '/incidents');
    setFilters([]);
  };

  const onShowScenario = (scenarioId) => {
    history.push(`/scenarios/${scenarioId}`);
  };

  const inputValues = {};
  filters.forEach(({ id, value: filterValue }) => {
    if (id === 'listId') {
      inputValues[id] = listOptions.filter(
        ({ value: optionValue }) => filterValue.includes(optionValue),
      );
    } else if (id === 'vehicleTypeId' || id === 'vehicleTypeIdExclude') {
      inputValues[id] = vehicleTypeOptions.filter(
        ({ value: optionValue }) => filterValue.includes(optionValue),
      );
    } else if (id === 'vehicleBrandId' || id === 'vehicleBrandIdExclude') {
      inputValues[id] = vehicleBrandOptions.filter(
        ({ value: optionValue }) => filterValue.includes(optionValue),
      );
    } else if (id === 'vehicleModelId' || id === 'vehicleModelIdExclude') {
      inputValues[id] = vehicleModelOptions.filter(
        ({ value: optionValue }) => filterValue.includes(optionValue),
      );
    } else if (id === 'vehicleColorId' || id === 'vehicleColorIdExclude') {
      inputValues[id] = vehicleColorOptions.filter(
        ({ value: optionValue }) => filterValue.includes(optionValue),
      );
    } else if (id === 'vehicleEmergencyTypeId' || id === 'vehicleEmergencyTypeIdExclude') {
      inputValues[id] = vehicleEmergencyTypeOptions.filter(
        ({ value: optionValue }) => filterValue.includes(optionValue),
      );
    } else if (id === 'publicTransportTypeId' || id === 'publicTransportTypeIdExclude') {
      inputValues[id] = publicTransportTypeOptions.filter(
        ({ value: optionValue }) => filterValue.includes(optionValue),
      );
    } else if (id === 'specialTransportTypeId' || id === 'specialTransportTypeIdExclude') {
      inputValues[id] = specialTransportTypeOptions.filter(
        ({ value: optionValue }) => filterValue.includes(optionValue),
      );
    } else if (id === 'indicatorTypeId' || id === 'indicatorTypeIdExclude') {
      inputValues[id] = scenarioIndicatorTypeOptions.filter(
        ({ value: optionValue }) => filterValue.includes(optionValue),
      );
    } else if (id === 'scenarioId' || id === 'scenarioIdExclude') {
      inputValues[id] = scenarioOptions.filter(
        ({ value: optionValue }) => filterValue.includes(optionValue),
      );
    } else if (id === 'countryId' || id === 'countryIdExclude') {
      inputValues[id] = countryOptions.filter(
        ({ value: optionValue }) => filterValue.includes(optionValue),
      );
    } else if (id === 'camId' || id === 'camIdExclude') {
      inputValues[id] = camOptions.filter(
        ({ value: optionValue }) => filterValue.includes(optionValue),
      );
    } else inputValues[id] = filterValue;
  });

  return (
    <Page breadcrumbs={[{ caption: t('Инциденты') }]}>
      <IncidentsTable
        onShowScenario={onShowScenario}
        isFiltersVisible={isFiltersVisible}
      />

      <IncidentsFilterForm
        vehicleTypeOptions={vehicleTypeOptions}
        vehicleBrandOptions={vehicleBrandOptions}
        vehicleModelOptions={vehicleModelOptions}
        vehicleColorOptions={vehicleColorOptions}
        vehicleEmergencyTypeOptions={vehicleEmergencyTypeOptions}
        publicTransportTypeOptions={publicTransportTypeOptions}
        specialTransportTypeOptions={specialTransportTypeOptions}
        licensePlateFeatureOptions={licensePlateFeatureOptions}
        scenarioIndicatorTypeOptions={scenarioIndicatorTypeOptions.filter(({ type }) => type === 'incident')}
        scenarioOptions={scenarioOptions.filter(({ scenarioType }) => scenarioType === 'incident')}
        countryOptions={countryOptions}
        camOptions={camOptions}
        listOptions={listOptions}
        onReset={onReset}
        onFilter={onFilter}
        onExport={onExport}
        isVisible={isFiltersVisible}
        setIsVisible={setIsFiltersVisible}
        settings={settings}
        {...inputValues}
      />
    </Page>
  );
}

const dropdownOptionPropTypes = PropTypes.shape({
  id: PropTypes.oneOfType([PropTypes.string, PropTypes.number]),
  value: PropTypes.oneOfType([PropTypes.string, PropTypes.number]),
});

IncidentsPage.propTypes = {
  vehicleTypeOptions: PropTypes.arrayOf(dropdownOptionPropTypes),
  vehicleBrandOptions: PropTypes.arrayOf(dropdownOptionPropTypes),
  vehicleModelOptions: PropTypes.arrayOf(dropdownOptionPropTypes),
  vehicleColorOptions: PropTypes.arrayOf(dropdownOptionPropTypes),
  vehicleEmergencyTypeOptions: PropTypes.arrayOf(dropdownOptionPropTypes),
  publicTransportTypeOptions: PropTypes.arrayOf(dropdownOptionPropTypes),
  specialTransportTypeOptions: PropTypes.arrayOf(dropdownOptionPropTypes),
  licensePlateFeatureOptions: PropTypes.arrayOf(dropdownOptionPropTypes),
  scenarioIndicatorTypeOptions: PropTypes.arrayOf(dropdownOptionPropTypes),
  scenarioOptions: PropTypes.arrayOf(dropdownOptionPropTypes),
  countryOptions: PropTypes.arrayOf(dropdownOptionPropTypes),
  camOptions: PropTypes.arrayOf(dropdownOptionPropTypes),
  listOptions: PropTypes.arrayOf(dropdownOptionPropTypes),
  filters: PropTypes.arrayOf(PropTypes.shape(PropTypes.any)),
  setFilters: PropTypes.func,
  settings: PropTypes.object,
};
IncidentsPage.defaultProps = {
  vehicleTypeOptions: [],
  vehicleBrandOptions: [],
  vehicleModelOptions: [],
  vehicleColorOptions: [],
  vehicleEmergencyTypeOptions: [],
  publicTransportTypeOptions: [],
  specialTransportTypeOptions: [],
  licensePlateFeatureOptions: [],
  scenarioIndicatorTypeOptions: [],
  scenarioOptions: [],
  countryOptions: [],
  camOptions: [],
  listOptions: [],
  filters: [],
  setFilters: undefined,
  settings: {},
};

export default connect((state) => ({
  ...selectVehicleAttributes(state),
  camOptions: selectCamOptions(state),
  filters: selectIncidentsFilters(state),
  listOptions: selectListOptions(state).listOptions,
  scenarioOptions: selectScenarioOptions(state),
  settings: selectSettings(state),
}), (dispatch) => ({
  setFilters: (filters) => dispatch(updateParams({ filters })),
}))(IncidentsPage);
