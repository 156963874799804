import React, { useCallback, useMemo } from 'react';

import {
  Page, Control, Margin, Grid, GridRow, GridCol,
} from '@vlabs/uikit';
import { useForm } from 'react-hook-form';
import { useTranslation } from 'react-i18next';
// import { createFilter } from 'react-select';

import './ListItemForm.sass';
import { HAS_FLASHING_LIGHTS_OPTIONS } from 'api-bindings/luna-cars/constants';
import { FileDropzone } from 'components/file-dropzone/FileDropzone';

const MAX_IMAGE_FILE_SIZE = 5 * 1024 * 1024; // 5 Мб
const SUPPORTED_FORMATS = [
  'image/jpeg',
  'image/jpg',
  'image/png',
];

function ListItemForm({
  image = '',
  imageUri = '',
  countryIds = [],
  vehicleTypeIds = [],
  vehicleBrandIds = [],
  vehicleModelIds = [],
  vehicleColorIds = [],
  vehicleEmergencyTypeIds = [],
  vehicleEmergencyHasFlashingLight = null,
  vehicleLicensePlate = '',
  imageAccuracy = undefined,
  countryOptions = [],
  vehicleTypeOptions = [],
  vehicleBrandOptions = [],
  vehicleModelOptions = [],
  vehicleColorOptions = [],
  vehicleEmergencyTypeOptions = [],
  onSubmit = () => { },
}) {
  const { t } = useTranslation();
  const { control,
    register,
    handleSubmit,
    getValues,
    setValue,
    watch,
    formState: {
      touchedFields,
      errors },
  } = useForm({
    defaultValues: {
      image,
      imageUri,
      countryIds,
      vehicleTypeIds,
      vehicleBrandIds,
      vehicleModelIds,
      vehicleColorIds,
      vehicleEmergencyTypeIds,
      vehicleEmergencyHasFlashingLight,
      vehicleLicensePlate,
      imageAccuracy,
    },
  });

  const atLeastOneFieldIsFilled = (fields) => {
    return fields.some((field) => {
      if (field.value === null || field.value === undefined) return false;
      if (Array.isArray(field.value) || typeof field.value === 'string') return field.value?.length > 0;
      return Object.keys(field.value)?.length > 0;
    });
  };
  const fields = [
    'vehicleLicensePlate',
    'countryIds',
    'vehicleTypeIds',
    'vehicleBrandIds',
    'vehicleModelIds',
    'vehicleColorIds',
    'vehicleEmergencyTypeIds',
    'vehicleEmergencyHasFlashingLight',
    'image',
    'imageUri',
  ];
  register('atLeastOne', {
    validate: () => {
      if (!atLeastOneFieldIsFilled(fields.map((f) => ({ value: getValues(f) })))) {
        return 'Необходимо заполнить как минимум одно поле';
      }
    },
  });

  const modelOptions = useMemo(() => {
    const vehicleBrandIds = getValues('vehicleBrandIds');
    const selectedBrandIds = vehicleBrandIds
      ? vehicleBrandIds.map(({ value }) => value)
      : [];
    return vehicleBrandIds?.length > 0
      ? vehicleModelOptions
        .filter(({ vehicleBrandId }) => selectedBrandIds.includes(vehicleBrandId))
      : vehicleModelOptions;
  // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [vehicleModelOptions, watch('vehicleBrandIds')]);

  const onFileSelect = useCallback(() => {
    setValue('imageUri', undefined);
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);

  const watchedImage = watch('image');
  const watchedImageUri = watch('imageUri');

  return (
    <Page title={<Margin left bottom>{t('Запись списка')}</Margin>} className="ListItemForm">
      <form onSubmit={handleSubmit(onSubmit)}>
        <Grid>
          <GridRow>
            <GridCol cols={6}>
              <Margin display="block">
                <Control.Input
                  label={t('ГРЗ (неизвестные значения можно заменить символом *)')}
                  id="vehicleLicensePlate"
                  errors={errors}
                  {...register('vehicleLicensePlate')}
                />
              </Margin>
              <Margin display="block">
                <Control.Select
                  label={t('Страна')}
                  id="countryIds"
                  name="countryIds"
                  isClearable
                  isMulti
                  control={control}
                  options={countryOptions}
                          // filterOption={searchOnLabelsOnly}
                  errors={errors}
                />
              </Margin>
              <Margin display="block">
                <Control.Select
                  label={t('Тип ТС')}
                  id="vehicleTypeIds"
                  name="vehicleTypeIds"
                  isClearable
                  isMulti
                  control={control}
                  options={vehicleTypeOptions}
                          // filterOption={searchOnLabelsOnly}
                  errors={errors}
                />
              </Margin>
              <Margin display="block">
                <Control.Select
                  label={t('Марка ТС')}
                  id="vehicleBrandIds"
                  name="vehicleBrandIds"
                  isClearable
                  isMulti
                  control={control}
                  options={vehicleBrandOptions}
                  // filterOption={searchOnLabelsOnly}
                  // TODO добавить onChange в Control.Select
                  // onChange={handleBrandChange}
                  errors={errors}
                />
              </Margin>
              <Margin display="block">
                <Control.Select
                  label={t('Модель ТС')}
                  id="vehicleModelIds"
                  name="vehicleModelIds"
                  isClearable
                  isMulti
                  control={control}
                  options={modelOptions}
                          // filterOption={searchOnLabelsOnly}
                  errors={errors}
                />
              </Margin>
              <Margin display="block">
                <Control.Select
                  label={t('Экстренные службы')}
                  id="vehicleEmergencyTypeIds"
                  name="vehicleEmergencyTypeIds"
                  isClearable
                  isMulti
                  control={control}
                  options={vehicleEmergencyTypeOptions}
                          // filterOption={searchOnLabelsOnly}
                  errors={errors}
                />
              </Margin>
              <Margin display="block">
                <Control.Select
                  label={t('Проблесковый маячок')}
                  id="vehicleEmergencyHasFlashingLight"
                  name="vehicleEmergencyHasFlashingLight"
                  isClearable
                  control={control}
                  options={HAS_FLASHING_LIGHTS_OPTIONS.raw}
                          // filterOption={searchOnLabelsOnly}
                  errors={errors}
                />
              </Margin>
              <Margin display="block">
                <Control.Select
                  label={t('Цвет ТС')}
                  id="vehicleColorIds"
                  name="vehicleColorIds"
                  isClearable
                  isMulti
                  control={control}
                  options={vehicleColorOptions}
                          // filterOption={searchOnLabelsOnly}
                  errors={errors}
                />
              </Margin>
              {(watchedImage?.length || watchedImageUri) && (
              <Margin display="block">
                <Control.Input
                  {...register('imageAccuracy', {
                    validate: (value) => (value !== undefined && (value < 0 || value > 1)
                      ? t('Введите значение от {{min}} до {{max}}', { min: 0, max: 1 })
                      : undefined),
                    // min: { value: -180, message: t('Значение должно быть больше или равно {{min}}', { min: -180 }) },
                    // max: { value: 180, message: t('Значение должно быть меньше или равно {{max}}', { max: 180 }) },
                  })}
                  label={t('Порог схожести машин (от 0 до 1)')}
                  id="imageAccuracy"
                  errors={errors}
                  type="number"
                  step="0.01"
                />
              </Margin>
              )}
            </GridCol>
            <GridCol cols={6}>
              <Margin display="block">
                <FileDropzone
                  data-testid="image"
                  onChangeValue={onFileSelect}
                  id="image"
                  {...register('image')}
                  maxImageSize={MAX_IMAGE_FILE_SIZE}
                  error={errors?.file && errors?.file.message}
                  onlyImages
                  supportedImageTypes={SUPPORTED_FORMATS}
                  hasImagePreview
                  imageUrl={imageUri}
                />
                {errors.image && <span>{errors.image.message}</span>}
              </Margin>
            </GridCol>
          </GridRow>
        </Grid>
        <Margin display="block">
          {errors?.atLeastOne && Object.keys(touchedFields)?.length > 0 && (
            <span className="ErrorMessage Subtitle-2">{errors.atLeastOne.message}</span>
          )}
        </Margin>
        <Margin display="block">
          <Control.Button type="submit" fullWidth>
            {t('Сохранить')}
          </Control.Button>
        </Margin>
      </form>
    </Page>
  );
}

export default ListItemForm;
