/* eslint-disable no-unused-vars */
/* eslint-disable jsx-a11y/no-static-element-interactions */
/* eslint-disable jsx-a11y/click-events-have-key-events */
import React, { useState } from 'react';

import { CircleInfoIcon } from '@vlabs/icons';
import { Page, Control, Margin, Grid, GridRow, GridCol } from '@vlabs/uikit';
import cn from 'classnames';
import { FormProvider, useForm } from 'react-hook-form';
import { useTranslation } from 'react-i18next';
import { connect } from 'react-redux';
import { toast } from 'react-toastify';

import { selectVehicleAttributes } from 'store/vehicle/selectors';

import ImageSelector from 'components/file-selectors/ImageSelector';

import st from './ClassifyImageForm.module.sass';

const MAX_IMAGE_FILE_SIZE = 5 * 1024 * 1024; // 5 Мб
const SUPPORTED_FORMATS = [
  'image/jpeg',
  'image/jpg',
  'image/png',
];

export const defaultValues = {
  vehicleBrandId: true,
  vehicleModelId: true,
  countryId: true,
  vehicleLicensePlate: true,
  publicTransportTypeId: true,
  specialTransportTypeId: true,
  vehicleColorId: true,
  vehicleEmergencyTypeId: true,
  vehicleTypeId: true,
};

function ClassifyImageForm({
  countryOptions = [],
  vehicleBrandOptions = [],
  vehicleColorOptions = [],
  vehicleModelOptions = [],
  vehicleTypeOptions = [],
  vehicleEmergencyTypeOptions = [],
  publicTransportTypeOptions = [],
  specialTransportTypeOptions = [],
  onUpload = undefined,
  onSubmit = undefined,
}) {
  const { t } = useTranslation();
  const [filters, setFilters] = useState(undefined);
  const [upload, setUpload] = useState();

  const formMethods = useForm({ defaultValues });
  const { handleSubmit, register } = formMethods;

  const classifyObjectOptions = [
    { name: 'vehicleBrandId', label: t('Марка'), options: vehicleBrandOptions },
    { name: 'vehicleModelId', label: t('Модель'), options: vehicleModelOptions },
    { name: 'countryId', label: t('Страна'), options: countryOptions },
    { name: 'vehicleLicensePlate', label: t('ГРЗ') },
    { name: 'publicTransportTypeId', label: t('Общ. транспорт'), options: publicTransportTypeOptions },
    { name: 'specialTransportTypeId', label: t('Спец. техника'), options: specialTransportTypeOptions },
    { name: 'vehicleColorId', label: t('Цвет'), options: vehicleColorOptions },
    { name: 'vehicleEmergencyTypeId', label: t('Экстренные службы'), options: vehicleEmergencyTypeOptions },
    { name: 'vehicleTypeId', label: t('Тип'), options: vehicleTypeOptions },
  ];

  const $onUpload = async (file) => {
    if (!file) return;

    const getValueLabel = (options, data, name) => {
      if (!data?.[name]) return undefined;
      if (options) return options.find(({ value: id }) => data[name] === id)?.label;
      return data[name];
    };

    try {
      const uploadedData = await onUpload(file);
      setUpload(uploadedData);
      const data = classifyObjectOptions
        .map(({ name, label, options }) => ({
          name,
          label,
          value: uploadedData?.[name],
          valueLabel: getValueLabel(options, uploadedData, name),
        }));
      setFilters(data);
    } catch ({ response }) {
      toast.error(`${t('Серверная ошибка')}: ${response?.data}`);
    }
  };

  const $onSubmit = async (data) => {
    const filters2 = {};
    const filteredParams = Object.fromEntries(
      Object.entries(data).filter(([_, value]) => value),
    );
    Object.keys(filteredParams).forEach((name) => {
      const { value, valueLabel: label } = filters.find(({ name: name2 }) => name2 === name);
      filters2[name] = name === 'vehicleLicensePlate' ? value : [{ value, label }];
    });
    if (onSubmit) onSubmit(filters2);
  };

  return (
    <Page className={st.Form}>
      <Margin left bottom>
        <h5>
          {t('Автозаполнение фильтров по изображению транспортного средства')}
        </h5>
      </Margin>

      <Grid>
        <GridRow>
          <GridCol cols={8}>
            <Margin display="block">
              <ImageSelector
                maxImageSize={MAX_IMAGE_FILE_SIZE}
                supportedImageTypes={SUPPORTED_FORMATS}
                onChange={$onUpload}
                detections={upload?.carDetections ? [upload?.carDetections] : undefined}
              />
            </Margin>
          </GridCol>

          <GridCol>
            <Margin display="block">
              <h6>
                {t('Выберите фильтры:')}
              </h6>
              {!filters?.length && (
                <p className={st.Flex}>
                  <CircleInfoIcon className={st.Icon} />
                  <span className={cn(st.Info)}>
                    {t('Загрузите изображение с транспортным средством для распознавания данных.')}
                  </span>
                </p>
              )}
            </Margin>

            <FormProvider {...formMethods}>
              <form
                onSubmit={handleSubmit($onSubmit)}
                id="search-by-image-form"
              >
                {filters?.length > 0 && filters.map(({ name, label, valueLabel }) => {
                  return (
                    <div key={name}>
                      <Margin display="block">
                        {valueLabel && (
                        <Control.Checkbox
                          id={`${name}_classify`}
                          {...register(name)}
                          className={st.Checkbox}
                        />
                        )}
                        <span className={st.Label}>
                          {`${label}: ${valueLabel || t('Не распознан')}`}
                        </span>
                      </Margin>
                    </div>
                  );
                })}
              </form>
            </FormProvider>
          </GridCol>
        </GridRow>
      </Grid>
      <Margin left>
        <Control.Button
          type="submit"
          form="search-by-image-form"
        >
          {t('Применить')}
        </Control.Button>
      </Margin>
    </Page>
  );
}

export default connect((state) => ({
  ...selectVehicleAttributes(state),
}))(ClassifyImageForm);
