import React from 'react';

import cn from 'classnames';

import './CamCell.sass';

export default function CamCell({
  value,
}) {
  return (
    <a
      className={cn({
        CamCell: true,
        CamCell_disabled: !value?.watchUri,
      })}
      href={`/cams/${value?.id}/stream`}
    >
      {value?.name}
    </a>
  );
}
