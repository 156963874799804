import React, { useState } from 'react';

import { Pages, Modal } from '@vlabs/uikit';
import i18n from 'i18next';
import { useTranslation } from 'react-i18next';
import { useDispatch } from 'react-redux';
import { useHistory } from 'react-router-dom';
import { toast } from 'react-toastify';

import { lunaCarsClient } from 'api';
import { fetchCurrentUser } from 'features/accounts/accountSlice';
import ForgotPasswordForm from 'features/auth/ForgotPasswordForm';

export default function LoginPage() {
  const { t } = useTranslation();
  const dispatch = useDispatch();
  const history = useHistory();
  const [error, setError] = useState(null);

  const [modalIsOpen, setModalIsOpen] = useState(false);
  const closeModal = () => {
    setModalIsOpen(false);
  };

  const loginHandler = async ({ login, password }) => {
    try {
      await lunaCarsClient.auth.login(login, password);
      dispatch(fetchCurrentUser);
      history.push('/');
    } catch ({ response }) {
      if (response?.status === 401) return setError(i18n.t('Логин или пароль неверны'));
      if (response?.status !== 500) return toast.error(`${t('Серверная ошибка')}: ${response?.data}`);
    }
  };

  const forgotPasswordHandler = ({ email }) => {
    lunaCarsClient.auth.forgotPassword(email);
    toast.info(t('Ссылка для создания нового пароля была отправлена на указанный email'));
    closeModal();
  };

  const onForgotPassword = () => {
    setModalIsOpen(true);
  };

  return (
    <>
      <Modal
        isOpen={modalIsOpen}
        onRequestClose={closeModal}
        appElement={document.getElementById('root')}
      >
        <ForgotPasswordForm
          onSubmit={forgotPasswordHandler}
        />
      </Modal>
      <Pages.LoginPage
        error={error}
        onSubmit={loginHandler}
        onForgotPassword={onForgotPassword}
      />
    </>
  );
}
