import React, { useEffect } from 'react';

import { Pages } from '@vlabs/uikit';
import i18n from 'i18next';
import { useDispatch } from 'react-redux';
import { useHistory, useParams } from 'react-router-dom';
import { toast } from 'react-toastify';

import { lunaCarsClient } from 'api';
import { resetPasswordLogout } from 'features/accounts/accountSlice';

export default function ResetPage() {
  const history = useHistory();
  const { token } = useParams();
  const dispatch = useDispatch();

  useEffect(() => {
    dispatch(resetPasswordLogout);
  }, [dispatch]);

  const resetHandler = async ({ password }) => {
    try {
      await lunaCarsClient.auth.resetPassword(token, password);
      history.push('/');
    } catch (error) {
      toast.error(i18n.t('Ошибка! Пароль не изменен!'));
    }
  };

  return <Pages.ResetPasswordPage onSubmit={resetHandler} />;
}
