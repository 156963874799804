import React, { useCallback, useState } from 'react';

import {
  Page, Table, TableCells, Panel, useTableHook, openConfirmPopup, Modal,
} from '@vlabs/uikit';
import { useTranslation } from 'react-i18next';
import { connect, useDispatch } from 'react-redux';
import { useHistory } from 'react-router-dom';
import { toast } from 'react-toastify';

import { selectAccountRole } from 'features/accounts/selectors';

import { lunaCarsClient } from 'api';
import { PAGE_SIZE_OPTIONS } from 'api-bindings/luna-cars/constants';
import ListForm from 'features/lists/ListForm';
import { fetchListOptions } from 'features/lists/listsSlice';
import { can } from 'utils/can';
import { actionColumnProps } from 'utils/helpers';

function ListsPage({
  role,
}) {
  const { t } = useTranslation();
  const history = useHistory();
  const dispatch = useDispatch();
  const [modalIsOpen, setModalIsOpen] = useState(false);
  const [selectedList, setSelectedList] = useState(null);
  const closeModal = () => {
    setModalIsOpen(false);
    setSelectedList(null);
  };

  const onFetch = ({ page = 0, ...params }) => lunaCarsClient.lists
    .showAll({ page: page + 1, ...params })
    .then(({ data }) => data);
  const tableProps = useTableHook(useCallback(onFetch, []));

  const onSelect = ({ id }) => history.push(`/lists/${id}`);

  const onShowForm = (list) => {
    if (list) {
      setSelectedList(list);
    }
    setModalIsOpen(true);
  };

  const handleApiCall = () => {
    dispatch(fetchListOptions);
    tableProps.onFetch();
  };

  const onFormSubmit = async ({ name }) => {
    const values = { name };
    try {
      if (selectedList) {
        await lunaCarsClient.lists.update(selectedList.id, values);
        closeModal();
        handleApiCall();
      } else {
        await lunaCarsClient.lists.create(values);
        closeModal();
        handleApiCall();
      }
      setSelectedList(null);
    } catch ({ response }) {
      return toast.error(`${t('Серверная ошибка')}: ${response?.data}`);
    }
  };

  const onDelete = (data) => {
    let message;
    let onConfirm;
    if (data instanceof Array) {
      message = (
        <span>
          {`${t('Вы уверены, что хотите удалить')} `}
          <strong>{data.length}</strong>
          {`${t('выбранных списка')}`}
          ?
        </span>
      );
      onConfirm = async () => {
        await Promise.all(data.map((list) => lunaCarsClient.lists.delete(list.id)));
        handleApiCall();
      };
    } else {
      const list = data;
      message = (
        <span>
          {`${t('Вы уверены, что хотите удалить список')} `}
          <strong>{list.name}</strong>
          ?
        </span>
      );
      onConfirm = async () => {
        await lunaCarsClient.lists.delete(list.id);
        handleApiCall();
      };
    }

    openConfirmPopup({
      title: t('Удаление списка'),
      message,
      type: 'delete',
      onConfirm,
    });
  };
  return (
    <Page>
      <Modal
        isOpen={modalIsOpen}
        onRequestClose={closeModal}
        appElement={document.getElementById('root')}
      >
        <ListForm
          onSubmit={onFormSubmit}
          {...selectedList}
        />
      </Modal>
      <Panel>
        <Table
          {...tableProps}
          paginationType="offsetBased"
          pageSizeOptions={PAGE_SIZE_OPTIONS}
          columns={[
            { Header: t('Наименование'), accessor: 'name' },
            { Header: t('Количество номеров'), accessor: 'itemsCount' },
            { Header: t('Дата создания'), accessor: 'createdAt', Cell: TableCells.DateCell },
            actionColumnProps({
              id: 'edit', Cell: TableCells.EditCell,
            }),
            actionColumnProps({
              id: 'delete', Cell: TableCells.DeleteCell,
            }),
          ]}
          onActions={{
            onClickRow: { handler: onSelect, can: () => can(role, 'lists:read') },
            onAddRow: { handler: onShowForm, can: () => can(role, 'lists:create') },
            onEditRow: { handler: onShowForm, can: () => can(role, 'lists:update') },
            onDeleteRow: { handler: onDelete, can: () => can(role, 'lists:delete') },
          }}
        />
      </Panel>
    </Page>
  );
}

export default connect((state) => ({
  role: selectAccountRole(state),
}))(ListsPage);
