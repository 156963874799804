import React, { useState, useEffect } from 'react';

import { ImageIcon, RotationRightIcon, PackageIcon } from '@vlabs/icons';
import cn from 'classnames';
import PropTypes from 'prop-types';
import { useTranslation } from 'react-i18next';

import Dropzone from './dropzone/Dropzone';

import './FileSelector.sass';

const SUPPORTED_IMAGE_TYPES = ['image/jpeg', 'image/png'];
const MAX_IMAGE_SIZE = 2 * 1024 * 1024;
const SUPPORTED_ARCHIVE_TYPES = ['application/x-zip-compressed', 'application/zip'];
const MAX_ARCHIVE_SIZE = 1024 * 1024 * 1024;

const readAsUrl = async (image) => new Promise((resolve) => {
  const reader = new FileReader();
  reader.onloadend = () => resolve(reader.result);
  reader.readAsDataURL(image);
});

const ImageCaption = ({ name }) => {
  const { t } = useTranslation();
  return (
    <div className="Caption ImageIcon">
      <ImageIcon />
      <span className="Subtitle-2">{`${t('Выбрано изображение')}: ${name}`}</span>
    </div>
  );
};

const ArchiveCaption = ({ name }) => {
  const { t } = useTranslation();
  return (
    <div className="Caption">
      <PackageIcon />
      <span className="Subtitle-2">{`${t('Выбран архив')}: ${name}`}</span>
    </div>
  );
};

const WrongImageSizeCaption = ({ maxImageSize }) => {
  const { t } = useTranslation();
  return (
    <span className="Subtitle-2">
      {t('Размер изображения не должен превышать')}
&nbsp;
      {Math.round(maxImageSize / 1024 / 1024)}
      {t('Мб')}
    </span>
  );
};
const WrongArchiveSizeCaption = () => {
  const { t } = useTranslation();
  return (
    <span className="Subtitle-2">
      {t('Размер архива не должен превышать')}
&nbsp;
      {Math.round(MAX_ARCHIVE_SIZE / 1024 / 1024 / 1024)}
      {t('Гб')}
    </span>
  );
};
const WrongImageCaption = () => {
  const { t } = useTranslation();
  return (
    <span className="Subtitle-2">{t('Неподходящий формат изображения')}</span>
  );
};
const WrongArchiveCaption = () => {
  const { t } = useTranslation();
  return (
    <span className="Subtitle-2">{t('Неподходящий формат файла')}</span>
  );
};
const WrongFileCaption = () => {
  const { t } = useTranslation();
  return (
    <span className="Subtitle-2">{t('Неподходящий формат файла')}</span>
  );
};

const FileSelector = ({
  onSelect,
  onReset,
  caption,
  disabled,
  file: inputFile,
  supportedImageTypes,
  supportedArchiveTypes,
  maxImageSize,
  maxArchiveSize,
  error: inputError,
}) => {
  const [file, setFile] = useState(inputFile);
  const [error, setError] = useState(undefined);

  const selectFile = async (selectingFile) => {
    if (selectingFile) {
      if (selectingFile.type.startsWith('image/')) {
        if (selectingFile.size > maxImageSize) {
          setError(<WrongImageSizeCaption maxImageSize={maxImageSize} />);
          return;
        }
        if (supportedImageTypes.includes(selectingFile.type)) {
          const binary = await readAsUrl(selectingFile);
          setFile({
            type: 'image',
            raw: selectingFile,
            binary,
          });
        } else {
          setError(<WrongImageCaption />);
        }
      } else if (selectingFile.type.startsWith('application/')) {
        if (selectingFile.size > maxArchiveSize) {
          setError(<WrongArchiveSizeCaption />);
          return;
        }
        if (supportedArchiveTypes.includes(selectingFile.type)) {
          setFile({
            type: 'archive',
            raw: selectingFile,
          });
        } else {
          setError(<WrongArchiveCaption />);
        }
      } else {
        setError(<WrongFileCaption />);
      }
    }
  };

  const reset = () => {
    setFile(undefined);
    setError(undefined);
    if (onSelect) {
      onSelect(undefined);
    }
    if (onReset) onReset();
  };

  useEffect(() => {
    if (!inputFile) reset();
    // eslint-disable-next-line
  }, [inputFile]);

  useEffect(() => {
    if (file && onSelect) onSelect(file);
  }, [onSelect, file]);

  useEffect(() => {
    setError(inputError);
  }, [inputError]);

  let currentCaption;
  if (caption) {
    currentCaption = caption;
  } else if (file && file.raw && supportedImageTypes.includes(file.raw.type)) {
    currentCaption = <ImageCaption name={file.raw.name} />;
    if (error) setError(undefined);
  } else if (file && file.raw && supportedArchiveTypes.includes(file.raw.type)) {
    currentCaption = <ArchiveCaption name={file.raw.name} />;
    if (error) setError(undefined);
  }

  return (
    <div
      className={cn({
        FileSelector: true,
        FileSelector__Selected: file,
      })}
    >
      {file && <RotationRightIcon className="ResetButton" onClick={reset} />}
      <Dropzone
        accept={[...supportedImageTypes, ...supportedArchiveTypes]}
        caption={currentCaption}
        onFilesAdded={selectFile}
        disabled={disabled}
        error={error}
        file={file}
      />
    </div>
  );
};

FileSelector.propTypes = {
  onSelect: PropTypes.func,
  onReset: PropTypes.func,
  caption: PropTypes.node,
  disabled: PropTypes.bool,
  file: PropTypes.shape({
    raw: PropTypes.instanceOf(File),
    type: PropTypes.string,
  }),
  supportedImageTypes: PropTypes.arrayOf(PropTypes.string),
  supportedArchiveTypes: PropTypes.arrayOf(PropTypes.string),
  maxImageSize: PropTypes.number,
  maxArchiveSize: PropTypes.number,
  error: PropTypes.node,
};

FileSelector.defaultProps = {
  onSelect: () => {},
  onReset: () => {},
  caption: undefined,
  disabled: false,
  file: undefined,
  supportedImageTypes: SUPPORTED_IMAGE_TYPES,
  supportedArchiveTypes: SUPPORTED_ARCHIVE_TYPES,
  maxImageSize: MAX_IMAGE_SIZE,
  maxArchiveSize: MAX_ARCHIVE_SIZE,
  error: undefined,
};

export default FileSelector;
