import React, { useEffect, useState } from 'react';

import { SettingsItemWrapper, Margin, Control, ROIPreview } from '@vlabs/uikit';
import PropTypes from 'prop-types';
import { useFormContext } from 'react-hook-form';
import { useTranslation } from 'react-i18next';

import ParameterSubForm from 'features/cams/ParameterSubForm';

import './CamSettingForm.sass';

function CamSettingForm({
  fullCamOptions,
}) {
  const { t } = useTranslation();
  const { control, watch, setValue, formState: { errors } } = useFormContext();
  const [regions, setRegions] = useState();

  useEffect(() => {
    const sub = watch((data, { name }) => {
      if (name === undefined && data?.camera?.regions) setRegions(data?.camera?.regions);
      if (name === 'camera') {
        setRegions(data?.camera?.regions);
        setValue(
          'parameters',
          data.parameters?.map(({ value, ...rest }) => (
            typeof value === 'object'
              ? { ...rest, value: null }
              : { ...rest, value }
          )),
        );
      }
    });

    return () => sub.unsubscribe();
  }, [watch, setValue]);

  return (
    <Margin display="block">
      <Margin display="block">
        {watch('camera.previewUri') && (
          <ROIPreview
            preview={watch('camera.previewUri')}
            roi={watch('camera.roiRectangle')}
            droi={watch('camera.regions')?.map(({ points, tagName, displayColor }) => ({
              points,
              name: tagName,
              color: displayColor,
            }))}
            isNormalized={false}
          />
        )}
        {!watch('camera.previewUri') && (
          <ROIPreview
            preview="/camDef.png"
            roi={watch('camera.roiRectangle') || {}}
            droi={watch('camera.regions')?.map(({ points, tagName, displayColor }) => ({
              points,
              name: tagName,
              color: displayColor,
            }))}
            isNormalized={false}
          />
        )}
      </Margin>

      <SettingsItemWrapper title={t('Камера')}>
        <div className="CamSettingForm__Dropdown">
          <Control.Select
            name="camera"
            control={control}
            options={fullCamOptions}
            errors={errors}
          />
        </div>
      </SettingsItemWrapper>

      <h5 className="CamSettingForm__ParametersTitle">
        {t('Параметры')}
      </h5>
      <ParameterSubForm
        fieldName="parameters"
        regionOptions={regions?.map(({ id, tagName, displayColor }) => ({
          value: id,
          label: tagName,
          displayColor,
        }))}
      />
    </Margin>
  );
}

CamSettingForm.propTypes = {
  fullCamOptions: PropTypes.array,
};

CamSettingForm.defaultProps = {
  fullCamOptions: [],
};

export default CamSettingForm;
