import React from 'react';

import { Control } from '@vlabs/uikit';
import PropTypes from 'prop-types';
import { useTranslation } from 'react-i18next';

const readAsUrl = async (image) => new Promise((resolve) => {
  const reader = new FileReader();
  reader.onloadend = () => resolve(reader.result);
  reader.readAsDataURL(image);
});

const FileInputButton = ({ onSelect }) => {
  const { t } = useTranslation();
  const fileInputRef = React.createRef();

  const onSetCameraPreview = async (evt) => {
    const { files } = evt.target;
    if (!files?.[0]) return;
    const binary = await readAsUrl(files[0]);
    if (onSelect) {
      onSelect({
        type: 'image',
        raw: files[0],
        binary,
      });
    }
  };

  return (
    <Control.Button onClick={() => fileInputRef.current.click()} fullWidth>
      {t('Загрузить превью из файла')}
      <input
        ref={fileInputRef}
        style={{ display: 'none' }}
        type="file"
        accept={['image/png', 'image/jpeg']}
        onChange={onSetCameraPreview}
        aria-label={t('Загрузить превью из файла')}
      />
    </Control.Button>
  );
};

FileInputButton.propTypes = {
  onSelect: PropTypes.func,
};

FileInputButton.defaultProps = {
  onSelect: undefined,
};

export default FileInputButton;
