import React from 'react';

import {
  Control, Page, Margin, regexp,
} from '@vlabs/uikit';
import { useForm } from 'react-hook-form';
import { useTranslation } from 'react-i18next';

import './ForgotPasswordForm.sass';

function ForgotPasswordForm({
  email = '',
  onSubmit = () => {},
}) {
  const { t } = useTranslation();
  const formMethods = useForm({ defaultValues: { email } });
  const { handleSubmit, register, formState: { errors } } = formMethods;
  return (
    <form onSubmit={handleSubmit(onSubmit)}>
      <Page title={<Margin left bottom>{t('Сброс пароля')}</Margin>} className="ForgotPasswordForm">
        <Margin display="block">
          <Control.Input
            placeholder={t('Email')}
            id="email"
            {...register('email', {
              required: t('Email обязателен к заполнению'),
              pattern: {
                value: regexp.email,
                message: t('Неверный формат ввода email'),
              },
            })}
            errors={errors}
          />
        </Margin>
        <Margin display="block">
          <Control.Button
            type="submit"
            fullWidth
          >
            {t('Сбросить')}
          </Control.Button>
        </Margin>
      </Page>
    </form>
  );
}

export default ForgotPasswordForm;
