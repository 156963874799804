import { createSlice } from '@reduxjs/toolkit';

import { lunaCarsClient } from 'api';

const expandOptions = (data) => data.map(({ id, title, ...rest }) => ({
  value: id,
  label: title,
  ...rest,
}));

const expandHandlerTypeOptions = (data) => data.map(({ systemName, title, ...rest }) => ({
  value: systemName,
  label: title,
  ...rest,
}));

const vehicleAttributesSlice = createSlice({
  name: 'vehicleAttributes',
  initialState: {
    vehicleTypeOptions: [],
    vehicleBrandOptions: [],
    vehicleModelOptions: [],
    vehicleColorOptions: [],
    vehicleColorTypeOptions: [],
    vehicleEmergencyTypeOptions: [],
    publicTransportTypeOptions: [],
    specialTransportTypeOptions: [],
    countryOptions: [],
    scenarioIndicatorTypeOptions: [],
    handlerTypeOptions: [],
  },
  reducers: {
    setVehicleAttributesOptions(state, { payload }) {
      const { attribute, data } = payload;

      if (attribute !== 'handlerTypeOptions') {
        state[attribute] = expandOptions(data);
      }

      if (attribute === 'handlerTypeOptions') {
        state[attribute] = expandHandlerTypeOptions(data);
      }
    },
  },
});

export default vehicleAttributesSlice.reducer;

export const {
  setVehicleAttributesOptions,
} = vehicleAttributesSlice.actions;

const setVehicleAttribute = (attribute, dispatch) => ({ data: { data } }) => dispatch(
  setVehicleAttributesOptions({ attribute, data }),
);
export const fetchVehicleOptions = async (dispatch) => {
  lunaCarsClient.filters.vehicleTypes().then(setVehicleAttribute('vehicleTypeOptions', dispatch));
  lunaCarsClient.filters.vehicleBrands().then(setVehicleAttribute('vehicleBrandOptions', dispatch));
  lunaCarsClient.filters.vehicleModels().then(setVehicleAttribute('vehicleModelOptions', dispatch));
  lunaCarsClient.filters.vehicleColors().then(setVehicleAttribute('vehicleColorOptions', dispatch));
  lunaCarsClient.filters.vehicleColorTypes().then(setVehicleAttribute('vehicleColorTypeOptions', dispatch));
  lunaCarsClient.filters.vehicleEmergencyTypes().then(setVehicleAttribute('vehicleEmergencyTypeOptions', dispatch));
  lunaCarsClient.filters.scenarioIndicatorTypes().then(setVehicleAttribute('scenarioIndicatorTypeOptions', dispatch));
  lunaCarsClient.filters.handlerTypes().then(setVehicleAttribute('handlerTypeOptions', dispatch));
  lunaCarsClient.filters.countries().then(setVehicleAttribute('countryOptions', dispatch));
  lunaCarsClient.filters.publicTransportTypes().then(setVehicleAttribute('publicTransportTypeOptions', dispatch));
  lunaCarsClient.filters.specialTransportTypes().then(setVehicleAttribute('specialTransportTypeOptions', dispatch));
  lunaCarsClient.filters.licensePlateFeatures().then(setVehicleAttribute('licensePlateFeatureOptions', dispatch));
};
