import React from 'react';

import { VideoIcon } from '@vlabs/icons';
import { Control } from '@vlabs/uikit';

import './Cam.sass';

const WatchStreamCell = (
  onShowStream,
  row,
) => {
  const { watchUri, parameters } = row;
  const streamPreviewValue = parameters?.find(({ systemName }) => systemName === 'generalGroup')?.value?.find(({ systemName }) => systemName === 'streamPreview')?.value;
  return (
    <>
      {(!watchUri || streamPreviewValue === 0) && (
      <Control.RoundButton
        data-testid="watchStreamCell"
        variant="dimmed"
        icon={<VideoIcon />}
        disabled
        className="Cam__Icon"
      />
      )}
      {watchUri && streamPreviewValue === 1 && (
        <a
          href={row.watchUri}
          target="_blank"
          rel="noopener noreferrer"
        >
          <Control.RoundButton
            data-testid="watchStreamCell"
            variant="dimmed"
            icon={<VideoIcon />}
            className="Cam__Icon"
          />
        </a>
      )}
      {watchUri
        && (streamPreviewValue === 2 || streamPreviewValue === undefined)
        && (
          <Control.RoundButton
            data-testid="watchStreamCell"
            variant="dimmed"
            className="Cam__Icon"
            onClick={() => onShowStream(row)}
            icon={<VideoIcon />}
          />
        )}
    </>
  );
};

export default WatchStreamCell;
