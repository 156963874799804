/* eslint-disable jsx-a11y/no-noninteractive-element-interactions */
/* eslint-disable jsx-a11y/click-events-have-key-events */
import React, { useState } from 'react';

import { Modal } from '@vlabs/uikit';

import { FRAGMENT_TYPE_OPTIONS } from 'api-bindings/luna-cars/constants';
import FragmentForm from 'components/fragment-form/FragmentForm';

export default function FrameList({ label, fragments, type }) {
  const [isModalOpen, setIsModalOpen] = useState(false);
  const [image, setImage] = useState({});
  const filterFragments = (values) => {
    if (!(type === 'Человек' || type === 'Пешеход' || type === 'Животное')) return values;
    return values.filter(({ type }) => type !== 'license_plate_crop');
  };

  const closeROIModal = () => {
    setIsModalOpen(false);
    setImage(null);
  };

  const openModal = (fragment) => {
    setImage({ ...fragment });
    setIsModalOpen(true);
  };

  return (
    <>
      <Modal
        isOpen={isModalOpen}
        onRequestClose={closeROIModal}
        appElement={document.getElementById('root')}
      >
        <FragmentForm {...image} />
      </Modal>
      <div className="IncidentPage__ImageContainer">
        <h6>{label}</h6>
        {filterFragments(fragments).map((fragment) => {
          if (fragment.image === null || fragment.image === undefined) return null;
          let fragmentLabel = FRAGMENT_TYPE_OPTIONS[fragment.type]?.label;
          if ((type === 'Человек' || type === 'Пешеход') && fragment.type === 'vehicle_crop') fragmentLabel = 'Кроп пешехода';
          if (type === 'Животное' && fragment.type === 'vehicle_crop') fragmentLabel = 'Кроп животного';
          return (
            <div className="IncidentPage__ImageSubContainer">
              <img
                className="IncidentPage__Image"
                src={fragment.image}
                alt={fragment.label}
                onClick={() => openModal(fragment)}
              />
              {fragmentLabel}
            </div>
          );
        })}
      </div>
    </>
  );
}
