import React, { useEffect, useState } from 'react';

import { useWindowSize } from '@vlabs/uikit';
import cn from 'classnames';
import { t } from 'i18next';
import PropTypes from 'prop-types';

import st from './BboxImageSelector.module.sass';

function BboxImageSelector({
  image,
  detections,
  className,
  alt,
}) {
  const windowSize = useWindowSize();
  const [renderedImage, setRenderedImage] = useState(undefined);
  const [selectors, setSelectors] = useState([]);

  useEffect(() => {
    if (!renderedImage) return;
    if (!detections) return;

    const ratioW = renderedImage.width / renderedImage.naturalWidth;
    const ratioH = renderedImage.height / renderedImage.naturalHeight;
    const ratio = Math.min(ratioH, ratioW);
    if (detections) {
      setSelectors(detections.map(({
        x, y, width, height,
      }) => ({
        width: width * ratio,
        height: height * ratio,
        left: x * ratio,
        top: y * ratio,
      })));
    }
  }, [detections, renderedImage, windowSize]);
  return (
    <div className={st.Root}>
      <img
        src={image}
        className={cn({
          [st.Image]: true,
          [className]: className,
        })}
        onLoad={({ target }) => setRenderedImage(target)}
        onChange={({ target }) => setRenderedImage(target)}
        alt={alt || t('Фотография для выбора детекции')}
      />
      {selectors.map(({
        width, height, left, top,
      }, i) => (
        <div
          // eslint-disable-next-line react/no-array-index-key
          key={i}
          className={st.Selector}
          style={{
            width,
            height,
            left,
            top,
          }}
        />
      ))}
    </div>
  );
}

BboxImageSelector.propTypes = {
  image: PropTypes.string,
  detections: PropTypes.arrayOf(PropTypes.any),
  className: PropTypes.string,
  alt: PropTypes.string,
};

BboxImageSelector.defaultProps = {
  image: undefined,
  detections: [],
  className: undefined,
  alt: undefined,
};

export default BboxImageSelector;
