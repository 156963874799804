import React from 'react';

import { ArrowInCircleRightIcon } from '@vlabs/icons';
import cn from 'classnames';
import PropTypes from 'prop-types';
import { Link } from 'react-router-dom';

import './GoTo.sass';

const GoTo = ({
  to,
  children,
  className,
  ...props
}) => {
  return (
    <Link
      className={cn('GoTo', className)}
      to={to}
      {...props}
    >
      {children}
      <ArrowInCircleRightIcon className="GoTo__Icon" />
    </Link>
  );
};

GoTo.propTypes = {
  to: PropTypes.string.isRequired,
  children: PropTypes.node,
  className: PropTypes.string,
};

GoTo.defaultProps = {
  children: undefined,
  className: undefined,
};

export { GoTo };
