import { createSelector } from '@reduxjs/toolkit';

export const selectIncidents = (state) => state.incidents;
export const selectIncidentsFilters = (state) => state.incidents.filters;
export const selectIsFoldOpen = (state) => state.incidents.isFoldOpen;

export const selectUnreadCount = createSelector(
  selectIncidents,
  ({ meta: { unreadCount } }) => unreadCount || 0,
);
