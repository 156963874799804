import React, { useEffect, useCallback, useRef, useMemo } from 'react';

import {
  Page, Divider, Panel, Table, TableCells, openConfirmPopup, useTableHook, TableFilters,
} from '@vlabs/uikit';
import PropTypes from 'prop-types';
import { useTranslation } from 'react-i18next';
import { connect } from 'react-redux';

import { selectAccountModel } from 'features/accounts/selectors';

import { lunaCarsClient } from 'api';
import { STATUS_OPTIONS, PAGE_SIZE_OPTIONS, TYPE_OPTIONS } from 'api-bindings/luna-cars/constants';
import CancelCell from 'components/cancel-cell/CancelCell';
import ProgressCell from 'components/progress-cell/ProgressCell';
import { roles } from 'features/accounts/rbac-rules';
import { actionColumnProps } from 'utils/helpers';

import CustomActionCell from './CustomActionCell';

function TasksPage({ account }) {
  const { t } = useTranslation();

  const intervalRef = useRef();

  const onFetch = ({ page = 0, ...params }) => lunaCarsClient.tasks
    .showAll({ page: page + 1, ...params })
    .then(({ data }) => data);

  const tableProps = useTableHook(useCallback(onFetch, []));

  useEffect(() => {
    const intervalId = setInterval(tableProps.onFetch, 10000);
    intervalRef.current = intervalId;
    return () => clearInterval(intervalRef.current);
  }, [tableProps.onFetch]);

  const onDeleteHandler = (task) => {
    const message = (
      <span>
        {t('Вы уверены, что хотите удалить задачу?')}
      </span>
    );

    const onConfirm = async () => {
      await lunaCarsClient.tasks.delete(task.id);
      tableProps.onFetch();
    };

    openConfirmPopup({
      title: t('Удаление задачи'),
      message,
      type: 'delete',
      onConfirm,
    });
  };

  const onCancelHandler = useCallback((task) => {
    const message = (
      <span>
        {t('Вы уверены, что хотите остановить выполнение задачи?')}
      </span>
    );

    const onConfirm = async () => {
      await lunaCarsClient.tasks.cancel(task.id);
      tableProps.onFetch();
    };

    openConfirmPopup({
      title: t('Остановка задачи'),
      message,
      type: 'warning',
      onConfirm,
    });
  // eslint-disable-next-line
  }, [t, tableProps.onFetch]);

  const columns = useMemo(() => {
    const result = [
      { Header: 'ID', accessor: 'id' },
      {
        Header: t('Тип'),
        accessor: 'type',
        Cell: ({ value: type }) => TYPE_OPTIONS[type]?.label || type,
        Filter: TableFilters.OneOfFilter({ options: TYPE_OPTIONS.raw }),
      },
      {
        Header: t('Статус выполнения'),
        accessor: 'status',
        Cell: (row) => ProgressCell(row),
        Filter: TableFilters.OneOfFilter({ options: STATUS_OPTIONS.raw }),
      },
      { Header: t('Дата создания'), accessor: 'createdAt', Cell: TableCells.DateCell },
      { Header: t('Дата завершения'), accessor: 'finishedAt', Cell: TableCells.DateCell },
      actionColumnProps({ id: 'action_custom', Cell: CustomActionCell, width: 30 }),
      actionColumnProps({ id: 'action_cancel', Cell: (row) => CancelCell(onCancelHandler, row), width: 30 }),
      actionColumnProps({ id: 'action_delete', Cell: TableCells.DeleteCell, width: 30 }),
    ];

    if (account.role === roles.ADMIN) {
      result.unshift({
        Header: t('Автор'),
        id: 'email',
        accessor: ({ author: { email } }) => email,
        Filter: TableFilters.TextFilter(),
      });
    }
    return result;
  }, [t, account.role, onCancelHandler]);

  return (
    <Page title={t('Отложенные задачи')}>
      <Divider />
      <Panel>
        <Table
          {...tableProps}
          paginationType="offsetBased"
          pageSizeOptions={PAGE_SIZE_OPTIONS}
          columns={columns}
          onDelete={onDeleteHandler}
          hideDeleteButton
          hideCheckbox
          onActions={{
            onDeleteRow: { handler: onDeleteHandler, can: ({ author }) => account?.id === author?.id },
          }}
        />
      </Panel>
    </Page>
  );
}

TasksPage.propTypes = {
  account: PropTypes.shape({
    role: PropTypes.number,
    id: PropTypes.string,
  }).isRequired,
};

export default connect((state) => ({
  account: selectAccountModel(state),
}))(TasksPage);
