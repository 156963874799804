import React, { useEffect, useState } from 'react';

import { Page } from '@vlabs/uikit';
import { formatISO } from 'date-fns';
import PropTypes from 'prop-types';
import qs from 'qs';
import { useTranslation } from 'react-i18next';
import { connect } from 'react-redux';
import { useHistory } from 'react-router-dom';
import { toast } from 'react-toastify';

import { selectAccountRole } from 'features/accounts/selectors';
import { selectCamOptions } from 'features/cams/selectors';
import { selectExtendedEventsFilters } from 'features/extended-events/selectors';
import { selectListOptions } from 'features/lists/selectors';
import { selectScenarioOptions } from 'features/scenarios/selectors';
import { selectSettings } from 'store/app-settings/selectors';
import { selectVehicleAttributes } from 'store/vehicle/selectors';

import { lunaCarsClient } from 'api';
import { EventsFilterForm } from 'features/events/forms/EventsFilterForm';
import { updateExtendedParams } from 'features/extended-events/extendedEventsSlice';
import { flattenFilters } from 'utils/utils';

import { ExtendedEventsTable } from './ExtendedEventsTable';

const ExtendedEventsPage = ({
  vehicleTypeOptions = [],
  vehicleBrandOptions = [],
  vehicleModelOptions = [],
  vehicleColorOptions = [],
  vehicleEmergencyTypeOptions = [],
  publicTransportTypeOptions = [],
  specialTransportTypeOptions = [],
  licensePlateFeatureOptions = [],
  scenarioIndicatorTypeOptions = [],
  scenarioOptions = [],
  countryOptions = [],
  camOptions = [],
  listOptions = [],
  filters,
  setFilters,
  settings,
  role,
}) => {
  const { t } = useTranslation();
  const history = useHistory();
  const [isFiltersVisible, setIsFiltersVisible] = useState(true);

  useEffect(() => {
    if (window.location.search) {
      setFilters(
        [qs.parse(window.location.search, { ignoreQueryPrefix: true })],
      );
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [window.location.search]);

  const getMappedFilters = (fields) => {
    const filterredFields = Object.entries(fields)
      .filter((field) => field[0] !== 'imageId' && field[0] !== 'imageAccuracyGte' && field[0] !== 'imageAccuracyLte');

    const mappedFilters = filterredFields.map(([id, field]) => {
      let value = field;

      if (Array.isArray(field)) {
        value = field.map((fieldValue) => {
          // Check if fieldValue is value of MultiDropdown
          if (typeof fieldValue === 'object' && fieldValue !== null && 'value' in fieldValue) {
            return fieldValue.value;
          }
          return fieldValue;
        });
      }

      if (!Array.isArray(field) && (id === 'recordId' || id === 'recordIdExclude')) {
        return { id, value: value ? value.split(',') : value };
      }

      if (['registeredAfter', 'registeredBefore'].includes(id) && value) {
        return { id, value: formatISO(value?.[0]) };
      }

      return { id, value };
    });
    return mappedFilters;
  };

  const onFilter = (fields) => {
    setFilters(getMappedFilters(fields));
  };

  const onCreateTask = async ({ imageInfo, ...fields }) => {
    const filtersOnExport = flattenFilters(getMappedFilters(fields));
    await lunaCarsClient.extendedEvents.showAll({ ...filtersOnExport, ...imageInfo })
      .then(({ status }) => {
        if (status === 201) {
          toast.info(t('Задача  была создана, для просмотра перейдите в "Задачи"'));
        }
      });
  };

  const onExport = async (fields) => {
    const { imageId, imageAccuracyLte, imageAccuracyGte } = fields;
    const filtersOnExport = flattenFilters(getMappedFilters(fields));
    const resp = await lunaCarsClient.exportResource.export({
      ...filtersOnExport, imageId, imageAccuracyLte, imageAccuracyGte, exportFormat: 'xlsx',
    });
    if (resp.status === 201) {
      toast.info(t('Задача  была создана, для просмотра перейдите в "Задачи"'));
    }
  };

  const onReset = () => {
    window.history.pushState({}, document.title, '/extended-events');
    setFilters([]);
  };

  const onShowScenario = (scenarioId) => {
    history.push(`/scenarios/${scenarioId}`);
  };

  const inputValues = {};

  const filterOptionsMap = {
    listId: listOptions,

    vehicleTypeId: vehicleTypeOptions,
    vehicleTypeIdExclude: vehicleTypeOptions,

    vehicleBrandId: vehicleBrandOptions,
    vehicleBrandIdExclude: vehicleBrandOptions,

    vehicleModelId: vehicleModelOptions,
    vehicleModelIdExclude: vehicleModelOptions,

    vehicleColorId: vehicleColorOptions,
    vehicleColorIdExclude: vehicleColorOptions,

    vehicleEmergencyTypeId: vehicleEmergencyTypeOptions,
    vehicleEmergencyTypeIdExclude: vehicleEmergencyTypeOptions,

    publicTransportTypeId: publicTransportTypeOptions,
    publicTransportTypeIdExclude: publicTransportTypeOptions,

    specialTransportTypeId: specialTransportTypeOptions,
    specialTransportTypeIdExclude: specialTransportTypeOptions,

    indicatorTypeId: scenarioIndicatorTypeOptions,
    indicatorTypeIdExclude: scenarioIndicatorTypeOptions,

    scenarioId: scenarioOptions,
    scenarioIdExclude: scenarioOptions,

    countryId: countryOptions,
    countryIdExclude: countryOptions,

    camId: camOptions,
    camIdExclude: camOptions,
  };

  const prepareFilters = (filter, options) => {
    inputValues[filter.id] = options.filter(
      (option) => filter.value.includes(option.value),
    );
  };

  filters.forEach((filter) => {
    const filterOptions = filterOptionsMap[filter.id];

    if (filterOptions) {
      prepareFilters(filter, filterOptions);
    } else {
      inputValues[filter.id] = filter.value;
    }
  });

  return (
    <Page breadcrumbs={[{ caption: t('События') }]}>
      <ExtendedEventsTable
        onShowScenario={onShowScenario}
        isFiltersVisible={isFiltersVisible}
      />

      <EventsFilterForm
        vehicleTypeOptions={vehicleTypeOptions}
        vehicleBrandOptions={vehicleBrandOptions}
        vehicleModelOptions={vehicleModelOptions}
        vehicleColorOptions={vehicleColorOptions}
        vehicleEmergencyTypeOptions={vehicleEmergencyTypeOptions}
        publicTransportTypeOptions={publicTransportTypeOptions}
        specialTransportTypeOptions={specialTransportTypeOptions}
        licensePlateFeatureOptions={licensePlateFeatureOptions}
        scenarioIndicatorTypeOptions={scenarioIndicatorTypeOptions.filter(({ type }) => type === 'event')}
        scenarioOptions={scenarioOptions.filter(({ scenarioType }) => scenarioType === 'event')}
        countryOptions={countryOptions}
        camOptions={camOptions}
        listOptions={listOptions}
        onReset={onReset}
        onFilter={onFilter}
        onCreateTask={onCreateTask}
        onExport={onExport}
        isVisible={isFiltersVisible}
        setIsVisible={setIsFiltersVisible}
        setFilters={setFilters}
        settings={settings}
        role={role}
        {...inputValues}
      />

    </Page>
  );
};

const dropdownOptionPropTypes = PropTypes.shape({
  id: PropTypes.oneOfType([PropTypes.string, PropTypes.number]),
  value: PropTypes.oneOfType([PropTypes.string, PropTypes.number]),
});

ExtendedEventsPage.propTypes = {
  vehicleTypeOptions: PropTypes.arrayOf(dropdownOptionPropTypes),
  vehicleBrandOptions: PropTypes.arrayOf(dropdownOptionPropTypes),
  vehicleModelOptions: PropTypes.arrayOf(dropdownOptionPropTypes),
  vehicleColorOptions: PropTypes.arrayOf(dropdownOptionPropTypes),
  vehicleEmergencyTypeOptions: PropTypes.arrayOf(dropdownOptionPropTypes),
  publicTransportTypeOptions: PropTypes.arrayOf(dropdownOptionPropTypes),
  specialTransportTypeOptions: PropTypes.arrayOf(dropdownOptionPropTypes),
  licensePlateFeatureOptions: PropTypes.arrayOf(dropdownOptionPropTypes),
  scenarioIndicatorTypeOptions: PropTypes.arrayOf(dropdownOptionPropTypes),
  scenarioOptions: PropTypes.arrayOf(dropdownOptionPropTypes),
  countryOptions: PropTypes.arrayOf(dropdownOptionPropTypes),
  camOptions: PropTypes.arrayOf(dropdownOptionPropTypes),
  listOptions: PropTypes.arrayOf(dropdownOptionPropTypes),
  filters: PropTypes.arrayOf(PropTypes.shape(PropTypes.any)),
  setFilters: PropTypes.func,
  settings: PropTypes.object,
  role: PropTypes.number,
};
ExtendedEventsPage.defaultProps = {
  vehicleTypeOptions: [],
  vehicleBrandOptions: [],
  vehicleModelOptions: [],
  vehicleColorOptions: [],
  vehicleEmergencyTypeOptions: [],
  publicTransportTypeOptions: [],
  specialTransportTypeOptions: [],
  licensePlateFeatureOptions: [],
  scenarioIndicatorTypeOptions: [],
  scenarioOptions: [],
  countryOptions: [],
  camOptions: [],
  listOptions: [],
  filters: [],
  setFilters: undefined,
  settings: {},
  role: undefined,
};

const $ExtendedEventsPage = connect((state) => ({
  ...selectVehicleAttributes(state),
  filters: selectExtendedEventsFilters(state),
  listOptions: selectListOptions(state).listOptions,
  camOptions: selectCamOptions(state),
  scenarioOptions: selectScenarioOptions(state),
  settings: selectSettings(state),
  role: selectAccountRole(state),
}), (dispatch) => ({
  setFilters: (filters) => dispatch(updateExtendedParams({ filters })),
}))(ExtendedEventsPage);

export {
  $ExtendedEventsPage as ExtendedEventsPage,
};
