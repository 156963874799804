import { createSlice } from '@reduxjs/toolkit';

import { lunaCarsClient } from 'api';

const expandOptions = (data) => data.map(({ id, name, ...rest }) => ({
  value: id,
  label: name,
  ...rest,
}));

const listsSlice = createSlice({
  name: 'listItems',
  initialState: {
    listOptions: [],
  },
  reducers: {
    setLists(state, { payload }) {
      const { attribute, data } = payload;
      state[attribute] = expandOptions(data);
    },
  },
});

export default listsSlice.reducer;

export const {
  setLists,
} = listsSlice.actions;

const setListAttribute = (attribute, dispatch) => ({ data: { data } }) => dispatch(
  setLists({ attribute, data }),
);

export const fetchListOptions = async (dispatch) => {
  lunaCarsClient.lists.showAll({ pageSize: 100 }).then(setListAttribute('listOptions', dispatch));
};
