import axios from 'axios';

import { LunaError } from 'api-bindings/luna-client/LunaError';

import { showAPIErrorToast } from './APIErrorToast';
import { showErrorToast } from './ErrorToast';
import { showLunaErrorToast } from './LunaErrorToast';

export function handleError(error) {
  console.error(error);
  if (error instanceof LunaError) {
    showLunaErrorToast(error);
  } else if (axios.isAxiosError(error)) {
    showAPIErrorToast(error);
  } else {
    showErrorToast(error);
  }
}
