import React from 'react';

import { CameraOffIcon } from '@vlabs/icons';
import { useTranslation } from 'react-i18next';

import './Cam.sass';

const PreviewCell = (
  previewUri,
) => {
  const { t } = useTranslation();
  return (previewUri !== null
    ? (
      <img src={previewUri} alt={previewUri} className="Cam__Preview" />
    )
    : (
      <div className="Cam__Preview Cam__Preview_noPhoto">
        <CameraOffIcon className="Cam__Preview_offIcon" />
        {t('Изображение отсутствует')}
      </div>
    ));
};

export default PreviewCell;
