import React, { useState, useEffect } from 'react';

import { Page, Control, Divider } from '@vlabs/uikit';
import PropTypes from 'prop-types';
import { useTranslation } from 'react-i18next';
import { connect, useDispatch } from 'react-redux';
import { useParams } from 'react-router-dom';

import { CAMERA_PROTOCOL_OPTIONS, CAMERA_TYPE_OPTIONS, ROTATION_OPTIONS, EVENT_TYPES } from 'api-bindings/luna-cars/constants';

import * as camApi from '../camsSlice';
import EventsTable from './EventsTable';
import IncidentsTable from './IncidentsTable';
import st from './StreamPage.module.sass';

function StreamPage({
  fetchCamEvents,
}) {
  const dispatch = useDispatch();
  const { t } = useTranslation();

  const { camId } = useParams();
  const [cam, setCam] = useState();
  const [eventType, setEventType] = useState(EVENT_TYPES.events);
  const fetchCam = async () => {
    const $cam = await camApi.fetchCam(camId);
    const $$cam = {
      ...$cam,
      previewImg: undefined,
      inputProtocol: CAMERA_PROTOCOL_OPTIONS[$cam.inputProtocol],
      inputType: CAMERA_TYPE_OPTIONS[$cam.inputType],
      rotation: ROTATION_OPTIONS[$cam.rotation],
    };

    setCam($$cam);
  };

  useEffect(() => {
    fetchCam();
    fetchCamEvents(camId);
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [camId, dispatch]);

  return (
    <Page
      breadcrumbs={[
        { caption: t('Камеры'), link: '/cams' },
        { caption: cam?.name || camId },
      ]}
    >
      <div className={st.Container}>
        <div className={st.CameraContainer}>
          <img
            className={st.Camera}
            alt={t('Камера')}
            src={cam?.watchUri}
          />
        </div>

        <div className={st.TableContainer}>
          <Control.Select
            options={EVENT_TYPES.raw}
            onChange={setEventType}
            value={eventType}
          />
          <Divider small />
          {eventType.value === 'events' && <EventsTable />}
          {eventType.value === 'incidents' && <IncidentsTable />}
        </div>
      </div>
    </Page>
  );
}

StreamPage.propTypes = {
  fetchCamEvents: PropTypes.func.isRequired,
};

export default connect(() => ({}),
  (dispatch) => ({
    fetchCamEvents: (camId) => dispatch(camApi.fetchCamEvents(camId)),
  }))(StreamPage);
