/* eslint-disable no-restricted-globals */
import i18next from 'i18next';

export const actionColumnProps = (props) => ({
  width: 20,
  minWidth: 0,
  type: 'action',
  align: 'center',
  disableFilters: true,
  ...props,
});

export const UUID_PATTERN = /^(?:\s*[0-9a-f]{8}-[0-9a-f]{4}-[0-9a-f]{4}-[0-9a-f]{4}-[0-9a-f]{12})(?:,\s*[0-9a-f]{8}-[0-9a-f]{4}-[0-9a-f]{4}-[0-9a-f]{4}-[0-9a-f]{12})*\s*$/mg;
export const EMAIL_PATTERN = /(^[-!#$%&'*+/=?^`{}|~\w]+(\.[-!#$%&'*+/=?^`{}|~\w]+)*|^"([!#-[\]-\u007f]|\\)*")@(?:[A-Z0-9](?:[A-Z0-9-]{0,61}[A-Z0-9])?\.)+(?:[A-Z]{2,6}|[A-Z0-9-]{2,})$|^\[(25[0-5]|2[0-4]\d|[0-1]?\d?\d)(\.(25[0-5]|2[0-4]\d|[0-1]?\d?\d)){3}\]$/iu;
export const LP_PATTERN = /^[A-Z0-9.,/#!$%^&*;:{}[\]()=+'"|?\-_`~()\\]*$/;

export const numberValidatorHookForm = (value) => value === '' || value === undefined || !isNaN(value) || i18next.t('Допускается ввод только целых и дробных чисел, разделенных знаком "." ');

export const integerValidatorHookForm = (value) => value === '' || value === undefined || Number.isInteger(Number(value)) || i18next.t('Допустимы только целые числа');
