import React, { useEffect, useState } from 'react';

import {
  Page, Control, Margin,
  Divider, openConfirmPopup,
  GridRow, GridCol, Grid, Panel,
} from '@vlabs/uikit';
import PropTypes from 'prop-types';
import { FormProvider, useForm, useWatch } from 'react-hook-form';
import { useTranslation } from 'react-i18next';
import { connect } from 'react-redux';
import { useHistory, useParams } from 'react-router-dom';
import { toast } from 'react-toastify';

import { lunaCarsClient } from 'api';
import { CAMERA_PROTOCOL_OPTIONS, CAMERA_TYPE_OPTIONS, ROTATION_OPTIONS } from 'api-bindings/luna-cars/constants';
import FileInputButton from 'components/file-input-button/FileInputButton';
import { ROIContainer } from 'components/roi/ROIContainer';

import { updateRemoteValues } from './camFromApi';
import st from './CamPage.module.sass';
import * as camApi from './camsSlice';
import { formatParametersToApi } from './camToApi';
import ParameterSubForm from './ParameterSubForm';
import { selectCamInstances } from './selectors';

const CamPage = ({
  camInstances,
  createCam,
  updateCam,
  deleteCam,
  createCamRegion,
  updateCamRegion,
  deleteCamRegion,
  fetchCamInstances,
}) => {
  const { t } = useTranslation();

  const { camId } = useParams();
  const [cam, setCam] = useState();
  const [previewImage, setPreviewImage] = useState(undefined);
  const history = useHistory();

  const isCreating = camId === 'create';

  const formMethods = useForm({
    defaultValues: cam
      ? { ...cam }
      : { name: '', isEnabled: false },
  });

  const onFetchCameraPreview = async () => {
    try {
      const { status } = await lunaCarsClient.cams.fetchPreview(cam?.id);
      if (status === 201) {
        history.replace('/cams');
        toast.info(t('Задача на получение превью камеры {{name}} была создана, ожидайте', { name: cam?.name }));
      }
    } catch ({ response }) {
      toast.error(`${t('Серверная ошибка')}: ${response?.data}`);
    }
  };

  const {
    handleSubmit,
    register,
    control,
    formState: { errors },
    setValue,
    watch,
    reset: resetForm,
    getValues,
  } = formMethods;
  const inputType = useWatch({ control, name: 'inputType' });
  const isAnprSource = inputType?.value === 'anpr-sources';

  const fetchCam = async () => {
    const $cam = await camApi.fetchCam(camId);
    const $$cam = {
      ...$cam,
      previewImg: undefined,
      inputProtocol: CAMERA_PROTOCOL_OPTIONS[$cam.inputProtocol],
      inputType: CAMERA_TYPE_OPTIONS[$cam.inputType],
      rotation: ROTATION_OPTIONS[$cam.rotation],
    };

    setCam($$cam);
    resetForm($$cam);
  };

  useEffect(() => {
    fetchCamInstances(inputType?.value);

    if (!camId || cam || isCreating) return;
    fetchCam();
    // eslint-disable-next-line
  }, [camId, cam, inputType, isCreating]);

  useEffect(() => {
    const sub = watch((data, { name }) => {
      async function getParams() {
        if (data?.inputType?.value) {
          const {
            data: {
              data: handlerTemplate,
            },
          } = await lunaCarsClient.cams.showParamTypesByType(data.inputType.value);
          resetForm({ ...data,
            userName: undefined,
            password: undefined,
            carStreamUrlDropdown: '',
            carStreamUrl: '',
            parameters: updateRemoteValues({}, handlerTemplate).parameters,
            handlerTemplate,
          });
        } else {
          resetForm({ ...data,
            userName: undefined,
            password: undefined,
            carStreamUrlDropdown: '',
            carStreamUrl: '',
          });
        }
      }
      if (name === 'inputType') {
        getParams();
      }
    });

    return () => sub.unsubscribe();
    // eslint-disable-next-line
  }, [inputType]);

  const goToCamsPage = () => {
    history.push('/cams');
  };

  const onDelete = () => {
    const deletionMessage = (
      <span>
        {`${t('Вы уверены, что хотите удалить камеру')}`}
        &nbsp;
        <strong>{cam.name}</strong>
        ?
      </span>
    );

    openConfirmPopup({
      title: t('Удаление камеры'),
      message: deletionMessage,
      type: 'delete',
      onConfirm: async () => {
        try {
          await deleteCam(camId);
          goToCamsPage();
          return undefined;
        } catch (e) {
          return undefined;
        }
      },
    });
  };

  const onSubmit = async (
    {
      inputProtocol,
      inputType,
      rotation,
      carStreamUrl: carStreamUrlManual,
      carStreamUrlDropdown,
      regions,
      handlerTemplate,
      parameters,
      ...values
    },
  ) => {
    const formattedParameters = formatParametersToApi(parameters);
    const data = {
      inputProtocol: inputProtocol?.value,
      inputType: inputType?.value,
      rotation: rotation?.value,
      carStreamUrl: carStreamUrlDropdown?.value || carStreamUrlManual,
      previewImage: previewImage?.raw,
      parameters: formattedParameters,
      ...values,
    };
    try {
      if (isCreating) {
        await createCam(data);
      } else {
        await updateCam(cam.id, data);
      }
      goToCamsPage();
    } catch (e) {
      return undefined;
    }
  };

  const requiredMessage = t('Поле обязательно для заполнения');

  const {
    ...streemUrlProps
  } = register('carStreamUrl');

  const pageActions = (
    <div className={st.Button_top}>
      {camId && (
        <>
          <Control.Button
            onClick={onDelete}
            kind="alert"
          >
            {t('Удалить')}
          </Control.Button>
          <Divider small />
        </>
      )}
      <Control.Button
        type="submit"
        form="cam-form"
      >
        {t('Сохранить')}
      </Control.Button>
    </div>
  );

  useEffect(() => {
    const sub = watch((data, { name }) => {
      if (name === 'carStreamUrlDropdown') {
        const v = getValues('carStreamUrlDropdown');
        if (!v) return;
        setValue('carStreamUrl', '');
      }
      if (name === 'carStreamUrl') {
        const v = getValues('carStreamUrl');
        if (!v) return;
        setValue('carStreamUrlDropdown', '');
      }
    });

    return () => sub.unsubscribe();
  // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [watch, getValues]);

  const fields = {
    userName: (
      <Margin display="block">
        <Control.Input
          label={t('Пользователь')}
          errors={errors}
          {...register('userName', {
            required: isAnprSource ? requiredMessage : undefined,
          })}
        />
      </Margin>
    ),
    password: (
      <Margin display="block">
        <Control.Input
          label={t('Пароль')}
          errors={errors}
          {...register('password', {
            required: isAnprSource ? requiredMessage : undefined,
          })}
        />
      </Margin>),
    carStreamUrlDropdown: (
      <Margin display="block">
        <Control.Select
          label={t('Адрес сервера {{stream}}', { stream: isAnprSource ? 'ANPR Stream' : 'Cars Stream' })}
          name="carStreamUrlDropdown"
          options={camInstances}
          control={control}
          errors={errors}
          isClearable
        />
      </Margin>),
    carStreamUrlManual: (
      <Margin display="block">
        <Control.Input
          label={t('Адрес сервера {{stream}} (ручной ввод)', { stream: isAnprSource ? 'ANPR Stream' : 'Cars Stream' })}
          errors={errors}
          placeholder="http://"
          {...streemUrlProps}
        />
      </Margin>
    ),
    inputLocation: (
      <Margin display="block">
        <Control.Input
          label={t('Расположение источника')}
          errors={errors}
          {...register('inputLocation', {
            required: requiredMessage,
          })}
        />
      </Margin>
    ),
    inputProtocol: (
      <Margin display="block">
        <Control.Select
          label={t('Протокол передачи данных')}
          name="inputProtocol"
          control={control}
          options={CAMERA_PROTOCOL_OPTIONS.raw}
          errors={errors}
        />
      </Margin>
    ),
  };

  const fieldsByInputType = () => {
    switch (inputType?.value) {
      case 'anpr-sources': {
        return (
          <>
            {fields.userName}
            {fields.password}
            {fields.carStreamUrlDropdown}
            {fields.carStreamUrlManual}
            {fields.inputLocation}
          </>
        );
      }
      default: {
        return (
          <>
            {fields.carStreamUrlDropdown}
            {fields.carStreamUrlManual}
            {fields.inputLocation}
            {fields.inputProtocol}
          </>
        );
      }
    }
  };

  return (
    <Page
      actions={pageActions}
      breadcrumbs={[
        { caption: t('Камеры'), link: '/cams' },
        { caption: isCreating ? t('Создание камеры') : cam?.name || camId },
      ]}
    >
      <Divider small />

      <FormProvider {...formMethods}>
        <form
          onSubmit={handleSubmit(onSubmit)}
          id="cam-form"
        >
          <Grid>
            <GridRow>
              <GridCol cols={6}>
                <Panel>
                  <Margin display="block" top left>
                    <h5>{t('Подключение')}</h5>
                  </Margin>
                  <Divider small />
                  <Margin display="block">
                    <Control.Input
                      label={t('Название')}
                      errors={errors}
                      {...register('name', {
                        required: requiredMessage,
                      })}
                    />
                  </Margin>

                  <Margin display="block">
                    <Control.Select
                      label={t('Тип источника')}
                      name="inputType"
                      control={control}
                      options={CAMERA_TYPE_OPTIONS.raw}
                      rules={{ required: requiredMessage }}
                      errors={errors}
                    />
                  </Margin>

                  {fieldsByInputType()}

                  <Margin display="block">
                    <Control.Switch
                      label={t('Камера включена')}
                      name="isEnabled"
                      control={control}
                      errors={errors}
                    />
                  </Margin>
                </Panel>

                <Divider />

                <Panel>
                  <Margin display="block" top left>
                    <h5>{t('Геопозиция')}</h5>
                  </Margin>
                  <Divider small />
                  <Margin display="block">
                    <Control.Input
                      label={t('Адрес')}
                      errors={errors}
                      {...register('geolocation.address')}
                    />
                  </Margin>

                  <div className={st.Block}>
                    <div className={st.Block_half}>
                      <Control.Input
                        {...register('geolocation.lon', {
                          // min: { value: -180, message: t('Значение должно быть больше или равно {{min}}', { min: -180 }) },
                          // max: { value: 180, message: t('Значение должно быть меньше или равно {{max}}', { max: 180 }) },
                        })}
                        label={t('Долгота')}
                        errors={errors}
                        type="number"
                        step="0.000001"
                        min={-180}
                        max={180}
                      />
                    </div>
                    <div className={st.Block_half}>
                      <Control.Input
                        {...register('geolocation.lat', {
                          // min: { value: -90, message: t('Значение должно быть больше или равно {{min}}', { min: -90 }) },
                          // max: { value: 90, message: t('Значение должно быть меньше или равно {{max}}', { max: 90 }) },
                        })}
                        label={t('Широта')}
                        errors={errors}
                        type="number"
                        step="0.000001"
                        min={-90}
                        max={90}
                      />
                    </div>
                  </div>
                </Panel>

                <Divider />

                <ParameterSubForm
                  fieldName="parameters"
                  regionOptions={cam?.regions?.map(({ id, tagName, displayColor }) => ({
                    value: id,
                    label: tagName,
                    displayColor,
                  }))}
                />
              </GridCol>

              <GridCol cols={6}>
                <Panel>
                  <Margin display="block" top left>
                    <h5>{t('Настройки')}</h5>
                  </Margin>
                  <Divider small />
                  <Margin display="block">
                    <Control.Select
                      label={t('Угол поворота')}
                      name="rotation"
                      errors={errors}
                      control={control}
                      options={ROTATION_OPTIONS.raw}
                    />
                  </Margin>
                  <Margin display="block">
                    <Control.Switch
                      errors={errors}
                      label={t('Автоматический перезапуск')}
                      name="isRestartAuto"
                      control={control}
                    />
                  </Margin>

                  <div className={st.Block}>
                    <div className={st.Block_half}>
                      <FileInputButton onSelect={setPreviewImage} />
                    </div>
                    <div className={st.Block_half}>
                      <Control.Button disabled={!cam?.id} onClick={onFetchCameraPreview} fullWidth>
                        {t('Подгрузить превью из {{stream}}', { stream: isAnprSource ? 'ANPR Stream' : 'Cars Stream' })}
                      </Control.Button>
                    </div>
                  </div>
                  {previewImage && (
                  <Margin display="block">
                    <img className={st.Image} src={previewImage.binary} alt="PreviewImage" />
                  </Margin>
                  )}
                  {!previewImage && cam?.previewUri && (
                    <ROIContainer
                      camId={camId}
                      cam={cam}
                      roiRectangle={watch('roiRectangle')}
                      createCamRegion={createCamRegion}
                      updateCamRegion={updateCamRegion}
                      deleteCamRegion={deleteCamRegion}
                      previewImage={previewImage}
                    />
                  )}
                  <Divider small />
                </Panel>
              </GridCol>
            </GridRow>
          </Grid>
        </form>
      </FormProvider>
    </Page>
  );
};

CamPage.propTypes = {
  onDelete: PropTypes.func,
  camInstances: PropTypes.arrayOf(PropTypes.any),
  createCam: PropTypes.func.isRequired,
  updateCam: PropTypes.func.isRequired,
  deleteCam: PropTypes.func.isRequired,
  fetchCamInstances: PropTypes.func.isRequired,
  createCamRegion: PropTypes.func.isRequired,
  updateCamRegion: PropTypes.func.isRequired,
  deleteCamRegion: PropTypes.func.isRequired,
};

CamPage.defaultProps = {
  onDelete: undefined,
  camInstances: [],
};

const $CamPage = connect(
  (state) => ({
    camInstances: selectCamInstances(state),
  }),
  (dispatch) => ({
    createCam: (params) => dispatch(camApi.createCam(params)),
    updateCam: (id, params) => dispatch(camApi.updateCam(id, params)),
    deleteCam: (id) => dispatch(camApi.deleteCam(id)),
    fetchCamInstances: (inputType) => dispatch(camApi.fetchCamInstances(inputType)),
    createCamRegion: (camId, params) => dispatch(camApi.createRegion(camId, params)),
    updateCamRegion: (regionId, camId, params) => dispatch(camApi.updateRegion(regionId, camId, params)),
    deleteCamRegion: (regionId, camId) => dispatch(camApi.deleteRegion(regionId, camId)),
  }),
)(CamPage);

export { $CamPage as CamPage };
