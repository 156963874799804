import React from 'react';

import { SettingsIcon, CrossIcon, CirclePlusIcon, CircleMinusIcon } from '@vlabs/icons';
import cn from 'classnames';
import PropTypes from 'prop-types';
import { useTranslation } from 'react-i18next';

import st from './SettingFilter.module.sass';

function SettingFilter({
  label,
  children,
  accuracyName,
  onClick,
  accuracies,
  isExclude,
  onDelete,
  onExclude,
}) {
  const { t } = useTranslation();

  const $onClick = () => {
    if (onClick) onClick(accuracyName, label);
  };

  const $onDelete = () => {
    if (onDelete) onDelete(accuracyName);
  };

  const $onExclude = (value) => {
    if (onExclude) onExclude(value);
  };

  return (
    <div className={st.Wrapper}>
      <div className={st.Flex}>
        <div className={st.Label}>
          {label}
        </div>
        {onExclude && !isExclude && (
        <CirclePlusIcon
          className={cn(st.Icon, st.Icon_right)}
          onClick={() => $onExclude(true)}
        />
        )}
        {onExclude && isExclude && (
        <CircleMinusIcon
          className={cn(st.Icon, st.Icon_right)}
          onClick={() => $onExclude(false)}
        />
        )}
        {!onExclude && (<div className={cn(st.Icon, st.Icon_right, st.Icon_empty)} />)}
        <div className={st.Value}>
          {children}
        </div>
        {accuracyName && (
          <SettingsIcon
            className={cn({
              [st.Icon]: true,
              [st.Icon_left]: true,
              [st.Icon_active]: accuracies?.[`${accuracyName}Gte`] || accuracies?.[`${accuracyName}Lte`],
            })}
            onClick={$onClick}
          />
        )}
      </div>

      {((accuracies?.[`${accuracyName}Gte`] !== undefined && accuracies?.[`${accuracyName}Gte`] !== '')
      || (accuracies?.[`${accuracyName}Lte`] !== undefined && accuracies?.[`${accuracyName}Lte`] !== '')) && (
        <div className={st.AccuracyContainer}>
          <div className={st.Label}>
            {t('Точность')}
          </div>
          <div className={st.AccuracyValue}>
            {`${t('от')} ${accuracies[`${accuracyName}Gte`] || 0} ${t('до')} ${accuracies[`${accuracyName}Lte`] || 1}`}
          </div>
          <CrossIcon
            className={cn(st.Icon, st.Icon_left)}
            onClick={$onDelete}
          />
        </div>
      )}
    </div>
  );
}

SettingFilter.propTypes = {
  label: PropTypes.string.isRequired,
  children: PropTypes.node.isRequired,
  accuracyName: PropTypes.string,
  accuracies: PropTypes.objectOf(PropTypes.any),
  onClick: PropTypes.func,
  isExclude: PropTypes.bool,
  onDelete: PropTypes.func,
  onExclude: PropTypes.func,
};

SettingFilter.defaultProps = {
  accuracyName: undefined,
  accuracies: {},
  onClick: undefined,
  isExclude: false,
  onDelete: undefined,
  onExclude: undefined,
};

export default SettingFilter;
