import React from 'react';

import { RotationRightIcon } from '@vlabs/icons';
import { Control } from '@vlabs/uikit';

import { CAMERA_STATUS_OPTIONS } from 'api-bindings/luna-cars/constants';
import { can } from 'utils/can';

import './Cam.sass';

const RestartCell = (
  onRestartHandler,
  row,
  role,
) => {
  const { description: statusLabel } = CAMERA_STATUS_OPTIONS[row.status];
  return (
    <Control.RoundButton
      data-testid="restartCell"
      variant="dimmed"
      disabled={!can(role, 'cams:restart') || statusLabel === 'connecting'}
      onClick={
        statusLabel !== 'connecting'
          ? () => onRestartHandler(row.id)
          : undefined
      }
      icon={<RotationRightIcon />}
      className="Cam__Icon"
    />
  );
};

export default RestartCell;
