/* eslint-disable react/no-array-index-key */
import React from 'react';

import PropTypes from 'prop-types';
import { useFieldArray, useFormContext } from 'react-hook-form';

import Group from 'components/group/Group';
import Parameter from 'components/parameter/Parameter';

export default function ParameterSubForm({
  regionOptions,
  fieldName,
}) {
  const { watch } = useFormContext();
  const { fields } = useFieldArray({ name: fieldName });

  return (fields.map((parameter, index) => {
    if (parameter?.type === 'parameter') {
      return (
        <div key={`${parameter.systemName}_${index}`}>
          <Parameter
            {...parameter}
            index={index}
            name={`parameters.${index}.value`}
            regionOptions={regionOptions}
          />
        </div>
      );
    }

    if (parameter?.type === 'group') {
      return (
        <div key={`${parameter.title}_${index}`}>
          <Group
            {...parameter}
            commonParameters={watch('parameters')}
            handlerTemplate={watch('handlerTemplate')?.parameters?.[index]}
            index={index}
            regionOptions={regionOptions}
            nameForm={`parameters.${index}`}
          />
        </div>
      );
    }

    return null;
  })
  );
}

ParameterSubForm.propTypes = {
  parameters: PropTypes.arrayOf(PropTypes.object),
  regionOptions: PropTypes.arrayOf(PropTypes.object),
};

ParameterSubForm.defaultProps = {
  parameters: [],
  regionOptions: [],
};
