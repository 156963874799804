import React from 'react';

import { ImageIcon, RotationRightIcon } from '@vlabs/icons';
import cn from 'classnames';
import PropTypes from 'prop-types';
import { useTranslation } from 'react-i18next';

import BboxImageSelector from 'components/fragment-form/BboxImageSelector';

import FileSelector from './FileSelector';

import './ImageSelector.sass';

const DefaultCaption = () => {
  const { t } = useTranslation();
  return (
    <>
      <ImageIcon />
      <span className="Subtitle-2">{t('Щёлкните или перетащите файл')}</span>
    </>
  );
};

function ImageSelector({
  onChange,
  caption,
  file: inputFile,
  imageUrl: inputImageUrl,
  className,
  detections,
  ...props
}) {
  const [file, setFile] = React.useState(inputFile);
  const [url, setUrl] = React.useState(inputImageUrl);

  const selectHandler = (value) => {
    setFile(value);
    onChange(value);
  };
  const resetHandler = () => {
    setFile(undefined);
    setUrl(undefined);
    onChange(undefined);
  };
  const image = (file && file.binary) || url;
  const fileName = (file && file.raw.name) || '';

  return (
    <div className={cn('ImageSelector', className)}>
      {!file && !url && (
      <FileSelector
        onSelect={selectHandler}
        file={file}
        caption={caption || <DefaultCaption />}
        supportedArchiveTypes={[]}
        {...props}
      />
      )}
      {image && <RotationRightIcon className="ResetImageButton" onClick={resetHandler} />}
      {image && <BboxImageSelector className="ImageSelectorImage" image={image} alt={fileName} detections={detections} />}
    </div>
  );
}

ImageSelector.propTypes = {
  onChange: PropTypes.func,
  caption: PropTypes.node,
  file: PropTypes.shape({
    raw: PropTypes.instanceOf(File),
    type: PropTypes.string,
  }),
  imageUrl: PropTypes.string,
  className: PropTypes.string,
  detections: PropTypes.arrayOf(PropTypes.shape({
    x: PropTypes.number.isRequired,
    y: PropTypes.number.isRequired,
    width: PropTypes.number.isRequired,
    height: PropTypes.number.isRequired,
  })),
};

ImageSelector.defaultProps = {
  onChange: () => {},
  caption: undefined,
  file: undefined,
  imageUrl: undefined,
  className: undefined,
  detections: undefined,
};

export default ImageSelector;
