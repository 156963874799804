import React from 'react';

import {
  PlayerPauseInCircleIcon, CircleCheckIcon, CircleCrossIcon, CircleMinusIcon,
} from '@vlabs/icons';
import { ProgressBar } from '@vlabs/uikit';
import PropTypes from 'prop-types';

import './ProgressCell.sass';

import { STATUS_OPTIONS } from 'api-bindings/luna-cars/constants';

export default function ProgressCell({
  row,
}) {
  const { original: { status, progress } } = row;
  const { value: statusValue } = STATUS_OPTIONS[status];
  return (
    <div className="ProgressCell">
      {statusValue === 0 && (
        <PlayerPauseInCircleIcon className="ProgressCell__Label_pending" />
      )}
      {statusValue === 1 && (
        <ProgressBar progress={progress} />
      )}
      {statusValue === 2 && (
        <CircleCheckIcon className="ProgressCell__Label_finished" />
      )}
      {statusValue === 3 && (
        <CircleCrossIcon className="ProgressCell__Label_interrupted" />
      )}
      {statusValue === 4 && (
        <CircleMinusIcon className="ProgressCell__Label_canceled" />
      )}
    </div>
  );
}

ProgressCell.propTypes = {
  row: PropTypes.objectOf(PropTypes.any),
};

ProgressCell.defaultProps = {
  row: undefined,
};
