import React from 'react';

import PropTypes from 'prop-types';

import st from './Meta.module.sass';

const MetaValue = ({ value, accuracy }) => {
  return (
    <div className={st.Value}>
      <div>{value}</div>
      <div>{accuracy}</div>
    </div>
  );
};

MetaValue.propTypes = {
  value: PropTypes.node,
  accuracy: PropTypes.node,
};

MetaValue.defaultProps = {
  value: '',
  accuracy: '',
};

export { MetaValue };
