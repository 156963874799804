import React from 'react';

import cn from 'classnames';
import PropTypes from 'prop-types';

import st from './Meta.module.sass';
import { MetaValue } from './MetaValue';

function Meta({
  label,
  value,
  accuracyValue,
  isColumn,
  className,
  pairs,
}) {
  return (
    <div className={cn(
      className,
      {
        [st.Meta]: true,
        [st.Meta_column]: isColumn,
      },
    )}
    >
      <div className={st.Label}>
        {label}
      </div>

      <div className={st.Space} />

      {!pairs && (
        <MetaValue
          value={value}
          accuracy={accuracyValue}
        />
      )}

      {pairs && (
        pairs.map((pair) => (
          <MetaValue
            key={pair.value}
            value={pair.value}
            accuracy={pair.accuracy}
          />
        ))
      )}
    </div>
  );
}

Meta.propTypes = {
  label: PropTypes.string.isRequired,
  value: PropTypes.node,
  accuracyValue: PropTypes.node,
  pairs: PropTypes.arrayOf(PropTypes.shape({
    value: PropTypes.node,
    accuracy: PropTypes.node,
  })),
  isColumn: PropTypes.bool,
  className: PropTypes.string,
};

Meta.defaultProps = {
  value: '',
  accuracyValue: '',
  pairs: undefined,
  isColumn: false,
  className: undefined,
};

export default Meta;
