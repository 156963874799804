import React from 'react';

import {
  Page, Control, Margin, Grid, GridCol, GridRow,
} from '@vlabs/uikit';
import { useForm } from 'react-hook-form';
import { useTranslation } from 'react-i18next';

import { HAS_FLASHING_LIGHTS_OPTIONS } from 'api-bindings/luna-cars/constants';

function ListItemsFilterForm({
  vehicleTypeId = [],
  vehicleTypeOptions = [],
  vehicleBrandId = [],
  vehicleBrandOptions = [],
  vehicleModelId = [],
  vehicleModelOptions = [],
  vehicleColorId = [],
  vehicleColorOptions = [],
  vehicleEmergencyTypeId = [],
  vehicleEmergencyTypeOptions = [],
  vehicleEmergencyHasFlashingLight = [],
  countryId = [],
  countryOptions = [],
  vehicleLicensePlate = '',
  onSubmit = () => {},
  onReset = () => {},
}) {
  const { t } = useTranslation();
  const { handleSubmit, getValues, register, control, formState: { errors } } = useForm({
    defaultValues: {
      vehicleTypeId,
      vehicleBrandId,
      vehicleModelId,
      vehicleColorId,
      vehicleEmergencyTypeId,
      countryId,
      vehicleLicensePlate,
      vehicleEmergencyHasFlashingLight,
    },
  });
  const values = getValues();
  const selectedBrandIds = values.vehicleBrandId.map(({ value }) => value);
  const modelOptions = values.vehicleBrandId.length === 0
    ? vehicleModelOptions
    : vehicleModelOptions.filter(({ vehicleBrandId }) => selectedBrandIds.includes(vehicleBrandId));

  return (
    <form onSubmit={handleSubmit(onSubmit)}>
      <Page>
        <Grid>
          <GridRow>
            <GridCol cols={3}>
              <Control.Input
                label={t('ГРЗ')}
                id="vehicleLicensePlate"
                {...register('vehicleLicensePlate')}
                errors={errors}
              />
            </GridCol>
            <GridCol cols={3}>
              <Control.Select
                isMulti
                label={t('Типы ТС')}
                id="vehicleTypeId"
                name="vehicleTypeId"
                control={control}
                options={vehicleTypeOptions}
                errors={errors}
              />
            </GridCol>
            <GridCol cols={3}>
              <Control.Select
                isMulti
                label={t('Марки ТС')}
                id="vehicleBrandId"
                name="vehicleBrandId"
                control={control}
                options={vehicleBrandOptions}
                errors={errors}
              />
            </GridCol>
            <GridCol cols={3}>
              <Control.Select
                isMulti
                label={t('Модели ТС')}
                id="vehicleModelId"
                name="vehicleModelId"
                control={control}
                options={modelOptions}
                errors={errors}
              />
            </GridCol>
          </GridRow>
          <GridRow>
            <GridCol cols={3}>
              <Control.Select
                isMulti
                label={t('Цвета ТС')}
                id="vehicleColorId"
                name="vehicleColorId"
                control={control}
                options={vehicleColorOptions}
                errors={errors}
              />
            </GridCol>
            <GridCol cols={3}>
              <Control.Select
                isMulti
                label={t('Экстренные службы')}
                id="vehicleEmergencyTypeId"
                name="vehicleEmergencyTypeId"
                control={control}
                options={vehicleEmergencyTypeOptions}
                errors={errors}
              />
            </GridCol>
            <GridCol cols={3}>
              <Control.Select
                label={t('Проблесковый маячок')}
                id="vehicleEmergencyHasFlashingLight"
                name="vehicleEmergencyHasFlashingLight"
                control={control}
                options={HAS_FLASHING_LIGHTS_OPTIONS.raw}
                errors={errors}
                isClearable
              />
            </GridCol>
            <GridCol cols={3}>
              <Control.Select
                isMulti
                label={t('Страна ТС')}
                id="countryId"
                name="countryId"
                control={control}
                options={countryOptions}
                errors={errors}
              />
            </GridCol>
          </GridRow>
          <GridRow>
            <GridCol>
              <div>
                <Control.Button type="submit">
                  {t('Отфильтровать')}
                </Control.Button>
                <Margin left>
                  <Control.Button type="reset" onClick={onReset} kind="warning">
                    {t('Сбросить')}
                  </Control.Button>
                </Margin>
              </div>
            </GridCol>
          </GridRow>
        </Grid>
      </Page>
    </form>
  );
}

export default ListItemsFilterForm;
