/* eslint-disable max-len */
import React from 'react';

import { Control } from '@vlabs/uikit';
import { useFormContext } from 'react-hook-form';
import { useTranslation } from 'react-i18next';

import ParameterWrapper from 'components/parameter-wrapper/ParameterWrapper';

import st from './Parameter.module.sass';

export default function Parameter({
  title,
  validation,
  value,
  helper,
  regionOptions,
  name,
  isEnabled = true,
}) {
  const { t } = useTranslation();
  const { register, control, formState: { errors } } = useFormContext();

  return (
    <>
      <ParameterWrapper
        title={title}
        parameterType={validation?.type}
        color={value?.displayColor || regionOptions?.find(({ value: regionValue }) => regionValue === value?.value)?.displayColor}
        data-tip={helper}
        regionOptions={regionOptions}
      >
        <div style={{ width: '300px' }}>
          {validation?.type === 'float' && (
            <Control.Input
              {...register(name, {
                required: isEnabled && validation?.required && t('Поле обязательно для заполнения'),
                // min: { value: validation?.minValue, message: t('Значение должно быть больше или равно {{min}}', { min: validation?.minValue }) },
                // max: { value: validation?.maxValue, message: t('Значение должно быть меньше или равно {{max}}', { max: validation?.maxValue }) },
              })}
              errors={errors}
              type="number"
              step={validation?.step}
              min={validation?.minValue}
              max={validation?.maxValue}
            />
          )}
          {validation?.type === 'int' && (
            <Control.Input
              {...register(name, {
                required: isEnabled && validation?.required && t('Поле обязательно для заполнения'),
                // min: { value: validation?.minValue, message: t('Значение должно быть больше или равно {{min}}', { min: validation?.minValue }) },
                // max: { value: validation?.maxValue, message: t('Значение должно быть меньше или равно {{max}}', { max: validation?.maxValue }) },
              })}
              errors={errors}
              type="number"
              step={validation?.step}
              min={validation?.minValue}
              max={validation?.maxValue}
            />
          )}
          {validation?.type === 'bool' && (
            <Control.Switch
              name={name}
              control={control}
              errors={errors}
            />
          )}
          {validation?.type === 'string' && !validation?.choices && (
            <Control.Input
              errors={errors}
              {...register(name, {
                required: isEnabled && validation?.required && t('Поле обязательно для заполнения'),
              })}
            />
          )}
          {validation?.type === 'string' && validation?.choices && (
            <Control.Select
              name={name}
              control={control}
              options={validation?.choices}
              errors={errors}
              rules={{ required: isEnabled && validation?.required && t('Поле обязательно для заполнения') }}
              isMulti={validation?.isMulti || false}
              isClearable
            />
          )}
          {validation?.type === 'zoneSelector' && (
            <Control.Select
              name={name}
              control={control}
              options={regionOptions}
              errors={errors}
              rules={{ required: isEnabled && validation?.required && t('Поле обязательно для заполнения') }}
              isClearable
            />
          )}
        </div>
      </ParameterWrapper>
      <div className={st.Border} />
    </>
  );
}
