import React from 'react';

import PropTypes from 'prop-types';

import st from './PanelBlock.module.sass';

export default function PanelBlock({
  children,
  title,
}) {
  return (
    <div className={st.Container}>
      <div className={st.Header}>
        <h5>{title}</h5>
      </div>

      <div>
        {children}
      </div>
    </div>
  );
}

PanelBlock.propTypes = {
  children: PropTypes.node.isRequired,
  title: PropTypes.string.isRequired,
};
