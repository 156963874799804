import React from 'react';

import PropTypes from 'prop-types';

import { lunaCarsClient } from 'api';
import { DownloadCell } from 'components/download-cell/DownloadCell';
import { GoTo } from 'components/goto/GoTo';

import st from './CustomActionCell.module.sass';

function CustomActionCell({
  row: { original: row },
}) {
  if (!row) return null;

  // Задача ещё не завершена
  if (row.status !== 2) return null;

  switch (row.type) {
    case 'image_search': return (
      <GoTo
        to={`tasks/${row.id}`}
        className={st.SimpleGoTo}
      />
    );
    case 'file_export': return (
      <DownloadCell
        row={row}
        url={lunaCarsClient.tasks.downloadUrl(row.id, { dataFormat: 'xlsx' })}
      />
    );
    default: return null;
  }
}

CustomActionCell.propTypes = {
  row: PropTypes.shape({
    original: PropTypes.shape({
      id: PropTypes.string,
      status: PropTypes.oneOf([0, 1, 2, 3, 4]),
      type: PropTypes.oneOf(['image_search', 'file_export', 'get_cam_preview', 'unknown']),
    }),
  }).isRequired,
};

export default CustomActionCell;
