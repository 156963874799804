/* eslint-disable react-hooks/exhaustive-deps */
import React, { useState, useEffect } from 'react';

import {
  Control, Grid, GridCol, GridRow,
  Divider, Page, Margin, Panel, Fold, SettingsItemWrapper,
} from '@vlabs/uikit';
import qs from 'qs';
import { FormProvider, useForm } from 'react-hook-form';
import { useTranslation } from 'react-i18next';
import { connect } from 'react-redux';
import { useParams, useHistory } from 'react-router-dom';
import { toast } from 'react-toastify';

import { selectFullCamOptions } from 'features/cams/selectors';
import { selectHandlers } from 'features/scenarios/selectors';
import { selectVehicleAttributes } from 'store/vehicle/selectors';

import { lunaCarsClient } from 'api';
import CamSettingForm from 'features/cams/CamSettingForm';
import { setHandlers } from 'features/scenarios/scenariosSlice';

import { convertSubgroupFromApi, updateRemoteValues, updateTemplate } from './handlerFromApi';
import { formatParametersToApi } from './handlerToApi';

import './HandlerPage.sass';

function HandlerPage({
  handlerTypeOptions,
  fullCamOptions,
  handlers,
  setHandlers,
}) {
  const { t } = useTranslation();
  const history = useHistory();
  const { scenarioId, handlerId } = useParams();
  const [camOptionsByInputType, setCamOptionsByInputType] = useState();
  const [handler, setHandler] = useState({
    id: undefined,
    camera: undefined,
    handlerTemplate: undefined,
    comment: undefined,
    statusIsOn: false,
    parameters: [],
    index: undefined,
  });
  const { index, scenarioTitle } = qs.parse(window.location.search, { ignoreQueryPrefix: true });

  const formMethods = useForm({
    defaultValues: handler,
  });
  const {
    handleSubmit,
    register,
    control,
    formState: { errors },
    watch,
    reset: resetForm,
    getValues,
  } = formMethods;
  useEffect(() => { resetForm(handler); }, [handler, resetForm]);

  // При пустом стейте хэндлеров - наполняем
  useEffect(() => {
    if (!scenarioId || scenarioId === 'create' || handlers?.data?.length) return;
    lunaCarsClient.scenarios.showAllHandlers(scenarioId)
      .then(({ data }) => {
        setHandlers(data);
      });
  }, [scenarioId]);

  const generateAvailableCamList = (handlerValue) => {
    const сamsByInputType = fullCamOptions?.filter(({ inputType }) => handlerValue.inputTypes?.includes(inputType));
    setCamOptionsByInputType(сamsByInputType);
  };

  useEffect(() => {
    const currentHandlerTemplate = handlerTypeOptions.find((i) => i.value === handler.handlerTemplate?.value);
    if (currentHandlerTemplate) generateAvailableCamList(currentHandlerTemplate);
  }, [handler]);

  // Если есть обработчик и сценарий
  useEffect(() => {
    if (!handlerId || handlerId === 'create' || handlerId === 'edit') return;
    lunaCarsClient.scenarios.showHandler(handlerId, scenarioId)
      .then(
        ({ data: { data } }) => {
          setHandler(updateRemoteValues(data, handlerTypeOptions));
        },
      );
  }, [handlerId, handlerTypeOptions]);

  // При создании сценария (когда нет scenarioId)
  useEffect(() => {
    if (handlerId === 'edit' && index >= 0) {
      setHandler(updateRemoteValues(handlers.data[index], handlerTypeOptions, index));
    }
  }, [handlerId, handlerTypeOptions, index]);

  const onAction = (
    <Control.Button
      type="submit"
      form="handler-form"
      hollow
      className="HandlerPage__OnActionBtn"
    >
      {t('Сохранить')}
    </Control.Button>
  );

  const onSubmit = async ({
    handlerTemplate: { value: handlerTemplate },
    parameters,
    camera,
    id,
    index,
    ...values
  }) => {
    const formattedParameters = formatParametersToApi(parameters);
    const formattedHandler = {
      ...values,
      handlerTemplate,
      camId: camera?.value,
      parameters: formattedParameters?.length ? formattedParameters : undefined,
    };

    if (scenarioId && scenarioId !== 'create') {
      if (id) {
        await lunaCarsClient.scenarios.updateHandler(id, scenarioId, formattedHandler)
          .then(() => {
            toast.success(`${t('Обработчик успешно обновлен')}.`);
            history.push(`/scenarios/${scenarioId}`);
          })
          .catch(({ response }) => toast.error(`${t('Серверная ошибка')}: ${response.data}`));
      } else {
        await lunaCarsClient.scenarios.createHandler(scenarioId, formattedHandler)
          .then(() => {
            toast.success(`${t('Обработчик успешно создан')}.`);
            history.push(`/scenarios/${scenarioId}`);
          })
          .catch(({ response }) => toast.error(`${t('Серверная ошибка')}: ${response.data}`));
      }
      return;
    }

    const handlerList = handlers ? [...handlers.data] : [];
    const newHandler = {
      ...values,
      handlerTemplate,
      camera: {
        id: camera?.value,
        name: camera?.label,
        ...camera,
      },
      parameters: formattedParameters?.length ? formattedParameters : undefined,
    };
    if (index >= 0) {
      handlerList[index] = { ...newHandler, index };
    }
    if (!index && index !== 0) {
      handlerList.push({ ...newHandler, index: handlerList.length });
    }

    setHandlers({ data: handlerList });
    history.push(`/scenarios/${scenarioId}`);
  };

  const generateBreadcrumbLabel = () => {
    if (handlerId === 'create') return t('Создание обработчика');
    if (handlerId === 'edit') return t('Редактирование обработчика');
    return handlerId;
  };

  useEffect(() => {
    const sub = watch((data, { name }) => {
      if (name === 'handlerTemplate') {
        const v = getValues('handlerTemplate');
        if (!v) return;

        generateAvailableCamList(v);
        resetForm({
          ...getValues(),
          camera: '',
          handlerTemplate: {
            ...v,
            parameters: updateTemplate(v?.parameters),
          },
          parameters: convertSubgroupFromApi(v?.parameters),
        });
      }
    });

    return () => sub.unsubscribe();
  }, [watch, getValues, fullCamOptions]);

  return (
    <FormProvider {...formMethods}>
      <form
        onSubmit={handleSubmit(onSubmit)}
        id="handler-form"
      >
        <Page
          actions={onAction}
          breadcrumbs={[
            { caption: t('Сценарии'), link: '/scenarios' },
            { caption: scenarioTitle || t('Создание сценария'), link: `/scenarios/${scenarioId}` },
            { caption: generateBreadcrumbLabel() },
          ]}
        >
          <Divider />
          <Grid>
            <GridRow>
              <GridCol cols={6}>
                <Fold title={t('Общие')} isOpen>
                  {handler.id && (
                  <div className="HandlerPage__ContainerId">
                    <div className="HandlerPage__Id">{t('ID')}</div>
                    <div className="HandlerPage__Id">{handler.id}</div>
                  </div>
                  )}
                  <SettingsItemWrapper title={t('Тип')}>
                    <div className="HandlerPage__Dropdown">
                      <Control.Select
                        name="handlerTemplate"
                        control={control}
                        options={handlerTypeOptions}
                        errors={errors}
                        rules={{ required: 'Поле обязательно для заполнения' }}
                      />
                    </div>
                  </SettingsItemWrapper>
                  <SettingsItemWrapper title={t('Комментарий')}>
                    <Control.Input
                      errors={errors}
                      {...register('comment')}
                    />
                  </SettingsItemWrapper>
                  <SettingsItemWrapper title={t('Статус')}>
                    <Control.Switch
                      name="statusIsOn"
                      control={control}
                      errors={errors}
                    />
                  </SettingsItemWrapper>
                  <Margin>
                    <span className="HandlerPage__Comment">
                      {watch('handlerTemplate.info')}
                    </span>
                  </Margin>
                </Fold>
              </GridCol>

              <GridCol cols={6}>
                <Panel>
                  <Margin>
                    <h5>{t('Настройки камеры и зон распознавания')}</h5>
                  </Margin>
                  <CamSettingForm
                    fullCamOptions={camOptionsByInputType}
                  />
                </Panel>
              </GridCol>
            </GridRow>
          </Grid>
        </Page>
      </form>
    </FormProvider>
  );
}
export default connect((state) => ({
  ...selectVehicleAttributes(state),
  fullCamOptions: selectFullCamOptions(state),
  handlers: selectHandlers(state),
}),
(dispatch) => ({
  setHandlers: (handlers) => dispatch(setHandlers(handlers)),
}))(HandlerPage);
