import React from 'react';

import { useTranslation } from 'react-i18next';
import { Link } from 'react-router-dom';

import './ScenarioListsCell.sass';

export default function ScenarioListsCell({
  lists,
}) {
  const { t } = useTranslation();
  return (
    <div>
      {lists?.length > 0 && lists.map(({ listId, listTitle, isExcept }) => (
        <div key={listId}>
          {isExcept && `(${t('Кроме')}) `}
          <Link className="ScenarioListsCell__Item" key={listId} to={`/lists/${listId}`}>
            {listTitle}
          </Link>
        </div>
      ))}
      {!lists?.length && (<>&ndash;</>)}
    </div>
  );
}
