import React from 'react';

import { Divider } from '@vlabs/uikit';
import PropTypes from 'prop-types';
import { useTranslation } from 'react-i18next';
import { toast } from 'react-toastify';

import { APIErrorToast } from './APIErrorToast';
import st from './errors.module.sass';

const LunaErrorToast = ({
  error: {
    error_code,
    desc,
    detail,
    link,
    error,
  },
}) => {
  const { t } = useTranslation();

  return (
    <APIErrorToast error={error} service="LunaAPI">
      <p>
        <strong>{t('Код ошибки:')}</strong>
        {' '}
        {error_code}
      </p>
      <p>
        <strong>{t('Описание ошибки')}</strong>
        <br />
        {desc}
      </p>

      <p>
        <strong>{t('Детали ошибки')}</strong>
        <br />
        {detail}
      </p>

      <Divider small />

      <p>
        <a
          className={st.Link}
          href={link || '/'}
          target="_blank"
          rel="noopener noreferrer"
        >
          {t('Ссылка на документацию')}
        </a>
      </p>
    </APIErrorToast>
  );
};

LunaErrorToast.propTypes = {
  error: PropTypes.shape({
    error_code: PropTypes.number,
    desc: PropTypes.string,
    detail: PropTypes.string,
    link: PropTypes.string,
    error: PropTypes.instanceOf(Error),
  }).isRequired,
};

export { LunaErrorToast };
export const showLunaErrorToast = (error) => {
  toast.error(
    <LunaErrorToast error={error} />, { toastId: `${error.error.config.url}${error.error_code}` },
  );
};
