import React, { useEffect, useState } from 'react';

import { Margin, ROIPreview } from '@vlabs/uikit';
import PropTypes from 'prop-types';

import { lunaCarsClient } from 'api';
import { DroiModalField } from 'components/roi/DroiModalField';
import { RoiModalField } from 'components/roi/RoiModalField';

const ROIContainer = ({
  camId,
  cam,
  roiRectangle,
  ...props
}) => {
  const [regions, setRegions] = useState([]);

  useEffect(() => {
    if (!camId) return;

    const fetchRegions = async () => {
      const response = await lunaCarsClient.cams.showAllRegions(camId);
      setRegions(response.data.data.map((region) => ({
        ...region,
        isHide: false,
      })));
    };

    fetchRegions();
  }, [camId]);

  const visibleRegions = regions
    .filter((region) => !region.isHide)
    .map((region) => ({
      name: region.tagName,
      color: region.displayColor,
      points: region.points,
    }));

  return (
    <>
      <Margin display="block">
        <ROIPreview
          roi={roiRectangle}
          droi={visibleRegions}
          preview={cam?.previewUri}
          isNormalized={false}
        />
      </Margin>

      <Margin display="block">
        <RoiModalField
          name="roiRectangle"
          roi={roiRectangle}
          preview={cam?.previewUri}
        />
      </Margin>

      <Margin display="block">
        <DroiModalField
          camId={camId}
          roi={roiRectangle}
          droi={regions}
          cam={cam}
          setRegions={setRegions}
          {...props}
        />
      </Margin>
    </>
  );
};

ROIContainer.propTypes = {
  camId: PropTypes.string.isRequired,
  cam: PropTypes.object,
  previewUri: PropTypes.string,
  roiRectangle: PropTypes.object,
};

ROIContainer.defaultProps = {
  cam: undefined,
  previewUri: '',
  roiRectangle: {},
};

export { ROIContainer };
