import { SLICE_NAME } from '.';

const { createSelector } = require('@reduxjs/toolkit');

export const selectSlice = (state) => state[SLICE_NAME];
export const selectXLSXExportSettings = createSelector(
  selectSlice,
  ({ xlsx }) => {
    function blah(arr) {
      const defaults = [];
      const keyMap = {};

      function reformat(obj) {
        const result = {
          label: obj.title,
          value: obj.code || `group|${obj.title}`,
        };
        if (obj.code) keyMap[obj.code] = obj.title;

        if (obj.defaults) obj.defaults.forEach((v) => defaults.push(v));
        if (obj.items) {
          result.children = obj.items.map(reformat);
        }
        return result;
      }

      return {
        tree: arr.map(reformat),
        defaults,
        keyMap,
      };
    }
    return {
      columns: blah(xlsx.columns),
      groups: blah(xlsx.groups),
    };
  },
);
