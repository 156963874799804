import { optionsProxy } from '@vlabs/uikit';
import i18n from 'i18next';

export const ROLE_OPTIONS = optionsProxy.create([
  { value: 0, label: i18n.t('constants:пользователь') },
  { value: 1, label: i18n.t('constants:администратор') },
]);

export const TYPE_OPTIONS = optionsProxy.create([
  { value: 'image_search', label: i18n.t('constants:поиск по изображению') },
  { value: 'file_export', label: i18n.t('constants:экспорт в файл') },
  { value: 'get_cam_image', label: i18n.t('constants:получение превью камеры') },
  { value: 'unknown', label: i18n.t('constants:неизвестный') },
]);

export const STATUS_OPTIONS = optionsProxy.create([
  { value: 0, label: i18n.t('constants:в ожидании') },
  { value: 1, label: i18n.t('constants:запущено') },
  { value: 2, label: i18n.t('constants:готово') },
  { value: 3, label: i18n.t('constants:прервано') },
  { value: 4, label: i18n.t('constants:отменено') },
]);

export const CAMERA_TYPE_OPTIONS = optionsProxy.create([
  { value: 'stream-sources', label: i18n.t('constants:rtsp поток') },
  { value: 'video-sources', label: i18n.t('constants:видеофайл') },
  { value: 'anpr-sources', label: i18n.t('constants:anpr камера') },
  { value: 'rtsp-buffer-sources', label: i18n.t('constants:rtsp-buffer-sources') },
  // { value: 'images_sources', label: i18n.t('Папка с изображениями') },
]);

export const CAMERA_PROTOCOL_OPTIONS = optionsProxy.create([
  { value: 'tcp', label: i18n.t('constants:tcp') },
  { value: 'udp', label: i18n.t('constants:udp') },
]);

export const CAMERA_STATUS_OPTIONS = optionsProxy.create([
  { value: 0, label: i18n.t('constants:отключена'), description: 'stopped' },
  { value: 1, label: i18n.t('constants:включена'), description: 'active' },
  { value: 2, label: i18n.t('constants:подключается'), description: 'connecting' },
  { value: 3, label: i18n.t('constants:недоступна'), description: 'failed' },
  { value: 4, label: i18n.t('constants:проблемы'), description: 'problems' },
]);

export const SCENARIO_TYPE_OPTIONS = optionsProxy.create([
  { value: 'event', label: i18n.t('constants:события') },
  { value: 'incident', label: i18n.t('constants:инциденты') },
]);

export const SCENARIO_STATUS_OPTIONS = optionsProxy.create([
  { value: true, label: i18n.t('constants:включен') },
  { value: false, label: i18n.t('constants:отключен') },
]);

export const ROTATION_OPTIONS = optionsProxy.create([
  { value: 0, label: 0 },
  { value: 90, label: 90 },
  { value: 180, label: 180 },
  { value: 270, label: 270 },
]);

export const FRAGMENT_TYPE_OPTIONS = optionsProxy.create([
  { value: 'vehicle_crop', label: i18n.t('constants:кроп авто') },
  { value: 'license_plate_crop', label: i18n.t('constants:кроп номера') },
  { value: 'track_begin_frame', label: i18n.t('constants:фрейм начала трека') },
  { value: 'track_end_frame', label: i18n.t('constants:фрейм окончания трека') },
  { value: 'track_bestshot_frame', label: i18n.t('constants:фрейм с бестшотом') },
  { value: 'other', label: i18n.t('constants:прочее') },
]);

export const EVENT_TYPES = optionsProxy.create([
  { value: 'events', label: i18n.t('constants:события') },
  { value: 'incidents', label: i18n.t('constants:инциденты') },
]);

export const CLASS_TYPE_OPTIONS = optionsProxy.create([
  { value: 0, label: i18n.t('constants:главный') },
  { value: 1, label: i18n.t('constants:второстепенный') },
]);

export const HAS_FLASHING_LIGHTS_OPTIONS = optionsProxy.create([
  { value: 'true', label: i18n.t('constants:с маячками') },
  { value: 'false', label: i18n.t('constants:без маячков') },
]);

export const PAGE_SIZE_OPTIONS = [10, 25, 50];
