import React from 'react';

import { Pages } from '@vlabs/uikit';
import '../translations/i18n';
import {
  Switch,
  Route,
  Redirect,
} from 'react-router-dom';

import AccountsPage from 'features/accounts/AccountsPage';
import ResetPasswordPage from 'features/auth/ResetPasswordPage';
import { CamPage } from 'features/cams/CamPage';
import CamsPage from 'features/cams/CamsPage';
import StreamPage from 'features/cams/streams/StreamPage';
import EventPage from 'features/events/EventPage';
import EventsPage from 'features/events/EventsPage';
import { ExtendedEventPage } from 'features/extended-events/ExtendedEventPage';
import { ExtendedEventsPage } from 'features/extended-events/ExtendedEventsPage';
import IncidentPage from 'features/incidents/IncidentPage';
import IncidentsPage from 'features/incidents/IncidentsPage';
import ListPage from 'features/lists/ListPage';
import ListsPage from 'features/lists/ListsPage';
import HandlerPage from 'features/scenarios/HandlerPage';
import ScenarioListPage from 'features/scenarios/ScenarioListPage';
import ScenarioPage from 'features/scenarios/ScenarioPage';
import TaskPage from 'features/tasks/TaskPage';
import TasksPage from 'features/tasks/TasksPage';
import { can } from 'utils/can';

export default ({ role }) => (
  <Switch>
    <Route
      path="/extended-events/:eventId"
      render={() => (can(role, 'events:read') ? <ExtendedEventPage /> : <Pages.NotFoundPage />)}
    />
    <Route
      path="/events/:eventId"
      render={() => (can(role, 'events:read') ? <EventPage /> : <Pages.NotFoundPage />)}
    />
    <Route
      path="/extended-events"
      render={() => (can(role, 'events:read') ? <ExtendedEventsPage /> : <Pages.NotFoundPage />)}
    />
    <Route
      path="/events"
      render={() => (can(role, 'events:read') ? <EventsPage /> : <Pages.NotFoundPage />)}
    />
    <Route
      path="/lists/:listId"
      render={() => (can(role, 'lists:read') ? <ListPage /> : <Pages.NotFoundPage />)}
    />
    <Route
      path="/lists"
      render={() => (can(role, 'lists:read') ? <ListsPage /> : <Pages.NotFoundPage />)}
    />
    <Route
      path="/incidents/:incidentId"
      render={() => (can(role, 'incidents:read') ? <IncidentPage /> : <Pages.NotFoundPage />)}
    />
    <Route
      path="/incidents"
      render={() => (can(role, 'incidents:read') ? <IncidentsPage /> : <Pages.NotFoundPage />)}
    />
    <Route
      path="/tasks/:taskId"
      render={() => (can(role, 'tasks:read') ? <TaskPage /> : <Pages.NotFoundPage />)}
    />
    <Route
      path="/tasks"
      render={() => (can(role, 'tasks:read') ? <TasksPage /> : <Pages.NotFoundPage />)}
    />
    <Route
      path="/cams/:camId/stream"
      render={() => (can(role, 'cams:read') ? <StreamPage /> : <Pages.NotFoundPage />)}
    />
    <Route
      path="/cams/:camId"
      render={() => (can(role, 'cams:read') ? <CamPage /> : <Pages.NotFoundPage />)}
    />
    <Route
      path="/cams"
      render={() => (can(role, 'cams:read') ? <CamsPage /> : <Pages.NotFoundPage />)}
    />
    <Route path="/scenarios/:scenarioId/handlers/:handlerId" render={() => (can(role, 'scenarios:read') ? <HandlerPage /> : <Pages.NotFoundPage />)} />
    <Route path="/scenarios/:scenarioId" render={() => (can(role, 'scenarios:read') ? <ScenarioPage /> : <Pages.NotFoundPage />)} />
    <Route path="/scenarios/create" render={() => (can(role, 'scenarios:read') ? <ScenarioPage /> : <Pages.NotFoundPage />)} />
    <Route path="/scenarios" render={() => (can(role, 'scenarios:read') ? <ScenarioListPage /> : <Pages.NotFoundPage />)} />
    <Route
      path="/accounts"
      render={() => (can(role, 'accounts:read') ? <AccountsPage /> : <Pages.NotFoundPage />)}
    />
    <Route path="/reset-password/:token" component={ResetPasswordPage} />
    <Route exact path="/" render={() => (can(role, 'events:read') ? <Redirect to="/events" /> : <Pages.NotFoundPage />)} />
    <Route path="*" component={Pages.NotFoundPage} />
  </Switch>
);
