import React, { useEffect } from 'react';

import {
  Page, Panel, Table, TableCells, TableFilters,
} from '@vlabs/uikit';
import PropTypes from 'prop-types';
import qs from 'qs';
import { useTranslation } from 'react-i18next';
import { connect, useDispatch } from 'react-redux';
import { useHistory } from 'react-router-dom';
import { toast } from 'react-toastify';

import { selectAccountRole } from 'features/accounts/selectors';
import { selectCams } from 'features/cams/selectors';

import { lunaCarsClient } from 'api';
import { CAMERA_TYPE_OPTIONS, CAMERA_STATUS_OPTIONS } from 'api-bindings/luna-cars/constants';
import CamStatusCell from 'components/cam/CamStatusCell';
import OpenMapCell from 'components/cam/OpenMapCell';
import PreviewCell from 'components/cam/PreviewCell';
import RestartCell from 'components/cam/RestartCell';
import WatchStreamCell from 'components/cam/WatchStreamCell';
import {
  fetchCams, updatePage, updatePageSize, updateFilters,
} from 'features/cams/camsSlice';
import { can } from 'utils/can';
import { actionColumnProps } from 'utils/helpers';

import './CamsPage.sass';

function CamsPage({
  role,
  camsData,
  setFilters,
  setPageSize,
  setPageIndex,
}) {
  const { t } = useTranslation();
  const dispatch = useDispatch();
  const history = useHistory();

  useEffect(() => {
    dispatch(fetchCams);
  }, [dispatch]);

  const onShowForm = async (cam) => {
    history.push(`/cams/${cam?.id || 'create'}`);
  };

  const onRestart = async (camId) => {
    if (camId) {
      try {
        await lunaCarsClient.cams.restart(camId);
        dispatch(fetchCams);
      } catch ({ response }) {
        toast.error(`${t('Серверная ошибка')}: ${response?.data}`);
      }
    }
  };

  const onOpenMap = (geolocation) => {
    if (!geolocation) return;
    window.open(
      `https://yandex.ru/maps?${qs.stringify(
        { text: [geolocation.lat?.toFixed(6), geolocation.lon?.toFixed(6)] },
        { arrayFormat: 'comma' },
      )}`,
      '_blank',
      `left=${window.screenLeft},top=${window.screenTop},width=${window.innerWidth},height=${window.innerHeight}`,
    );
  };

  const onShowStream = async (cam) => {
    history.push(`/cams/${cam.id}/stream`);
  };

  return (
    <Page
      breadcrumbs={[
        { caption: t('Камеры') },
      ]}
    >
      <Panel>
        <Table
          data={camsData.data}
          filters={camsData.filters}
          pageIndex={camsData.pageIndex}
          pageSize={camsData.pageSize}
          pageSizeOptions={camsData.pageSizeOptions}
          setFilters={setFilters}
          setPageIndex={setPageIndex}
          setPageSize={setPageSize}
          paginationType="offsetBased"
          columns={[
            {
              Header: t('Превью'),
              accessor: 'previewUri',
              Cell: ({ value: previewUri }) => PreviewCell(previewUri),
              width: 175,
            },
            { Header: t('Название'), accessor: 'name', Filter: TableFilters.TextFilter() },
            {
              Header: t('Тип'),
              accessor: 'inputType',
              Cell: ({ value }) => {
                const roleOption = CAMERA_TYPE_OPTIONS[value];
                return roleOption ? roleOption.label : value;
              },
              Filter: TableFilters.OneOfFilter({ options: CAMERA_TYPE_OPTIONS.raw }),
            },
            {
              Header: t('Статус'), accessor: 'status', Cell: ({ value: status }) => CamStatusCell(status), width: 100, Filter: TableFilters.OneOfFilter({ options: CAMERA_STATUS_OPTIONS.raw }),
            },
            {
              Header: t('Дата обновления'), accessor: 'updatedAt', Cell: TableCells.DateCell, Filter: TableFilters.DateFilter(),
            },
            actionColumnProps({
              id: 'restart',
              Cell: ({ row: { original } }) => RestartCell(onRestart, original, role),
            }),
            actionColumnProps({
              id: 'edit',
              Cell: TableCells.EditCell,
            }),
            actionColumnProps({
              id: 'openMap',
              Cell: ({ row: { original } }) => OpenMapCell(onOpenMap, original),
            }),
            actionColumnProps({
              id: 'watchStream',
              Cell: ({ row: { original } }) => WatchStreamCell(onShowStream, original),
            }),
          ]}
          onActions={{
            onEditRow: { handler: onShowForm, can: () => can(role, 'cams:update') },
            onAddRow: { handler: onShowForm, can: () => can(role, 'cams:create') },
          }}
        />
      </Panel>
    </Page>
  );
}

CamsPage.propTypes = {
  role: PropTypes.number.isRequired,
  camsData: PropTypes.oneOfType([PropTypes.object, PropTypes.arrayOf(PropTypes.any)]),
  setFilters: PropTypes.func.isRequired,
  setPageSize: PropTypes.func.isRequired,
  setPageIndex: PropTypes.func.isRequired,
};

CamsPage.defaultProps = {
  camsData: {},
};

export default connect((state) => ({
  role: selectAccountRole(state),
  camsData: selectCams(state),
}),
(dispatch) => ({
  setFilters: (filters) => dispatch(updateFilters({ filters })),
  setPageSize: (pageSize) => dispatch(updatePageSize({ pageSize })),
  setPageIndex: (pageIndex) => dispatch(updatePage({ pageIndex })),
}))(CamsPage);
