import React, { useState } from 'react';

import { DeleteIcon, EditIcon } from '@vlabs/icons';
import { Control, Modal, Divider, openConfirmPopup, Page } from '@vlabs/uikit';
import PropTypes from 'prop-types';
import { useTranslation } from 'react-i18next';

import { lunaCarsClient } from 'api';

import { DroiForm } from './DroiForm';

import './RoiModalField.sass';
import './DroiTable.sass';

const DroiModalField = ({
  camId,
  roi,
  droi,
  setRegions,
  disabled,
  previewImage,
  cam,
  createCamRegion,
  updateCamRegion,
  deleteCamRegion,
}) => {
  const { t } = useTranslation();
  const [isModalOpen, setIsModalOpen] = useState(false);
  const [selectedRegion, setSelectedRegion] = useState(null);

  const closeModal = () => {
    setIsModalOpen(false);
    setSelectedRegion(null);
  };

  const openModal = () => {
    setIsModalOpen(true);
    // В RHF нет возможности установить touched вручную
    // setTouched(name);
  };

  const fetchAllCamRegions = async () => {
    const response = await lunaCarsClient.cams.showAllRegions(camId);

    setRegions(response.data.data.map((region) => {
      const $region = { ...region };
      const $droi = droi?.find((droiItem) => droiItem.id === region.id);
      $region.isHide = $droi?.isHide || false;

      return $region;
    }));
  };

  const editRegion = async (regionId) => {
    const response = await lunaCarsClient.cams.showRegion(regionId, camId);
    setSelectedRegion(response.data.data);
    openModal();
  };

  const onSubmit = async (data) => {
    try {
      if (selectedRegion) {
        await updateCamRegion(selectedRegion.id, camId, data);
      } else {
        await createCamRegion(camId, data);
      }

      closeModal();
      await fetchAllCamRegions();
    } catch (e) {
      return undefined;
    }
  };

  const onDelete = (regionId, regionName) => {
    const deletionMessage = (
      <span>
        {`${t('Вы уверены, что хотите удалить зону распознавания')}`}
        &nbsp;
        <strong>{regionName}</strong>
        ?
      </span>
    );

    openConfirmPopup({
      title: t('Удаление зоны распознавания'),
      message: deletionMessage,
      type: 'delete',
      onConfirm: async () => {
        await deleteCamRegion(regionId, camId);
        fetchAllCamRegions();
      },
    });
  };

  const toggleRegionVisibility = (filterId) => {
    setRegions(
      droi.map((region) => {
        if (region.id === filterId) {
          return {
            ...region,
            isHide: !region.isHide,
          };
        }

        return region;
      }),
    );
  };

  return (
    <div>
      <Control.Button
        disabled={disabled}
        className="RoiModalField__Button"
        onClick={() => openModal()}
        fullWidth
      >
        {t('Добавить зону распознавания')}
      </Control.Button>

      <Modal
        isOpen={isModalOpen}
        onRequestClose={closeModal}
        appElement={document.getElementById('root')}
      >
        <Page
          title={t('Зона распознавания')}
          className="RoiModalField__Modal"
        >
          <Divider small />
          <DroiForm
            name="points"
            selectedRegion={selectedRegion}
            previewUri={cam?.previewUri}
            roi={roi}
            onCancel={closeModal}
            onSubmit={onSubmit}
          />
        </Page>
      </Modal>

      <Divider small />

      {!previewImage && (
      <>
        {!droi?.length && (
        <h5 className="DroiTable__Title DroiTable__Title_disabled">
          {t('Зоны распознавания не добавлены')}
        </h5>
        )}
        {!!droi?.length && (
        <>
          <h5 className="DroiTable__Title">
            {t('Зоны распознавания')}
          </h5>

          <table className="DroiTable">
            <tbody>
              {droi.map(({
                id: regionId,
                tagName,
                displayColor,
                isHide,
              }) => (
                <tr key={regionId} className="DroiTable__tr">
                  <td className="DroiTable__td DroiTable__td_icon">
                    <Control.Checkbox
                      checked={!isHide}
                      onClick={() => toggleRegionVisibility(regionId)}
                      aria-label={t('Отображать на снимке')} // TODO: Жобавить перевод
                    />
                  </td>
                  <td
                    className="DroiTable__td DroiTable__td_bold"
                    style={{ color: displayColor }}
                  >
                    {tagName}
                  </td>

                  <td className="DroiTable__td DroiTable__td_icon">
                    <Control.RoundButton
                      variant="dimmed"
                      kind="primary"
                      icon={<EditIcon />}
                      onClick={() => editRegion(regionId)}
                      aria-label={t('Редактировать')} // TODO: Жобавить перевод
                    />
                  </td>
                  <td className="DroiTable__td DroiTable__td_icon">
                    <Control.RoundButton
                      variant="dimmed"
                      kind="alert"
                      icon={<DeleteIcon />}
                      onClick={() => onDelete(regionId, tagName)}
                      aria-label={t('Удалить')} // TODO: Жобавить перевод
                    />
                  </td>
                </tr>
              ))}
            </tbody>
          </table>
        </>
        )}
      </>
      )}
    </div>
  );
};

DroiModalField.propTypes = {
  camId: PropTypes.string.isRequired,
  roi: PropTypes.object.isRequired,
  droi: PropTypes.array.isRequired,
  setRegions: PropTypes.func.isRequired,
  disabled: PropTypes.bool,
  previewImage: PropTypes.object,
  cam: PropTypes.object,
  createCamRegion: PropTypes.func.isRequired,
  updateCamRegion: PropTypes.func.isRequired,
  deleteCamRegion: PropTypes.func.isRequired,
};

DroiModalField.defaultProps = {
  disabled: false,
  previewImage: undefined,
  cam: undefined,
};

export { DroiModalField };
