export const filterResetValues = {
  vehicleTypeId: [],
  vehicleTypeIdExclude: [],
  vehicleBrandId: [],
  vehicleBrandIdExclude: [],
  vehicleModelId: [],
  vehicleModelIdExclude: [],
  vehicleColorId: [],
  vehicleColorIdExclude: [],
  vehicleEmergencyTypeId: [],
  vehicleEmergencyTypeIdExclude: [],
  publicTransportTypeId: [],
  publicTransportTypeIdExclude: [],
  specialTransportTypeId: [],
  specialTransportTypeIdExclude: [],
  indicatorTypeId: [],
  indicatorTypeIdExclude: [],
  scenarioId: [],
  scenarioIdExclude: [],
  countryId: [],
  countryIdExclude: [],
  vehicleLicensePlate: '',
  vehicleAxlesAmount: '',
  camId: [],
  camIdExclude: [],
  listId: [],
  listIdExclude: [],
  registeredAfter: '',
  registeredBefore: '',
  recordId: '',
  recordIdExclude: '',
  imageId: '',
};

export const REFETCH_INTERVAL = 5000;
