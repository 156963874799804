import React from 'react';

import ReactDOM from 'react-dom';
import { Provider } from 'react-redux';
import { toast, ToastContainer } from 'react-toastify';

import App from 'app/App';
import store from 'store/index';
import { registerErrorHandlers } from 'utils/errors';

import { initApp } from './app/store/appSlice';
import * as serviceWorker from './serviceWorker';

import 'react-toastify/dist/ReactToastify.css';
import 'app/styles/index.sass';
import 'app/styles/toasts.sass';

registerErrorHandlers();

store.dispatch(initApp);

const container = document.getElementById('root');

// eslint-disable-next-line react/no-deprecated
ReactDOM.render(
  <React.StrictMode>
    <Provider store={store}>
      <App />
      <ToastContainer position={toast.POSITION.TOP_LEFT} icon={false} />
    </Provider>
  </React.StrictMode>,
  container,
);

// If you want your app to work offline and load faster, you can change
// unregister() to register() below. Note this comes with some pitfalls.
// Learn more about service workers: https://bit.ly/CRA-PWA
serviceWorker.unregister();
