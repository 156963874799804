import Axios from 'axios';
import formatISO from 'date-fns/formatISO';
import qs from 'qs';
import ReconnectingWebsocket from 'reconnecting-websocket';

export default class WSClient {
  constructor(baseURL) {
    this.baseURL = baseURL;
    this.socket = undefined;
    this.http = Axios.create({
      baseURL: this.baseURL,
      withCredentials: true,
    });
  }

  buildUrl(params, devHost) {
    let url;
    const { location: { protocol, host } } = window;
    if (protocol === 'https:') {
      url = 'wss:';
    } else {
      url = 'ws:';
    }
    if (devHost) {
      url += `//${devHost}${this.baseURL}`;
    } else {
      url += `//${host}${this.baseURL}`;
    }
    if (params) {
      const query = qs.stringify(params, {
        arrayFormat: 'comma',
        serializeDate: formatISO,
      });
      return `${url}?${query}`;
    }
    return url;
  }

  async getIsAvailable() {
    if (this.isAvailable !== undefined) return this.isAvailable;
    const resp = await this.http.head('/');
    this.isAvailable = resp.status === 405;
    return this.isAvailable;
  }

  async connect(queryParams, protocols, options, devHost = null) {
    if (this.socket) {
      await this.socket.close();
      this.socket = undefined;
    }

    const url = this.buildUrl(queryParams, devHost);
    this.socket = new ReconnectingWebsocket(url, protocols, {
      ...options,
      startClosed: options?.startClosed || false,
    });

    return this.socket;
  }

  async disconnect() {
    if (this.socket) {
      await this.socket.close();
      this.socket = undefined;
    }
  }
}
