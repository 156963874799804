/* eslint-disable prefer-destructuring */
/* eslint-disable no-await-in-loop */
import { createSlice } from '@reduxjs/toolkit';
import i18next from 'i18next';
import { toast } from 'react-toastify';

import { lunaCarsClient } from 'api';

const expandOptions = (data) => data.map(({ id, title, ...rest }) => ({
  value: id,
  label: title,
  ...rest,
}));

const compareLabels = ({ label }, { label: compareLabel }) => label.localeCompare(compareLabel);

export const defaultScenario = {
  title: '',
  scenarioType: '',
  indicatorTypeId: '',
  lists: [],
  statusIsOn: false,
};

const scenariosSlice = createSlice({
  name: 'scenarioItems',
  initialState: {
    currentScenario: defaultScenario,
    scenarioOptions: [],
    handlers: {
      data: [],
      meta: {},
    },
    data: [],
    filters: [],
    pageIndex: 0,
    pageSize: 25,
    pageSizeOptions: [10, 25, 50, 100],
  },
  reducers: {
    setCurrentScenario(state, { payload }) {
      state.currentScenario = payload;
    },
    setHandlers(state, { payload }) {
      state.handlers = payload;
    },
    setScenarioOptions(state, { payload }) {
      const { attribute, data } = payload;
      state[attribute] = expandOptions(data);
    },
    setScenariosList(state, { payload }) {
      state.data = payload;
    },
    setFilters(state, { payload: { filters } = {} }) {
      if (filters !== undefined) state.filters = filters;
    },
    setPage(state, { payload: { pageIndex } = {} }) {
      if (pageIndex !== undefined) state.pageIndex = pageIndex;
    },
    setPageSize(state, { payload: { pageSize } = {} }) {
      if (pageSize !== undefined) state.pageSize = pageSize;
    },
  },
});

export default scenariosSlice.reducer;

export const {
  setCurrentScenario, setHandlers, setScenarioOptions,
  setScenariosList, setFilters, setPage, setPageSize,
} = scenariosSlice.actions;

const setScenarioAttribute = (attribute, dispatch) => (data) => dispatch(
  setScenarioOptions({ attribute, data }),
);

export const fetchScenarioOptions = async (dispatch) => {
  let page = 0;
  let scenarios = [];
  // eslint-disable-next-line no-constant-condition
  while (true) {
    page += 1;
    const { data } = await lunaCarsClient.scenarios.showAll({ page, pageSize: 100 })
      .catch(() => ({}));
    if (!data) break;
    scenarios = [...scenarios, ...data.data];
    if (data.data?.length < data.meta?.pageSize) break;
  }
  setScenarioAttribute('scenarioOptions', dispatch)(scenarios);
};

export const updateScenarioOption = (id, data) => (dispatch, getState) => {
  const {
    scenarios: { scenarioOptions },
  } = getState();
  const newScenarios = scenarioOptions.map((scenario) => (
    scenario.value === id
      ? { ...data, id }
      : scenario
  ));
  setScenarioAttribute('scenarioOptions', dispatch)(newScenarios);
};

export const createScenarioOption = (scenario) => (dispatch, getState) => {
  const {
    scenarios: { scenarioOptions },
  } = getState();
  setScenarioAttribute('scenarioOptions', dispatch)([
    ...scenarioOptions,
    { ...scenario, label: scenario.title },
  ].sort(compareLabels));
};

export const fetchScenarios = async (dispatch, getState) => {
  const { scenarios: { pageIndex, pageSize, filters } } = getState();

  const localFilters = [];
  filters.forEach(({ id, value }) => {
    if (typeof value === 'object') {
      localFilters[id] = value.value;
    } else {
      localFilters[id] = value;
    }
    if (id === 'createdAt') {
      localFilters.createdAfter = value[0];
      localFilters.createdBefore = value[1];
    }
  });
  delete localFilters.createdAt;
  const { data: { data } } = await lunaCarsClient.scenarios.showAll({ page: pageIndex + 1, pageSize, ...localFilters });
  dispatch(setScenariosList(data));
};

export const deleteScenario = (id) => async (dispatch, getState) => {
  const {
    scenarios: { scenarioOptions },
  } = getState();
  try {
    await lunaCarsClient.scenarios.delete(id);
    const scenarios = scenarioOptions.filter(({ value }) => value !== id);
    setScenarioAttribute('scenarioOptions', dispatch)(scenarios);
  } catch (error) {
    toast.error(`${i18next.t('Серверная ошибка')}: ${JSON.stringify(error.response?.data)}`);
    throw error;
  }
  dispatch(fetchScenarios);
};

export const updateFilters = (params) => async (dispatch) => {
  dispatch(setFilters(params));
  dispatch(fetchScenarios);
};

export const updatePageSize = (params) => async (dispatch) => {
  dispatch(setPageSize(params));
  dispatch(fetchScenarios);
};

export const updatePage = (params) => async (dispatch) => {
  dispatch(setPage(params));
  dispatch(fetchScenarios);
};
