import React from 'react';

import './ScoreCell.sass';

export default ({ value: { label, score } }) => (
  <div className="ScoreCell">
    <span className="ScoreCell__label">{label}</span>
    <span className="ScoreCell__score">{score}</span>
  </div>
);
