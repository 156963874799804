/* eslint-disable prefer-destructuring */
import { createSlice } from '@reduxjs/toolkit';
import i18next from 'i18next';
import { toast } from 'react-toastify';

import { lunaCarsClient } from 'api';
import { fetchEvents } from 'features/events/eventsSlice';

import { updateRemoteValues } from './camFromApi';

let intervalId;

const camUpdatePeriod = 10000;

const camsSlice = createSlice({
  name: 'cams',
  initialState: {
    camOptions: [],
    fullCamOptions: [],
    fullData: [],
    data: [],
    meta: {},
    filters: [],
    instances: [],
    pageIndex: 0,
    pageSize: 25,
    pageSizeOptions: [10, 25, 50, 100],
    currentCamId: undefined,
  },
  reducers: {
    setPageCams(state, { payload }) {
      state.data = payload;
    },
    setAllCams(state, { payload }) {
      state.fullData = payload;
    },
    setCamsOptions(state, { payload }) {
      state.camOptions = payload.map(({ id, name }) => ({
        value: id,
        label: name,
      }));
    },
    setFullCamsOptions(state, { payload }) {
      state.fullCamOptions = payload.map(({ id, name, ...props }) => ({
        value: id,
        label: name,
        ...props,
      }));
    },
    setInstances(state, { payload }) {
      state.instances = payload.map(({ url, description }) => ({
        value: url,
        label: description,
      }));
    },
    setFilters(state, { payload: { filters } = {} }) {
      if (filters !== undefined) state.filters = filters;
    },
    setPage(state, { payload: { pageIndex } = {} }) {
      if (pageIndex !== undefined) state.pageIndex = pageIndex;
    },
    setPageSize(state, { payload: { pageSize } = {} }) {
      if (pageSize !== undefined) state.pageSize = pageSize;
    },
    setLatestEvents(state, { payload }) {
      state.latestEvents = payload;
    },
    setCurrentCamId(state, { payload }) {
      state.currentCamId = payload;
    },
    setAsyncData: {
      reducer(state, { payload: { data, meta } }) {
        data.forEach((event, i) => {
          if (event.cam?.id !== state.currentCamId) return;
          if (meta?.[i]?.operation === 'create') {
            state.latestEvents = [event, ...state.latestEvents].slice(0, state.pageSize);
          }
          if (meta?.[i]?.operation === 'update') {
            const index = state.latestEvents.findIndex(({ id }) => id === event.id);
            if (index === -1) return;
            state.latestEvents[index] = event;
          }
        });
      },
      prepare: (rawData) => ({ payload: JSON.parse(rawData) }),
    },
  },
});

export default camsSlice.reducer;

export const {
  setPageCams, setAllCams, setCamsOptions, setFullCamsOptions,
  setInstances, setFilters, setPageSize, setPage,
  setLatestEvents, setCurrentCamId, setAsyncData,
} = camsSlice.actions;

export const fetchCamOptions = async (dispatch) => {
  const { data: { data } } = await lunaCarsClient.cams.showAll({ pageSize: 0 });
  dispatch(setFullCamsOptions(data));
  dispatch(setCamsOptions(data));
};

const CAM_STATUSES = {
  STOPPED: 0,
  ACIVE: 1,
  CONNECTING: 2,
  FAILED: 3,
};

export const fetchCams = async (dispatch, getState) => {
  const { cams: { pageIndex, pageSize, filters } } = getState();

  const localFilters = [];
  filters.forEach(({ id, value }) => {
    if (typeof value === 'object') {
      localFilters[id] = value.value;
    } else {
      localFilters[id] = value;
    }
    if (id === 'updatedAt') {
      localFilters.updatedAfter = value[0];
      localFilters.updatedBefore = value[1];
    }
  });
  delete localFilters.updatedAt;
  const { data: { data } } = await lunaCarsClient.cams.showAll({ page: pageIndex + 1, pageSize, ...localFilters });
  dispatch(setPageCams(data));
};

export const fetchAllCams = async (dispatch, getState) => {
  const { cams: { fullData: prevFullData, data: prevData } } = getState();
  const { data: { data: newFullData } } = await lunaCarsClient.cams.showAll({ pageSize: 0 });
  prevFullData.forEach(({ id: prevId, status: prevStatus, name }) => {
    const index = newFullData.findIndex(({ id: newId }) => newId === prevId);
    if (index !== -1
        && [CAM_STATUSES.ACTIVE, CAM_STATUSES.CONNECTING].includes(prevStatus)
        && newFullData[index].status === CAM_STATUSES.FAILED
    ) {
      toast.warning(`${i18next.t('Сломалась камера')} ${name}`);
    }
  });

  const newData = [...prevData];
  prevData.forEach(({ id: prevId }, i) => {
    const index = newFullData.findIndex(({ id: newId }) => newId === prevId);
    if (index === -1) return;
    newData[i] = newFullData[index];
  });

  dispatch(setAllCams(newFullData));
  dispatch(setPageCams(newData));
};

export const fetchCam = async (camId) => {
  const { data: { data } } = await lunaCarsClient.cams.show(camId);
  const { data: { data: handlerTemplate } } = await lunaCarsClient.cams.showParamTypesByID(camId);
  return updateRemoteValues(data, handlerTemplate);
};

const sourceInstances = {
  'stream-sources': lunaCarsClient.cams.carsInstances,
  'video-sources': lunaCarsClient.cams.carsInstances,
  'anpr-sources': lunaCarsClient.cams.anprInstances,
  'rtsp-buffer-sources': lunaCarsClient.cams.readerInstances,
  other: lunaCarsClient.cams.carsInstances,
};
export const fetchCamInstances = (inputType) => async (dispatch) => {
  try {
    const getInstances = sourceInstances[inputType] || sourceInstances.other;
    const { data: { data } } = await getInstances();
    dispatch(setInstances(data));
  } catch (error) {
    toast.error(`${i18next.t('Серверная ошибка')}: ${JSON.stringify(error.response?.data)}`);
    dispatch(setInstances([]));
    throw error;
  }
};

export const createCam = (data) => async (dispatch) => {
  try {
    await lunaCarsClient.cams.create(data);
    toast.success(i18next.t('Камера успешно создана'));
  } catch (error) {
    toast.error(`${i18next.t('Серверная ошибка')}: ${JSON.stringify(error.response?.data)}`);
    throw error;
  }
  dispatch(fetchCamOptions);
};

export const updateCam = (camId, data) => async (dispatch) => {
  try {
    await lunaCarsClient.cams.update(camId, data);
    toast.success(i18next.t('Камера успешно обновлена'));
  } catch (error) {
    toast.error(`${i18next.t('Серверная ошибка')}: ${JSON.stringify(error.response?.data)}`);
    throw error;
  }
  dispatch(fetchCamOptions);
};

export const deleteCam = (camId) => async (dispatch) => {
  try {
    await lunaCarsClient.cams.delete(camId);
  } catch (error) {
    toast.error(`${i18next.t('Серверная ошибка')}: ${JSON.stringify(error.response?.data)}`);
    throw error;
  }
  dispatch(fetchCamOptions);
};

export const updateRegion = (regionId, camId, data) => async (dispatch) => {
  try {
    await lunaCarsClient.cams.updateRegion(regionId, camId, data);
  } catch (error) {
    toast.error(`${i18next.t('Серверная ошибка')}: ${JSON.stringify(error.response?.data)}`);
    throw error;
  }
  dispatch(fetchCamOptions);
};

export const createRegion = (camId, data) => async (dispatch) => {
  try {
    await lunaCarsClient.cams.createRegion(camId, data);
  } catch (error) {
    toast.error(`${i18next.t('Серверная ошибка')}: ${JSON.stringify(error.response?.data)}`);
    throw error;
  }
  dispatch(fetchCamOptions);
};

export const deleteRegion = (regionId, camId) => async (dispatch) => {
  try {
    await lunaCarsClient.cams.deleteRegion(regionId, camId);
  } catch (error) {
    toast.error(`${i18next.t('Серверная ошибка')}: ${JSON.stringify(error.response?.data)}`);
    throw error;
  }
  dispatch(fetchCamOptions);
};

export const setCamsFetchInterval = async (dispatch, getState) => {
  intervalId = setInterval(() => {
    const { account: { isAuthenticated } } = getState();
    if (!isAuthenticated) {
      clearInterval(intervalId);
      return;
    }
    dispatch(fetchAllCams);
  }, camUpdatePeriod);
};

export const updateFilters = (params) => async (dispatch) => {
  dispatch(setFilters(params));
  dispatch(fetchCams);
};

export const updatePageSize = (params) => async (dispatch) => {
  dispatch(setPageSize(params));
  dispatch(fetchCams);
};

export const updatePage = (params) => async (dispatch) => {
  dispatch(setPage(params));
  dispatch(fetchCams);
};

export const fetchCamEvents = (camId) => async (dispatch, getState) => {
  const { cams: { pageSize } } = getState();
  const { data: { data } } = await fetchEvents({ camId, pageSize });
  await dispatch(setLatestEvents(data));
  await dispatch(setCurrentCamId(camId));
};
