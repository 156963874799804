import { createSlice } from '@reduxjs/toolkit';

import { lunaCarsClient } from 'api';
import { connectAsyncEvents, disconnectAsyncEvents } from 'features/events/eventsSlice';
import { connectAsyncExtendedEvents, disconnectAsyncExtendedEvents } from 'features/extended-events/extendedEventsSlice';
import { connectAsyncIncidents, disconnectAsyncIncidents } from 'features/incidents/incidentsSlice';

const accountSlice = createSlice({
  name: 'account',
  initialState: {
    isAuthenticated: false,
    model: null,
  },
  reducers: {
    setAccount(state, { payload }) {
      state.model = payload;
      state.isAuthenticated = true;
    },
    logoutSuccess(state) {
      state.model = null;
      state.isAuthenticated = false;
    },
  },
});

export default accountSlice.reducer;

export const {
  setAccount,
  logoutSuccess,
} = accountSlice.actions;

export const fetchCurrentUser = async (dispatch) => {
  const page = window.location.pathname.replaceAll('/', '');

  try {
    const { data: { data: account } } = await lunaCarsClient.auth.getCurrentAccount();
    dispatch(setAccount(account));

    if (page === 'events') {
      dispatch(connectAsyncEvents);
    }

    if (page === 'extended-events') {
      dispatch(connectAsyncExtendedEvents);
    }

    if (page === 'incidents') {
      dispatch(connectAsyncIncidents);
    }
  } catch (error) {
    if (!error.response) throw error;
    const { status } = error.response;
    if (status !== 401 && status !== 403) throw error;
  }
};

export const logout = async (dispatch) => {
  const page = window.location.pathname.replaceAll('/', '');

  await lunaCarsClient.auth.logout();

  if (page !== 'events') {
    dispatch(disconnectAsyncEvents);
  }
  if (page !== 'extended-events') {
    dispatch(disconnectAsyncExtendedEvents);
  }
  if (page !== 'incidents') {
    dispatch(disconnectAsyncIncidents);
  }

  dispatch(logoutSuccess());
  window.history.replaceState({}, '', '/');
};

export const resetPasswordLogout = async (dispatch, getState) => {
  const {
    account: { isAuthenticated },
  } = getState();
  if (isAuthenticated) {
    await lunaCarsClient.auth.logout();
    dispatch(disconnectAsyncEvents);
    dispatch(disconnectAsyncExtendedEvents);
    dispatch(disconnectAsyncIncidents);
    dispatch(logoutSuccess());
  }
};
