import { createSlice } from '@reduxjs/toolkit';

import { fetchCurrentUser } from 'features/accounts/accountSlice';

const appSlice = createSlice({
  name: 'app',
  initialState: {},
  reducers: {
    updateState(state, { payload }) {
      state.state = payload;
    },
  },
});

export const { updateState } = appSlice.actions;

export const initApp = async (dispatch) => {
  dispatch(fetchCurrentUser);
};

export default appSlice.reducer;
