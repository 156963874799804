import React from 'react';

import { DeleteIcon, PlusIcon } from '@vlabs/icons';
import { Control, SettingsItemWrapper } from '@vlabs/uikit';
import PropTypes from 'prop-types';
import { useFieldArray, useFormContext } from 'react-hook-form';
import { useTranslation } from 'react-i18next';
import { v4 as uuidv4 } from 'uuid';

import './ItemSubForm.sass';

export default function ListSubForm({
  filteredListName,
  listOptions,
}) {
  const { t } = useTranslation();
  const { control, watch, setValue, formState: { errors } } = useFormContext();
  const { remove, fields } = useFieldArray({ name: filteredListName });
  return (
    <>
      <div className="Border" />

      {fields.map((param, i) => (
        <div className="ItemSubForm" key={uuidv4()}>
          <div style={{ display: 'flex', alignItems: 'center', justifyContent: 'space-between' }}>
            <div style={{ display: 'flex', alignItems: 'center' }}>
              <div style={{ width: '50px' }}>
                <Control.Switch
                  name={`lists.${i}.isExcept`}
                  control={control}
                  errors={errors}
                />
              </div>
              <span style={{ width: '100px' }}>{t(`(${t('Кроме')})`)}</span>
              <div style={{ width: '300px' }}>
                <Control.Select
                  name={`lists.${i}.listId`}
                  control={control}
                  options={listOptions}
                  placeholder={t('Список')}
                  noOptionsMessage={() => t('Создайте список в разделе "Списки"')}
                  rules={{ required: t('Поле обязательно для заполнения') }}
                  errors={errors}
                />
              </div>
            </div>
            <DeleteIcon
              className="Item_DeleteIcon"
              onClick={() => remove(i)}
            />
          </div>
        </div>
      ))}
      <SettingsItemWrapper>
        <div className="StoragePolicy_PlusIcon">
          <PlusIcon
            className="Icon"
            onClick={() => {
              setValue('lists',
                [...(watch('lists') || []), { listId: undefined, isExcept: false }]);
            }}
          />
        </div>
      </SettingsItemWrapper>
    </>
  );
}

ListSubForm.propTypes = {
  filteredListName: PropTypes.string,
  listOptions: PropTypes.arrayOf(PropTypes.object),
};

ListSubForm.defaultProps = {
  listOptions: [],
  filteredListName: '',
};
