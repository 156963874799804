/* eslint-disable jsx-a11y/no-static-element-interactions */
/* eslint-disable jsx-a11y/click-events-have-key-events */
import React from 'react';

import { Delete2Icon } from '@vlabs/icons';
import {
  Page, Control, Margin,
} from '@vlabs/uikit';
import { useForm } from 'react-hook-form';
import { useTranslation } from 'react-i18next';

import SettingFilter from 'components/setting-filter/SettingFilter';

import st from './AccuracyForm.module.sass';

function AccuracyForm({
  name = '',
  label = '',
  accuracies = {},
  onDelete = undefined,
  onSubmit = undefined,
}) {
  const { t } = useTranslation();
  const { handleSubmit, register, formState: { errors } } = useForm({
    defaultValues: {
      [`${name}Gte`]: accuracies[`${name}Gte`],
      [`${name}Lte`]: accuracies[`${name}Lte`],
    },
  });

  const accuracyValidator = (value) => {
    return ((value === undefined || (value <= 1 && value >= 0)) || t('Введите значение от {{min}} до {{max}}', { min: 0, max: 1 }));
  };
  return (
    <form onSubmit={handleSubmit(onSubmit)}>
      <Page title={<Margin left bottom>{t('Настройки')}</Margin>} className={st.Container}>
        <Margin left top>
          <h6>{label}</h6>
        </Margin>
        <Margin display="block">
          <SettingFilter label={t('Точность')}>
            <div className={st.Flex}>
              <div className={st.Full}>
                <Control.Input
                  id={`${name}Gte`}
                  {...register(`${name}Gte`, {
                    validate: {
                      accuracyValidator,
                    },
                  })}
                  step="0.01"
                  placeholder="0"
                  errors={errors}
                  type="number"
                />
              </div>
              :
              <div className={st.Full}>
                <Control.Input
                  id={`${name}Lte`}
                  {...register(`${name}Lte`, {
                    validate: {
                      accuracyValidator,
                    },
                  })}
                  step="0.01"
                  placeholder="1"
                  errors={errors}
                  type="number"
                />
              </div>
            </div>
          </SettingFilter>
        </Margin>
        <Margin display="block">
          <div className={st.Flex}>
            <Control.Button type="submit">
              {t('Принять')}
            </Control.Button>
            {((accuracies?.[`${name}Gte`] !== undefined && accuracies?.[`${name}Gte`] !== '')
              || (accuracies?.[`${name}Lte`] !== undefined && accuracies?.[`${name}Lte`] !== '')) && (
              <div onClick={() => onDelete(name)}>
                <Delete2Icon
                  className={st.Icon}
                />
                <div className={st.Link}>
                  {t('Удалить точность')}
                </div>
              </div>
            )}
          </div>
        </Margin>
      </Page>
    </form>
  );
}

export default AccuracyForm;
