import React, { useEffect } from 'react';

import { Panel, Table } from '@vlabs/uikit';
import { useTranslation } from 'react-i18next';

import InfoCell from 'components/info-cell/InfoCell';

import { useSocketHandler } from './useSocketHandler';

export default function IncidentsTable() {
  const { t } = useTranslation();
  const socketHandler = useSocketHandler('incidents');

  // eslint-disable-next-line react-hooks/exhaustive-deps
  useEffect(() => { socketHandler.subscribe(); return socketHandler.destroy; }, []);

  return (
    <Panel>
      <Table
        data={socketHandler.data}
        columns={[{
          Header: t('Информация'),
          accessor: (values) => values,
          Cell: InfoCell,
        }]}
      />
    </Panel>
  );
}
