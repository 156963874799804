/* eslint-disable jsx-a11y/no-static-element-interactions */
/* eslint-disable jsx-a11y/click-events-have-key-events */
import React, { useState } from 'react';

import { SquarePlusIcon, EditIcon } from '@vlabs/icons';
import { Modal, Control, Page, Margin } from '@vlabs/uikit';
import cn from 'classnames';
import { useForm } from 'react-hook-form';
import { useTranslation } from 'react-i18next';

import st from './CommentField.module.sass';

const CommentField = ({
  userNotes,
  onUserNotesSubmit,
}) => {
  const { t } = useTranslation();
  const [isModalOpen, setIsModalOpen] = useState(false);

  const { register, handleSubmit } = useForm({
    defaultValues: { userNotes },
  });

  const closeModal = () => {
    setIsModalOpen(false);
  };

  const openModal = () => {
    setIsModalOpen(true);
  };

  const $onUserNotesSubmit = ({ userNotes }) => {
    if (onUserNotesSubmit) onUserNotesSubmit(userNotes);
    closeModal();
  };

  return (
    <>
      <Modal
        isOpen={isModalOpen}
        onRequestClose={closeModal}
        appElement={document.getElementById('root')}
      >
        <Page className={st.Page}>
          <form onSubmit={handleSubmit($onUserNotesSubmit)}>
            <Control.Textarea
              data-testid="userNotes"
              label={t('Комментарий')}
              id="userNotes"
              rows={7}
              {...register('userNotes')}
            />
            <Margin display="block" top>
              <Control.Button type="submit" fullWidth>
                {t('Сохранить')}
              </Control.Button>
            </Margin>
          </form>
        </Page>
      </Modal>
      {!userNotes && (
      <div className={cn(st.CommentContainer, st.Link)} onClick={openModal}>
        <SquarePlusIcon className={st.Icon} />
        <span className={st.Text}>{t('Добавить')}</span>
      </div>
      )}
      {userNotes && (
      <div className={st.CommentContainer}>
        <EditIcon className={cn(st.Icon, st.Link)} onClick={openModal} />
        <span>{userNotes}</span>
      </div>
      )}
    </>
  );
};

export default CommentField;
