import { createSlice } from '@reduxjs/toolkit';

import { lunaCarsClient } from 'api';

import { SLICE_NAME } from '.';

const initialState = {
  xlsx: {
    groups: [],
    columns: [],
  },
};

const slice = createSlice({
  name: SLICE_NAME,
  initialState,
  reducers: {
    setXLSXSetings(state, { payload }) {
      state.xlsx = payload;
    },
  },
});

export default slice.reducer;

export const fetchSettings = async (dispatch) => {
  const [groups, columns] = await Promise.all([
    lunaCarsClient.exportResource.xlsx.groups(),
    lunaCarsClient.exportResource.xlsx.columns(),
  ]);
  dispatch(slice.actions.setXLSXSetings({ groups, columns }));
};

export const init = (dispatch) => {
  dispatch(fetchSettings);
};
