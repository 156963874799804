/* eslint-disable jsx-a11y/no-static-element-interactions */
/* eslint-disable jsx-a11y/click-events-have-key-events */
import React, { useState, useEffect } from 'react';

import {
  Grid, GridRow, GridCol, Divider, Page,
} from '@vlabs/uikit';
import parseISO from 'date-fns/parseISO';
import { useTranslation } from 'react-i18next';
import { connect } from 'react-redux';
import { useParams, useHistory } from 'react-router-dom';

import { selectAccountRole } from 'features/accounts/selectors';
import { selectScenarioOptions } from 'features/scenarios/selectors';
import { selectVehicleAttributes } from 'store/vehicle/selectors';

import { lunaCarsClient } from 'api';
import { HAS_FLASHING_LIGHTS_OPTIONS, SCENARIO_TYPE_OPTIONS, CLASS_TYPE_OPTIONS } from 'api-bindings/luna-cars/constants';
import CommentField from 'components/comment-field/CommentField';
import Meta from 'components/meta/Meta';
import PanelBlock from 'components/panel-block/PanelBlock';
import { can } from 'utils/can';

import { EventFrames } from './EventFrames';
import { updateEventUserNotes } from './eventsSlice';

import './EventPage.sass';

const EventPage = ({
  countryOptions,
  vehicleBrandOptions,
  vehicleColorOptions,
  vehicleColorTypeOptions,
  vehicleModelOptions,
  vehicleEmergencyTypeOptions,
  publicTransportTypeOptions,
  specialTransportTypeOptions,
  vehicleTypeOptions,
  scenarioIndicatorTypeOptions,
  // handlerTypeOptions,
  scenarioOptions,
  role,
}) => {
  const { t } = useTranslation();
  const history = useHistory();
  const { eventId } = useParams();
  const [event, setEvent] = useState({ data: [] });
  // FIXME: Костыльная проверка: если поле detections это {} и в нем есть поле type значит событие типа Пожар
  const isFireEvent = Boolean(event?.detections?.type);

  useEffect(() => {
    lunaCarsClient.events.show(eventId)
      .then(({ data: { data } }) => setEvent(data));
  }, [eventId]);

  const goToScenario = (scenarioId) => {
    history.push(`/scenarios/${scenarioId}`);
  };

  const scenario = scenarioOptions.find(({ value }) => event.scenarioId === value);
  const scenarioIndicatorType = scenarioIndicatorTypeOptions.find(({ value: id }) => event.indicatorTypeId === id);

  const buildCountryLabel = ({ countryId, features }) => {
    let countryLabel = countryOptions.find(({ value: id }) => countryId === id)?.label;
    if (!countryLabel) return '-';

    const licensePlateFeature = features?.find(({ type }) => type === 'rus_spec_type' || type === 'emirate_name')?.title;

    // Костыльно проверяем, зашит ли тип номера в название страны
    if (licensePlateFeature && countryLabel.indexOf('(') === -1) {
      countryLabel = `${countryLabel} (${licensePlateFeature})`;
    }

    return countryLabel;
  };

  const onUserNotesSubmit = async (userNotes) => {
    await updateEventUserNotes(eventId, { userNotes });
    setEvent({ ...event, userNotes });
  };

  return (
    <Page breadcrumbs={[
      { caption: t('События'), link: '/events' },
      { caption: eventId },
    ]}
    >
      <div className="EventPage__Container">
        <PanelBlock title={t('Общие')}>
          <Grid>
            <GridRow>
              <GridCol cols={6}>
                <div className="EventPage__SubContainer">
                  {event.id && (
                  <Meta
                    label={t('ID события')}
                    value={event.id}
                  />
                  )}
                  {event.registeredAt && (
                  <Meta
                    label={t('Дата, время регистрации')}
                    value={parseISO(event.registeredAt).toLocaleString()}
                  />
                  )}
                  {/* event.entryTime && (
                    <Meta
                      label={t('Дата входа')}
                      value={parseISO(event.entryTime).toLocaleString()}
                    />
                  ) */}
                  {/* event.exitTime && (
                    <Meta
                      label={t('Дата выхода')}
                      value={parseISO(event.exitTime).toLocaleString()}
                    />
                  ) */}
                  {event.cam && (
                  <Meta
                    label={t('Камера')}
                    value={event.cam?.name}
                  />
                  )}
                  {event.scenarioId && (
                  <Meta
                    label={t('Сценарий')}
                    value={(can(role, 'scenarios:read')
                      ? (
                        <div
                          className="EventPage__Link"
                          onClick={() => goToScenario(event.scenarioId)}
                        >
                          {scenario?.label || ''}
                        </div>
                      )
                      : scenario?.label || '')}
                  />
                  )}
                  {event.scenarioId && (
                  <Meta
                    label={t('Тип сценария')}
                    value={SCENARIO_TYPE_OPTIONS[scenario?.scenarioType]?.label}
                  />
                  )}
                  {event.indicatorTypeId && (
                    <Meta
                      label={event.type === 'event' ? t('Тип события') : t('Тип инцидента')}
                      value={scenarioIndicatorType?.label}
                    />
                  )}
                  <Meta
                    label={t('Комментарий')}
                    value={(
                      <CommentField
                        userNotes={event.userNotes}
                        onUserNotesSubmit={onUserNotesSubmit}
                      />
                    )}
                  />

                  <Divider small />

                  {event.vehicleTrackId && (
                  <Meta
                    label={t('Трек ID')}
                    value={event.vehicleTrackId}
                  />
                  )}
                  {event.frameId >= 0 && (
                  <Meta
                    label={t('ID кадра')}
                    value={event.frameId}
                  />
                  )}
                  {event.direction && (
                  <Meta
                    label={t('Направление выезда')}
                    value={JSON.stringify(event.direction)}
                  />
                  )}
                  {/* event.scenarioId && scenario?.handlerTemplates.length > 0 && (
                    <Meta
                      label={t('Обработчик')}
                      value={scenario?.handlerTemplates?.map((handler) => (
                        <div key={handler}>
                          { handlerTypeOptions.find(({ value }) => value === handler)?.label }
                        </div>
                      ))}
                    />
                  ) */}
                </div>
              </GridCol>
              <GridCol cols={6}>
                <div className="EventPage__Content">
                  {event.vehicleBestshot && (
                  <div className="EventPage__Image">
                    <img
                      className="ExtendedFrames__Shot"
                      src={event.vehicleBestshot}
                      alt="Бестшот"
                    />
                  </div>
                  )}
                  {event.fragmentGroups?.map(({ id, label, fragments }) => (
                    <EventFrames
                      id={id}
                      label={label}
                      fragments={fragments}
                      type={scenarioIndicatorType?.label}
                      key={id}
                    />
                  ))}
                  {(!event.fragmentGroups || event.fragmentGroups.length === 0) && (
                  <>
                    <div className="EventPage__EmptyContent">
                      {t('Кадры отсутствуют')}
                    </div>
                    <Divider small />
                  </>
                  )}
                </div>
              </GridCol>
            </GridRow>
          </Grid>
        </PanelBlock>

        <Divider />

        <PanelBlock title={t('Атрибуты')}>
          <Grid>
            <GridRow>
              <GridCol cols={6}>
                <div className="EventPage__SubContainer">
                  {event.vehicleTypeId && (
                  <Meta
                    label={t('Тип')}
                    value={vehicleTypeOptions.find(({ value: id }) => event.vehicleTypeId === id)?.label}
                    accuracyValue={event.vehicleTypeAccuracy}
                  />
                  )}
                  {event.vehicleBrandId && (
                  <Meta
                    label={t('Марка')}
                    value={vehicleBrandOptions.find(({ value: id }) => event.vehicleBrandId === id)?.label}
                    accuracyValue={event.vehicleBrandAccuracy}
                  />
                  )}
                  {event.vehicleModelId && (
                  <Meta
                    label={t('Модель')}
                    value={vehicleModelOptions.find(({ value: id }) => event.vehicleModelId === id)?.label}
                    accuracyValue={event.vehicleModelAccuracy}
                  />
                  )}
                  {event.vehicleEmergency?.type && (
                    <Meta
                      label={t('Экстренная служба')}
                      pairs={[
                        {
                          value: vehicleEmergencyTypeOptions.find(({
                            value: id,
                          }) => event.vehicleEmergency.type === id)?.label,
                          accuracy: event.vehicleEmergency.typeAccuracy,
                        },
                        {
                          value: HAS_FLASHING_LIGHTS_OPTIONS[event.vehicleEmergency.hasFlashingLights]?.label,
                          accuracy: event.vehicleEmergency.hasFlashingLightsAccuracy,
                        },
                      ]}
                    />
                  )}
                  {event.publicTransportTypeId && (
                  <Meta
                    label={t('Общ. транспорт')}
                    value={publicTransportTypeOptions.find(({
                      value: id,
                    }) => event.publicTransportTypeId === id)?.label}
                    accuracyValue={event.publicTransportTypeAccuracy}
                  />
                  )}
                  {event.specialTransportTypeId && (
                  <Meta
                    label={t('Спец. техника')}
                    value={specialTransportTypeOptions.find(({
                      value: id,
                    }) => event.specialTransportTypeId === id)?.label}
                    accuracyValue={event.specialTransportTypeAccuracy}
                  />
                  )}
                  {event.vehicleColorTypeId && (
                  <Meta
                    label={t('Тип цвета')}
                    value={vehicleColorTypeOptions.find(({ value: id }) => event.vehicleColorTypeId === id)?.label}
                    accuracyValue={event.vehicleColorTypeAccuracy}
                  />
                  )}
                  {event.vehicleColors?.length > 0 && (
                  <Meta
                    label={t('Цвет')}
                    pairs={event.vehicleColors.map((value) => ({
                      value: vehicleColorOptions.find(({ value: id }) => value.vehicleColorId === id)?.label,
                      accuracy: value.vehicleColorAccuracy,
                    }))}
                  />
                  )}
                  {event.vehicleAxlesAmount > 0 && (
                  <Meta
                    label={t('Количество осей')}
                    value={event.vehicleAxlesAmount}
                    accuracyValue={event.vehicleAxlesAccuracy}
                  />
                  )}
                  {event.numberOfObjects && (
                    <Meta
                      label={t('Количество ТС')}
                      value={event.numberOfObjects}
                    />
                  )}
                  {/* event.vehicleLicensePlate && (
                    <>
                      <Meta
                        label={t('Номер')}
                        value={event.vehicleLicensePlate}
                        accuracyValue={event.vehicleLicensePlateAccuracy}
                      />

                      {event.countryId && (
                        <Meta
                          label={t('Страна')}
                          value={buildCountryLabel({
                            countryId: event.countryId,
                            features: event.licensePlateFeature,
                          })}
                          accuracyValue={event.countryAccuracy}
                        />
                      )}
                    </>
                  ) */}
                  {isFireEvent && (
                  <>
                    {event?.detections?.meta?.fire !== undefined && <Meta label={t('Огонь')} value={event.detections.meta.fire} />}
                    {event?.detections?.meta?.blackSmoke !== undefined && <Meta label={t('Черный дым')} value={event.detections.meta.blackSmoke} />}
                    {event?.detections?.meta?.whiteSmoke !== undefined && <Meta label={t('Белый дым')} value={event.detections.meta.whiteSmoke} />}
                    {event?.detections?.meta?.noFire !== undefined && <Meta label={t('Нет возгорания')} value={event.detections.meta.noFire} />}
                  </>
                  )}
                </div>
              </GridCol>
              <GridCol cols={6}>
                {event.vehicleBestshot && (
                  <img
                    className="EventPage__FireEventImage"
                    src={event.vehicleBestshot}
                    alt="fire event img"
                  />
                )}
                {!event.vehicleBestshot && (
                  <>
                    <div className="EventPage__EmptyContent">
                      {t('Кадры отсутствуют')}
                    </div>
                    <Divider small />
                  </>
                )}
              </GridCol>
            </GridRow>
          </Grid>
        </PanelBlock>

        <Divider />
        <PanelBlock title={t('Регистрационные знаки')}>
          <div className="EventPage__SubContainer">
            {event.vehicleLicensePlates?.map((plate) => (
              <>
                <div className="EventPage__Plate">
                  <div className="EventPage__Plate_number">
                    <Meta label={t('ГРЗ')} value={`${plate.symbols} {${CLASS_TYPE_OPTIONS[plate.classType]?.label}}`} accuracyValue={plate.accuracy} />

                    {plate.countryId && (
                    <Meta
                      label={t('Страна')}
                      value={buildCountryLabel({
                        countryId: plate.countryId,
                        features: plate.licensePlateFeature,
                      })}
                      accuracyValue={plate.countryAccuracy}
                    />
                    )}
                  </div>
                  <div className="EventPage__Plate_imageContainer">
                    <img
                      className="EventPage__Plate_image"
                      src={plate.image}
                      alt="fire event img"
                    />
                  </div>
                </div>
                <div className="EventPage__Border" />
              </>
            ))}
          </div>
        </PanelBlock>

        {event.speeds?.length > 0 && (
        <>
          <Divider />
          <PanelBlock title={t('Скорость по полосам')}>
            <div className="EventPage__SubContainer">
              {event.speeds.map(({ title, speed }) => (
                <Meta label={title} value={`${speed} ${t('км/ч')}`} />
              ))}
            </div>
          </PanelBlock>
        </>
        )}
      </div>
    </Page>
  );
};

export default connect((state) => ({
  ...selectVehicleAttributes(state),
  scenarioOptions: selectScenarioOptions(state),
  role: selectAccountRole(state),
}))(EventPage);
