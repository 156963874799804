import { createSlice } from '@reduxjs/toolkit';
import i18next from 'i18next';
import { toast } from 'react-toastify';

import { lunaCarsClient } from 'api';
import { flattenFilters } from 'utils/utils';

import { REFETCH_INTERVAL } from './constants';

const eventsSlice = createSlice({
  name: 'events',
  initialState: {
    data: [],
    meta: {},
    filters: [],
    pageSize: 10,
    pageSizeOptions: [10, 25, 50],
    since: undefined,
    until: undefined,
    allIds: [],
    paginationType: 'timeBased',
    isFoldOpen: true,
    socketSubscriptionId: undefined,
  },
  reducers: {
    setRestApiData(state, { payload: { data, meta } }) {
      state.data = data;
      state.meta = meta;
      state.allIds = data.map(({ id }) => id);
    },
    setAsyncApiData: {
      reducer(state, { payload: { data, meta } }) {
        data.forEach((event, i) => {
          if (meta?.[i]?.operation === 'create') {
            state.data = [event, ...state.data].slice(0, state.pageSize);
          }
          if (meta?.[i]?.operation === 'update') {
            const index = state.data.findIndex(({ id }) => id === event.id);
            if (index === -1) return;
            state.data[index] = event;
          }
        });
      },
    },
    setParams(state, {
      payload: {
        // since и until поменяны местами, потому что направление списка от "свежих" к "старым"
        since: until, until: since, pageSize, filters, isFoldOpen,
      } = {},
    }) {
      if (filters !== undefined) {
        state.filters = filters;
        if (filters.length === 0) return;
      }
      if (isFoldOpen !== undefined) state.isFoldOpen = isFoldOpen;
      if (pageSize !== undefined) state.pageSize = pageSize;
      state.since = since ? since.registeredAt : undefined;
      state.until = until ? until.registeredAt : undefined;
    },
    setSocketSubscriptionId(state, { payload }) {
      if (state.socketSubscriptionId !== undefined) {
        if (typeof payload === 'number') {
          clearInterval(state.socketSubscriptionId);
        } else {
          lunaCarsClient.wsEvents.unsubscribe(state.socketSubscriptionId);
        }
      }
      state.socketSubscriptionId = payload;
    },
  },
});

export default eventsSlice.reducer;

export const { setAsyncApiData, setParams, setRestApiData } = eventsSlice.actions;

export const fetchEvents = async (params) => lunaCarsClient.events.showAll(params);

export const fetchEventsRest = async (dispatch, getState) => {
  const {
    events: {
      pageSize, filters, since, until,
    },
  } = getState();

  // eslint-disable-next-line prefer-const
  let { data, status } = await lunaCarsClient.events.showAll({
    ...flattenFilters(filters),
    since,
    until,
    pageSize,
  });
  if (status === 201) {
    toast.info(i18next.t('Задача  была создана, для просмотра перейдите в "Задачи"'));
    return;
  }
  if (data?.data?.length < pageSize && since !== undefined) {
    data = await lunaCarsClient.events.showAll({
      ...flattenFilters(filters),
      pageSize,
    })
      .then(({ data: firstPageData }) => firstPageData);
  }

  await dispatch(setRestApiData(data));
};

export const updateEventUserNotes = async (eventId, data) => {
  try {
    await lunaCarsClient.events.update(eventId, data);
    toast.success(i18next.t('Комментарий успешно добавлен'));
  } catch (error) {
    toast.error(`${i18next.t('Серверная ошибка')}: ${JSON.stringify(error.response?.data)}`);
    throw error;
  }
};

export const connectAsyncEvents = async (dispatch) => {
  const { data: { enabledWebSocketUpdates } } = await lunaCarsClient.settings.showAll();
  if (enabledWebSocketUpdates) {
    const { subscriptionId } = lunaCarsClient.wsEvents.subscribe((msg) => dispatch(setAsyncApiData(msg)));
    dispatch(eventsSlice.actions.setSocketSubscriptionId(subscriptionId));
  } else {
    const subscriptionId = setInterval(() => {
      dispatch(fetchEventsRest);
    }, REFETCH_INTERVAL);
    dispatch(eventsSlice.actions.setSocketSubscriptionId(subscriptionId));
  }
};

export const disconnectAsyncEvents = (dispatch, getState) => {
  const { events: { socketSubscriptionId } } = getState();
  lunaCarsClient.wsEvents.unsubscribe(socketSubscriptionId);
  dispatch(eventsSlice.actions.setSocketSubscriptionId(undefined));
};

export const updateParams = (params) => async (dispatch, getState) => {
  const { events: { filters: stateFilters } } = getState();
  const { filters } = params;

  // Если у происходит переход на нулевую страницу без фильтров, то подключаем повторно
  // websocket для непрерывного обновления данных. В случае если задан какой-либо из фильтров,
  // либо пользователь находится на странице, отличной от 0 — отключаем непрерывное обновление.
  const isAnyStateFilters = stateFilters.length !== 0 && filters === undefined;
  let isAnyFilters = false;

  if (filters !== undefined) {
    isAnyFilters = filters.some(
      ({ value }) => value !== '' && value?.length !== 0,
    );
  }

  await dispatch(setParams(params));

  // При юзкейсе, когда мы находимся на страинце, отличной от нулевой и меняем размер страницы
  // генерируется 2 события — одно для смены размера страницы, другое для перехода на страницу
  // с индексом 0, в результате чего выполняется подряд 2 запроса к серверу, один из которых
  // зачастую оказывается нерелевантным. (http://git.visionlabs.ru/frontend/uikit/-/issues/25)
  await dispatch(fetchEventsRest)
    .then(() => {
      const { events: { meta: { sinceAvailable } } } = getState();
      if (!sinceAvailable && !isAnyStateFilters && !isAnyFilters) {
        dispatch(connectAsyncEvents);
      } else if (!isAnyStateFilters) {
        dispatch(disconnectAsyncEvents);
      }
    });
};
