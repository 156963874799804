import React, { useCallback } from 'react';

import {
  Page, Table, Panel, TableCells, useTableHook, Divider,
} from '@vlabs/uikit';
import PropTypes from 'prop-types';
import { useTranslation } from 'react-i18next';
import { connect } from 'react-redux';
import { useParams, useHistory } from 'react-router-dom';

import { selectVehicleAttributes } from 'store/vehicle/selectors';

import { lunaCarsClient } from 'api';
import { PAGE_SIZE_OPTIONS } from 'api-bindings/luna-cars/constants';
import BestshotCell from 'components/bestshot/Bestshot';
import CamCell from 'components/cam-cell/CamCell';
import ScoreCell from 'components/score-cell/ScoreCell';
import vehicleAttributeAccessor from 'components/vehicleAttributeAccessor';

function TaskPage({
  vehicleTypeOptions = [],
  vehicleBrandOptions = [],
  vehicleModelOptions = [],
  vehicleColorTypeOptions = [],
  vehicleColorOptions = [],
  vehicleEmergencyTypeOptions = [],
  countryOptions = [],
}) {
  const { t } = useTranslation();
  const history = useHistory();
  const { taskId } = useParams();

  const onFetch = ({ page = 0, ...params }) => lunaCarsClient.tasks
    .showResult(taskId, {
      dataFormat: 'json', page: page + 1, ...params,
    })
    .then(({ data }) => data);
  const tableProps = useTableHook(useCallback(onFetch, [taskId]));

  const columnsEvents = [
    { Header: t('Камера'), accessor: 'cam', Cell: CamCell },
    { Header: t('Время'), accessor: 'registeredAt', Cell: TableCells.DateCell },
    {
      Header: t('Тип'),
      accessor: ({
        vehicleTypeId: id,
        vehicleTypeAccuracy: score,
      }) => ({
        label: vehicleAttributeAccessor(id, vehicleTypeOptions),
        score,
      }),
      Cell: ScoreCell,
    },
    {
      Header: t('Марка'),
      accessor: ({
        vehicleBrandId: id,
        vehicleBrandAccuracy: score,
      }) => ({
        label: vehicleAttributeAccessor(id, vehicleBrandOptions, score),
        score,
      }),
      Cell: ScoreCell,
    },
    {
      Header: t('Модель'),
      accessor: ({
        vehicleModelId: id,
        vehicleModelAccuracy: score,
      }) => ({
        label: vehicleAttributeAccessor(id, vehicleModelOptions, score),
        score,
      }),
      Cell: ScoreCell,
    },
    {
      Header: t('Экстренная служба'),
      accessor: ({
        vehicleEmergencyTypeId: id,
        vehicleEmergencyTypeAccuracy: score,
      }) => ({
        label: vehicleAttributeAccessor(id, vehicleEmergencyTypeOptions, score),
        score,
      }),
      Cell: ScoreCell,
    },
    {
      Header: t('Тип цвета'),
      accessor: ({
        vehicleColorTypeId: id,
        vehicleColorTypeAccuracy: score,
      }) => ({
        label: vehicleAttributeAccessor(id, vehicleColorTypeOptions, score),
        score,
      }),
      Cell: ScoreCell,
    },
    {
      Header: t('Цвет'),
      accessor: ({
        vehicleColors,
      }) => ({
        label: vehicleAttributeAccessor(
          vehicleColors?.[0]?.vehicleColorId,
          vehicleColorOptions,
          vehicleColors?.[0]?.vehicleColorAccuracy,
        ),
        score: vehicleColors?.[0]?.vehicleColorAccuracy,
      }),
      Cell: ScoreCell,
    },
    {
      Header: t('Страна'),
      accessor: ({
        countryId: id,
        countryAccuracy: score,
      }) => ({
        label: vehicleAttributeAccessor(id, countryOptions, score),
        score,
      }),
      Cell: ScoreCell,
    },
    {
      Header: t('Номер'),
      accessor: ({
        vehicleLicensePlate: label,
        vehicleLicensePlateAccuracy: score,
      }) => ({ label, score }),
      Cell: ScoreCell,
    },
    { Header: t('Фото ТС'), accessor: (row) => BestshotCell(row.vehicleBestshot) },
    { Header: t('Фото ГРЗ'), accessor: (row) => BestshotCell(row.vehicleLicensePlateBestshot, 'plate') },
    { Header: 'Track ID', accessor: 'vehicleTrackId' },
    { Header: 'Frame ID', accessor: 'frameId' },
  ];

  return (
    <Page title={t('Результат поиска по изображению')} onBackButtonClick={() => history.push('/tasks')}>
      <Divider />
      <Panel>
        <Table
          {...tableProps}
          paginationType="offsetBased"
          pageSizeOptions={PAGE_SIZE_OPTIONS}
          columns={columnsEvents}
          hideDeleteButton
          hideCheckbox
        />
      </Panel>
    </Page>
  );
}

const dropdownOptionPropTypes = PropTypes.shape({
  id: PropTypes.oneOfType([PropTypes.string, PropTypes.number]),
  value: PropTypes.oneOfType([PropTypes.string, PropTypes.number]),
});

TaskPage.propTypes = {
  vehicleTypeOptions: PropTypes.arrayOf(dropdownOptionPropTypes),
  vehicleBrandOptions: PropTypes.arrayOf(dropdownOptionPropTypes),
  vehicleModelOptions: PropTypes.arrayOf(dropdownOptionPropTypes),
  vehicleColorOptions: PropTypes.arrayOf(dropdownOptionPropTypes),
  vehicleColorTypeOptions: PropTypes.arrayOf(dropdownOptionPropTypes),
  vehicleEmergencyTypeOptions: PropTypes.arrayOf(dropdownOptionPropTypes),
  countryOptions: PropTypes.arrayOf(dropdownOptionPropTypes),
};
TaskPage.defaultProps = {
  vehicleTypeOptions: [],
  vehicleBrandOptions: [],
  vehicleModelOptions: [],
  vehicleColorOptions: [],
  vehicleColorTypeOptions: [],
  vehicleEmergencyTypeOptions: [],
  countryOptions: [],
};

export default connect(selectVehicleAttributes)(TaskPage);
