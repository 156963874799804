import React, { useState } from 'react';

import {
  Control, Modal, ROI, Page, Divider,
} from '@vlabs/uikit';
import PropTypes from 'prop-types';
import { useFormContext } from 'react-hook-form';
import { useTranslation } from 'react-i18next';

import './RoiModalField.sass';

const RoiModalField = ({
  disabled,
  ...restProps
}) => {
  const { t } = useTranslation();
  const { name } = restProps;
  const { setValue } = useFormContext();
  const [isModalOpen, setIsModalOpen] = useState(false);

  const closeModal = () => {
    setIsModalOpen(false);
  };

  const openModal = () => {
    setIsModalOpen(true);
    // В RHF нет возможности установить touched вручную
    // setTouched(name);
  };

  const setROI = (coords) => {
    setValue(name, coords);
    closeModal();
  };

  return (
    <div>
      <Control.Button
        disabled={disabled}
        className="RoiModalField__Button"
        onClick={() => openModal()}
        fullWidth
      >
        {t('Редактировать зону детекции')}
      </Control.Button>

      <Modal
        isOpen={isModalOpen}
        onRequestClose={closeModal}
        appElement={document.getElementById('root')}
      >
        <Page
          className="RoiModalField__Modal"
          title={t('Зона детекции')}
        >
          <Divider small />
          <ROI
            isNormalized={false}
            maxModalSizeRatio={0.6}
            onROISave={setROI}
            onCancel={closeModal}
            isRectangle
            coordinatesRounding={0}
            sizeRounding={0}
            hintText={t('* Зона детекции указывает на область кадра, которая будет обрабатываться CARS.Stream')}
            {...restProps}
          />
        </Page>
      </Modal>
    </div>

  );
};

RoiModalField.propTypes = {
  disabled: PropTypes.bool,
};

RoiModalField.defaultProps = {
  disabled: false,
};

export { RoiModalField };
