import React, { useState, useEffect } from 'react';

import { PlusIcon } from '@vlabs/icons';
import { Divider } from '@vlabs/uikit';
import cn from 'classnames';
import PropTypes from 'prop-types';
import { useTranslation } from 'react-i18next';

import './Dropzone.sass';

const fileListToArray = (list) => {
  const array = [];
  for (let i = 0; i < list.length; i += 1) {
    array.push(list.item(i));
  }
  return array;
};

const DefaultCaption = () => {
  const { t } = useTranslation();
  return (
    <>
      <PlusIcon />
      <span className="Subtitle-2">{t('Щёлкните или перетащите файл')}</span>
      <span className="Small-2">{t('Фотография или пакет фотографий для идентификации (*, zip)')}</span>
    </>
  );
};

const Dropzone = ({
  onFilesAdded,
  caption,
  disabled,
  error,
  file,
  accept,
}) => {
  const fileInputRef = React.createRef();
  const [highlight, setHighlight] = useState(false);

  const onFilesAddedHandler = (evt) => {
    const { files } = evt.target;
    const filesArray = fileListToArray(files);
    if (onFilesAdded) {
      onFilesAdded(filesArray[0]);
    }
  };

  const onDragOverHandler = (event) => {
    event.preventDefault();
    setHighlight(true);
  };

  const onDragLeaveHandler = () => setHighlight(false);

  const onDropHandler = (event) => {
    event.preventDefault();
    const { files } = event.dataTransfer;
    const filesArray = fileListToArray(files);
    if (onFilesAdded) {
      onFilesAdded(filesArray[0]);
    }
    setHighlight(false);
  };

  const openFileDialogHandler = () => fileInputRef.current.click();

  useEffect(() => {
    if (disabled) return;
    if (!file) {
      fileInputRef.current.value = '';
    }
  }, [file, fileInputRef, disabled]);

  return (
    <div
      className={cn({
        Dropzone: true,
        'Dropzone--highlight': highlight,
        'Dropzone--error': error,
      })}
      onDragOver={!disabled ? onDragOverHandler : undefined}
      onDragLeave={!disabled ? onDragLeaveHandler : undefined}
      onDrop={!disabled ? onDropHandler : undefined}
      onClick={!disabled ? openFileDialogHandler : undefined}
      onKeyPress={!disabled ? openFileDialogHandler : undefined}
      role="button"
      tabIndex={disabled ? -1 : 0}
      disabled={disabled}
    >
      <input
        ref={fileInputRef}
        className="FileInput"
        type="file"
        accept={accept}
        onChange={onFilesAddedHandler}
        aria-label="Загрузить файл" // TODO: Добавить перевод
      />
      {caption}
      <Divider small />
      {error}
    </div>
  );
};

Dropzone.propTypes = {
  onFilesAdded: PropTypes.func.isRequired,
  caption: PropTypes.node,
  disabled: PropTypes.bool,
  error: PropTypes.node,
  // eslint-disable-next-line react/forbid-prop-types
  file: PropTypes.any,
  accept: PropTypes.arrayOf(PropTypes.string),
};

Dropzone.defaultProps = {
  caption: <DefaultCaption />,
  disabled: false,
  error: undefined,
  file: undefined,
  accept: [
    'image/*',
    '.zip',
  ],
};

export default Dropzone;
