import React from 'react';

import { Page, Margin } from '@vlabs/uikit';
import parseISO from 'date-fns/parseISO';
import { useTranslation } from 'react-i18next';

import { FRAGMENT_TYPE_OPTIONS } from 'api-bindings/luna-cars/constants';

import BboxImageSelector from './BboxImageSelector';
import st from './FragmentForm.module.sass';
import Meta from './Meta';

const FragmentForm = ({
  image,
  detections,
  frameId,
  label,
  ts,
  type,
  eventType,
}) => {
  const { t } = useTranslation();
  let fragmentLabel = FRAGMENT_TYPE_OPTIONS[type]?.label;
  if ((eventType === 'Человек' || eventType === 'Пешеход') && type === 'vehicle_crop') fragmentLabel = 'Кроп пешехода';
  if (eventType === 'Животное' && type === 'vehicle_crop') fragmentLabel = 'Кроп животного';

  return (
    <Page>
      <div className={st.Container}>
        <Margin right>
          <div className={st.Info}>
            {frameId && (<Meta label={t('Frame ID')} value={frameId} />)}
            {ts && (<Meta label={t('Время')} value={parseISO(ts).toLocaleString()} />)}
            {type && (<Meta label={t('Тип')} value={fragmentLabel} />)}
            {label && (<Meta label={t('Лейбл')} value={label} />)}
          </div>
        </Margin>
        <BboxImageSelector
          detections={detections}
          image={image}
        />
      </div>
    </Page>
  );
};

export default FragmentForm;
