/* eslint-disable jsx-a11y/click-events-have-key-events */
/* eslint-disable jsx-a11y/no-static-element-interactions */
import React from 'react';

import { CircleInfoIcon } from '@vlabs/icons';
import { Tooltip } from '@vlabs/uikit';

import './ParameterWrapper.sass';

export default function ParameterWrapper({
  onClick,
  node,
  title,
  children,
  parameterType,
  'data-tip': dataTip,
  color,
}) {
  return (
    <div onClick={onClick} className="ParameterWrapper">
      <div style={{
        backgroundColor: (parameterType === 'zoneSelector' && color) || 'transparent',
        width: '50px',
        height: '20px',
        marginRight: '15px',
      }}
      />
      <div className="ParameterWrapper__Titlte">
        {node && <div>{node}</div>}
        <div>{title}</div>
        {dataTip && (
          <Tooltip tooltipProps={{ effect: 'solid', place: 'right' }} className="ParameterWrapper__Tooltip" data-tip={dataTip}>
            <CircleInfoIcon className="ParameterWrapper__Tooltip_icon" />
          </Tooltip>
        )}
      </div>
      <div>{children}</div>
    </div>
  );
}
