/* eslint-disable jsx-a11y/no-static-element-interactions */
/* eslint-disable jsx-a11y/click-events-have-key-events */
import React, { useState, useEffect } from 'react';

import {
  Grid, GridRow, GridCol, Divider, Page,
} from '@vlabs/uikit';
import parseISO from 'date-fns/parseISO';
import { useTranslation } from 'react-i18next';
import { connect } from 'react-redux';
import { useParams, useHistory } from 'react-router-dom';

import { selectAccountRole } from 'features/accounts/selectors';
import { selectScenarioOptions } from 'features/scenarios/selectors';
import { selectVehicleAttributes } from 'store/vehicle/selectors';

import { lunaCarsClient } from 'api';
import { SCENARIO_TYPE_OPTIONS, CLASS_TYPE_OPTIONS } from 'api-bindings/luna-cars/constants';
import CommentField from 'components/comment-field/CommentField';
import Meta from 'components/meta/Meta';
import PanelBlock from 'components/panel-block/PanelBlock';
import { can } from 'utils/can';

import { ExtendedEventFrames } from './ExtendedEventFrames';
import { updateExtendedEventUserNotes } from './extendedEventsSlice';

import '../events/EventPage.sass';
import './ExtendedFrames.sass';

const ExtendedEventPage = ({
  countryOptions,
  vehicleBrandOptions,
  vehicleColorOptions,
  vehicleColorTypeOptions,
  vehicleModelOptions,
  vehicleEmergencyTypeOptions,
  publicTransportTypeOptions,
  specialTransportTypeOptions,
  vehicleTypeOptions,
  scenarioIndicatorTypeOptions,
  handlerTypeOptions,
  scenarioOptions,
  role,
}) => {
  const { t } = useTranslation();
  const history = useHistory();
  const { eventId } = useParams();
  const [event, setEvent] = useState({ data: [] });
  // FIXME: Костыльная проверка: если поле detections это {} и в нем есть поле type значит событие типа Пожар
  const isFireEvent = Boolean(event?.detections?.type);

  useEffect(() => {
    lunaCarsClient.extendedEvents.show(eventId)
      .then(({ data: { data } }) => setEvent(data));
  }, [eventId]);

  const goToScenario = (scenarioId) => {
    history.push(`/scenarios/${scenarioId}`);
  };

  const scenario = scenarioOptions.find(({ value }) => event.scenarioId === value);
  const scenarioIndicatorType = scenarioIndicatorTypeOptions.find(({ value: id }) => event.indicatorTypeId === id);

  const buildCountryLabel = ({ countryId, features }) => {
    let countryLabel = countryOptions.find(({ value: id }) => countryId === id)?.label;
    if (!countryLabel) return '-';

    const licensePlateFeature = features?.find(({ type }) => type === 'rus_spec_type' || type === 'emirate_name')?.title;

    // Костыльно проверяем, зашит ли тип номера в название страны
    if (licensePlateFeature && countryLabel.indexOf('(') === -1) {
      countryLabel = `${countryLabel} (${licensePlateFeature})`;
    }

    return countryLabel;
  };

  const onUserNotesSubmit = async (userNotes) => {
    await updateExtendedEventUserNotes(eventId, { userNotes });
    setEvent({ ...event, userNotes });
  };

  const bestshot = event?.vehicleBestshots?.front?.[0];

  const buildPlateInfo = ({ label, plateInfo }) => {
    return (
      <Meta
        label={label}
        pairs={[
          {
            value: buildCountryLabel({
              countryId: plateInfo.countryId,
              features: plateInfo.features,
            }),
            accuracy: plateInfo.countryAccuracy,
          },
          {
            value: plateInfo.symbols,
            accuracy: plateInfo.accuracy,
          },
        ]}
      />
    );
  };

  const buildImageBlock = ({ label, plateInfo }) => {
    return (
      <div className="ExtendedEventPage__Plate">
        <div className="ExtendedEventPage__Plate_number">
          <Meta label={t(label)} value={`${plateInfo.symbols} {${CLASS_TYPE_OPTIONS[plateInfo.classType]?.label}}`} accuracyValue={plateInfo.accuracy} />
          {plateInfo.countryId && (
            <Meta
              label={t('Страна')}
              value={buildCountryLabel({
                countryId: plateInfo.countryId,
                features: plateInfo.licensePlateFeature,
              })}
              accuracyValue={plateInfo.countryAccuracy}
            />
          )}
        </div>
        <div className="ExtendedEventPage__Plate_imageContainer">
          <img
            className="ExtendedEventPage__Plate_image"
            src={plateInfo.image}
            alt="fire event img"
          />
        </div>
      </div>
    );
  };

  return (
    <Page breadcrumbs={[
      { caption: t('Расширенные события'), link: '/extended-events' },
      { caption: eventId },
    ]}
    >
      <div className="EventPage__Container">
        <Grid>
          <GridRow>
            <GridCol cols={6}>
              <PanelBlock title={t('Общие')}>
                <div className="EventPage__SubContainer">
                  {event.id && (
                    <Meta
                      label={t('ID')}
                      value={event.id}
                    />
                  )}
                  {event.cam && (
                    <Meta
                      label={t('Камера')}
                      value={event.cam?.name}
                    />
                  )}
                  {event.entryTime && (
                    <Meta
                      label={t('Дата входа')}
                      value={parseISO(event.entryTime).toLocaleString()}
                    />
                  )}
                  {event.exitTime && (
                    <Meta
                      label={t('Дата выхода')}
                      value={parseISO(event.exitTime).toLocaleString()}
                    />
                  )}
                  <Meta
                    label={t('Направление')}
                    value={JSON.stringify(event.direction)}
                  />
                  {event.scenarioId && (
                    <Meta
                      label={t('Сценарий')}
                      value={(can(role, 'scenarios:read')
                        ? (
                          <div
                            className="EventPage__Link"
                            onClick={() => goToScenario(event.scenarioId)}
                          >
                            {scenario?.label || ''}
                          </div>
                        )
                        : scenario?.label || '')}
                    />
                  )}
                  {event.scenarioId && (
                    <Meta
                      label={t('Тип сценария')}
                      value={SCENARIO_TYPE_OPTIONS[scenario?.scenarioType]?.label}
                    />
                  )}
                  {event.indicatorTypeId && (
                    <Meta
                      label={event.type === 'event' ? t('Тип события') : t('Тип инцидента')}
                      value={scenarioIndicatorType?.label}
                    />
                  )}
                  {event.scenarioId && scenario?.handlerTemplates.length > 0 && (
                    <Meta
                      label={t('Обработчик')}
                      value={scenario?.handlerTemplates?.map((handler) => (
                        <div key={handler}>
                          { handlerTypeOptions.find(({ value }) => value === handler)?.label }
                        </div>
                      ))}
                    />
                  )}
                  {event.vehicleTrackId && (
                    <Meta
                      label={t('Track ID')}
                      value={event.vehicleTrackId}
                    />
                  )}
                  {event.frameId >= 0 && (
                    <Meta
                      label={t('Frame ID')}
                      value={event.frameId}
                    />
                  )}
                  <Meta
                    label={t('Комментарий')}
                    value={(
                      <CommentField
                        userNotes={event.userNotes}
                        onUserNotesSubmit={onUserNotesSubmit}
                      />
                    )}
                  />
                </div>
              </PanelBlock>

              <Divider />

              <PanelBlock title={t('Информация с передней камеры')}>
                <div className="EventPage__SubContainer">
                  {event.vehicleTypeId && (
                    <Meta
                      label={t('Тип')}
                      value={vehicleTypeOptions.find(({ value: id }) => event.vehicleTypeId === id)?.label}
                      accuracyValue={event.vehicleTypeAccuracy}
                    />
                  )}
                  {event.vehicleBrandId && (
                    <Meta
                      label={t('Марка')}
                      value={vehicleBrandOptions.find(({ value: id }) => event.vehicleBrandId === id)?.label}
                      accuracyValue={event.vehicleBrandAccuracy}
                    />
                  )}
                  {event.vehicleModelId && (
                    <Meta
                      label={t('Модель')}
                      value={vehicleModelOptions.find(({ value: id }) => event.vehicleModelId === id)?.label}
                      accuracyValue={event.vehicleModelAccuracy}
                    />
                  )}
                  {event.vehicleEmergencyTypeId && (
                    <Meta
                      label={t('Экстренная служба')}
                      value={vehicleEmergencyTypeOptions.find(({
                        value: id,
                      }) => event.vehicleEmergencyTypeId === id)?.label}
                      accuracyValue={event.vehicleEmergencyTypeAccuracy}
                    />
                  )}
                  {event.vehicleColorTypeId && (
                    <Meta
                      label={t('Тип цвета')}
                      value={vehicleColorTypeOptions.find(({ value: id }) => event.vehicleColorTypeId === id)?.label}
                      accuracyValue={event.vehicleColorTypeAccuracy}
                    />
                  )}
                  {event.vehicleColors?.length > 0 && (
                  <Meta
                    label={t('Цвет')}
                    pairs={event.vehicleColors.map((value) => ({
                      value: vehicleColorOptions.find(({ value: id }) => value.vehicleColorId === id)?.label,
                      accuracy: value.vehicleColorAccuracy,
                    }))}
                  />
                  )}
                  {(event.vehicleLicensePlates && event.vehicleLicensePlates.front?.length) && (
                    <>
                      {buildPlateInfo({
                        label: t('ГРЗ спереди'),
                        plateInfo: event.vehicleLicensePlates.front?.[0],
                      })}
                    </>
                  )}
                  {event.vehicleAxlesAmount > 0 && (
                    <Meta
                      label={t('Количество осей')}
                      value={event.vehicleAxlesAmount}
                      accuracyValue={event.vehicleAxlesAccuracy}
                    />
                  )}
                  {event.publicTransportTypeId && (
                    <Meta
                      label={t('Общ. транспорт')}
                      value={publicTransportTypeOptions.find(({
                        value: id,
                      }) => event.publicTransportTypeId === id)?.label}
                      accuracyValue={event.publicTransportTypeAccuracy}
                    />
                  )}
                  {event.specialTransportTypeId && (
                    <Meta
                      label={t('Спец. техника')}
                      value={specialTransportTypeOptions.find(({
                        value: id,
                      }) => event.specialTransportTypeId === id)?.label}
                      accuracyValue={event.specialTransportTypeAccuracy}
                    />
                  )}
                  {event.numberOfObjects && (
                    <Meta
                      label={t('Количество ТС')}
                      value={event.numberOfObjects}
                    />
                  )}
                  {isFireEvent && (
                    <>
                      {event?.detections?.meta?.fire !== undefined && <Meta label={t('Огонь')} value={event.detections.meta.fire} />}
                      {event?.detections?.meta?.blackSmoke !== undefined && <Meta label={t('Черный дым')} value={event.detections.meta.blackSmoke} />}
                      {event?.detections?.meta?.whiteSmoke !== undefined && <Meta label={t('Белый дым')} value={event.detections.meta.whiteSmoke} />}
                      {event?.detections?.meta?.noFire !== undefined && <Meta label={t('Нет возгорания')} value={event.detections.meta.noFire} />}
                    </>
                  )}
                  {event.cameraStatus && (
                    <Meta
                      label={t('Статус камеры')}
                      value={event.cameraStatus}
                    />
                  )}
                </div>
              </PanelBlock>

              <Divider />

              <PanelBlock title={t('Информация с задней камеры')}>
                <div className="EventPage__SubContainer">
                  {event.backVehicleTypeId && (
                    <Meta
                      label={t('Тип')}
                      value={vehicleTypeOptions.find(({ value: id }) => event.backVehicleTypeId === id)?.label}
                      accuracyValue={event.backVehicleTypeAccuracy}
                    />
                  )}
                  {event.backVehicleBrandId && (
                    <Meta
                      label={t('Марка')}
                      value={vehicleBrandOptions.find(({ value: id }) => event.backVehicleBrandId === id)?.label}
                      accuracyValue={event.backVehicleBrandAccuracy}
                    />
                  )}
                  {event.backVehicleModelId && (
                    <Meta
                      label={t('Модель')}
                      value={vehicleModelOptions.find(({ value: id }) => event.backVehicleModelId === id)?.label}
                      accuracyValue={event.backVehicleModelAccuracy}
                    />
                  )}
                  {(event.vehicleLicensePlates && event.vehicleLicensePlates.back?.length > 0) && (
                    <>
                      {buildPlateInfo({
                        label: t('ГРЗ сзади'),
                        plateInfo: event.vehicleLicensePlates.back?.[0],
                      })}
                    </>
                  )}
                  {event.backSpecialTransportTypeId && (
                    <Meta
                      label={t('Спец. техника')}
                      value={specialTransportTypeOptions.find(({
                        value: id,
                      }) => event.backSpecialTransportTypeId === id)?.label}
                      accuracyValue={event.backSpecialTransportTypeAccuracy}
                    />
                  )}
                  <Meta
                    label={t('Принудительный exit')}
                    value={JSON.stringify(event.hasMissedTrackEnd)}
                  />
                </div>
              </PanelBlock>

              {event.hasTrailer && (
                <>
                  <Divider />
                  <PanelBlock title={t('Информация о прицепе')}>
                    <div className="EventPage__SubContainer">
                      {event.vehicleLicensePlates && (
                        <>
                          {buildPlateInfo({
                            label: t('ГРЗ прицепа'),
                            plateInfo: event.vehicleLicensePlates.trailer?.[0],
                          })}
                        </>
                      )}
                    </div>
                  </PanelBlock>
                </>
              )}

              <Divider />

              <PanelBlock title={t('Регистрационные знаки')}>
                <div className="EventPage__SubContainer">
                  {event.vehicleLicensePlates?.front?.map((plate) => (
                    <>
                      {buildImageBlock({
                        label: t('ГРЗ спереди'),
                        plateInfo: plate,
                      })}
                      <div className="EventPage__Border" />
                    </>
                  ))}
                  {event.vehicleLicensePlates?.back?.map((plate) => (
                    <>
                      {buildImageBlock({
                        label: t('ГРЗ сзади'),
                        plateInfo: plate,
                      })}
                      <div className="EventPage__Border" />
                    </>
                  ))}
                  {event.hasTrailer && event.vehicleLicensePlates?.trailer?.map((plate) => (
                    <>
                      {buildImageBlock({
                        label: t('ГРЗ прицепа'),
                        plateInfo: plate,
                      })}
                      <div className="EventPage__Border" />
                    </>
                  ))}
                </div>
              </PanelBlock>

              {event.speeds?.length > 0 && (
                <>
                  <Divider />
                  <PanelBlock title={t('Скорость по полосам')}>
                    <div className="EventPage__SubContainer">
                      {event.speeds.map(({ title, speed }) => (
                        <Meta label={title} value={`${speed} ${t('км/ч')}`} />
                      ))}
                    </div>
                  </PanelBlock>
                </>
              )}
            </GridCol>
            <GridCol cols={6}>
              <PanelBlock title={t('Кадры')}>
                {event.fragmentGroups?.map(({ id, label, fragments }) => (
                  <ExtendedEventFrames
                    id={id}
                    label={label}
                    fragments={fragments}
                    type={scenarioIndicatorType?.label}
                    key={id}
                  />
                ))}
                {event.vehicleBestshots && (
                  ['front', 'back', 'side', 'trailer'].map((side) => {
                    const sideName = t(`events:bestshot view.${side}`);

                    return (
                      <div className="ExtendedFrames">
                        {event.vehicleBestshots[side].length > 0 && (
                          <h6 className="ExtendedFrames__Title">
                            {sideName}
                          </h6>
                        )}

                        <div className="ExtendedFrames__Shots">
                          {/* eslint-disable-next-line max-len */}
                          {event.vehicleBestshots[side].length > 0 && event.vehicleBestshots[side].map((bestshotUrl, i) => (
                            <img
                              className="ExtendedFrames__Shot"
                              src={bestshotUrl}
                              alt={`${sideName} ${i + 1}`}
                            />
                          ))}
                        </div>
                      </div>
                    );
                  })
                )}
                {bestshot && event.objectType === 'fire' && (
                  <img
                    className="EventPage__FireEventImage"
                    src={event.bestshot}
                    alt="fire event img"
                  />
                )}
                {(!event.fragmentGroups?.length && !event.vehicleBestshots) && (
                  <>
                    <div className="EventPage__EmptyContent">
                      {t('Кадры отсутствуют')}
                    </div>
                    <Divider small />
                  </>
                )}
              </PanelBlock>
            </GridCol>
          </GridRow>
        </Grid>
      </div>
    </Page>
  );
};

const $ExtendedEventPage = connect((state) => ({
  ...selectVehicleAttributes(state),
  scenarioOptions: selectScenarioOptions(state),
  role: selectAccountRole(state),
}))(ExtendedEventPage);

export {
  $ExtendedEventPage as ExtendedEventPage,
};
