import React from 'react';

import i18next from 'i18next';
import { useSelector } from 'react-redux';

import { selectVehicleAttributes } from 'store/vehicle/selectors';

import './InfoCell.sass';

const fireOptions = {
  fire: i18next.t('Огонь'),
  blackSmoke: i18next.t('Черный дым'),
  whiteSmoke: i18next.t('Белый дым'),
};

const InfoCell = (
  {
    value: {
      vehicleBestshot,
      vehicleBestshots,
      vehicleBrandAccuracy,
      vehicleBrandId,
      vehicleColors,
      vehicleModelAccuracy,
      vehicleModelId,
      vehicleTypeAccuracy,
      vehicleTypeId,
      vehicleLicensePlate,
      vehicleLicensePlates,
      vehicleLicensePlateAccuracy,
      vehicleLicensePlateBestshot,
      vehicleEmergencyTypeId,
      vehicleEmergencyTypeAccuracy,
      licensePlateFeatures,
      numberOfObjects,
      detections,
    },
  },
) => {
  const {
    countryOptions,
    vehicleBrandOptions,
    vehicleColorOptions,
    vehicleModelOptions,
    vehicleTypeOptions,
    vehicleEmergencyTypeOptions,
  } = useSelector(selectVehicleAttributes);

  // FIXME: Костыльная проверка: если поле detections это {} и в нем есть поле type значит событие типа Пожар
  const fireEventType = detections?.type && fireOptions[detections.type];
  const vehicleBrand = vehicleBrandId && vehicleBrandOptions.find(({ value: id }) => vehicleBrandId === id);
  const vehicleModel = vehicleModelId && vehicleModelOptions.find(({ value: id }) => vehicleModelId === id);
  const vehicleType = vehicleTypeId && vehicleTypeOptions.find(({ value: id }) => vehicleTypeId === id);
  const vehicleColor = vehicleColors?.length > 0 && vehicleColors.map((color) => ({
    label: vehicleColorOptions.find(({ value: id }) => color.vehicleColorId === id)?.label,
    accuracy: color.vehicleColorAccuracy,
  }));
  const vehicleCountry = vehicleLicensePlates?.[0]?.countryId
    && countryOptions.find(({ value: id }) => vehicleLicensePlates?.[0]?.countryId === id);
  const vehicleEmergencyType = vehicleEmergencyTypeId
    && vehicleEmergencyTypeOptions.find(({ value: id, code }) => vehicleEmergencyTypeId === id && code !== 'not_emergency');
  const licensePlateFeature = licensePlateFeatures?.find(({ type }) => type === 'rus_spec_type' || type === 'emirate_name');

  const bestshot = vehicleBestshot || vehicleBestshots?.[0];

  const extendedLicensePlateInfo = vehicleLicensePlates?.[0];

  return (
    <div className="IngoCellWrapper">
      <div className="ImageContainer">
        <img className="Image" src={bestshot} alt="" />
      </div>

      <div className="InfoAttributes">
        {vehicleEmergencyType && <div>{`${vehicleEmergencyType?.label} - ${vehicleEmergencyTypeAccuracy}`}</div>}
        {vehicleTypeId && <div>{`${vehicleType?.label} - ${vehicleTypeAccuracy}`}</div>}
        {vehicleBrandId && <div>{`${vehicleBrand?.label} - ${vehicleBrandAccuracy}`}</div>}
        {vehicleModelId && <div>{`${vehicleModel?.label} - ${vehicleModelAccuracy}`}</div>}
        {vehicleColors?.length > 0 && vehicleColor.map((color) => (<div>{`${color?.label} - ${color.accuracy}`}</div>))}
        {numberOfObjects && <div>{`${i18next.t('Количество ТС')} - ${numberOfObjects}`}</div>}
        {fireEventType && <div>{fireEventType}</div>}
      </div>

      <div className="ImageContainer">
        <img className="ImagePlate" src={vehicleLicensePlateBestshot || extendedLicensePlateInfo?.image} alt="" />
      </div>

      <div className="InfoAttributes">
        {extendedLicensePlateInfo && (
          <div>{`${extendedLicensePlateInfo.symbols} - ${extendedLicensePlateInfo.accuracy}`}</div>
        )}

        {vehicleLicensePlate && (
          <div>{`${vehicleLicensePlate} - ${vehicleLicensePlateAccuracy}`}</div>
        )}

        {vehicleLicensePlates?.[0]?.countryId && (
          <div>
            {`${vehicleCountry?.label} ${licensePlateFeature?.title ? `(${licensePlateFeature.title}) ` : ''}- ${vehicleLicensePlates?.[0]?.countryAccuracy}`}
          </div>
        )}
      </div>
    </div>
  );
};
export default InfoCell;
