import { i18n as uikitI18n } from '@vlabs/uikit';

import { api } from 'api-bindings/i18n';

import carsApi from './carsApi.json';
import events from './events.json';

export default {
  events,
  carsApi,
  ...uikitI18n.ru,
  ...api.ru,
};
