import React, { useEffect } from 'react';

import {
  Grid, GridCol, GridRow, Fold, Table, Divider,
  TableCells, openConfirmPopup,
} from '@vlabs/uikit';
import qs from 'qs';
import { FormProvider, useForm } from 'react-hook-form';
import { useTranslation } from 'react-i18next';
import { connect } from 'react-redux';
import { useHistory, useParams } from 'react-router-dom';
import { toast } from 'react-toastify';

import { selectFullCamOptions } from 'features/cams/selectors';
import { selectListOptions } from 'features/lists/selectors';
import { selectCurrentScenario, selectHandlers } from 'features/scenarios/selectors';
import { selectVehicleAttributes } from 'store/vehicle/selectors';

import { lunaCarsClient } from 'api';
import { SCENARIO_TYPE_OPTIONS } from 'api-bindings/luna-cars/constants';
import ScenarioForm from 'features/scenarios/ScenarioForm';
import {
  updateScenarioOption, createScenarioOption, setHandlers, setCurrentScenario,
} from 'features/scenarios/scenariosSlice';
import { actionColumnProps } from 'utils/helpers';

function ScenarioPage({
  scenarioIndicatorTypeOptions = undefined,
  handlerTypeOptions = [],
  listOptions = {},
  setHandlers = undefined,
  handlers = [],
  setCurrentScenario,
  currentScenario,
  createScenarioOption = undefined,
  updateScenarioOption = undefined,
}) {
  const { t } = useTranslation();
  const { scenarioId } = useParams();
  const history = useHistory();
  let toSave = true;

  const formMethods = useForm({
    defaultValues: currentScenario,
  });
  const {
    handleSubmit,
    reset,
  } = formMethods;
  useEffect(() => { reset(currentScenario); }, [currentScenario, reset]);

  const fetchHandlers = async () => {
    await lunaCarsClient.scenarios
      .showAllHandlers(scenarioId)
      .then(({ data }) => setHandlers(data));
  };

  useEffect(() => {
    if (scenarioId && scenarioId !== 'create' && scenarioIndicatorTypeOptions?.length) {
      lunaCarsClient.scenarios
        .show(scenarioId)
        .then(({ data: { data } }) => {
          setCurrentScenario({
            ...data,
            scenarioType: SCENARIO_TYPE_OPTIONS[data.scenarioType],
            indicatorTypeId: scenarioIndicatorTypeOptions.find(({ value }) => value === data.indicatorTypeId),
            lists: data?.lists?.map(({ listId, listTitle, isExcept }) => (
              { listId: { value: listId, label: listTitle }, isExcept }
            )),
          });
        });

      fetchHandlers();
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [scenarioId, scenarioIndicatorTypeOptions]);

  const onDeleteHandler = ({ id, index }) => {
    const message = (
      <span>
        {t('Вы уверены, что хотите удалить обработчик')}
        &nbsp;
        <strong>{id}</strong>
        ?
      </span>
    );

    const onConfirm = async () => {
      if (id) {
        await lunaCarsClient.scenarios.deleteHandler(id, scenarioId)
          .then(() => setHandlers({ data: handlers?.data?.filter(({ id: handlerId }) => handlerId !== id) }));
      } else {
        setHandlers({
          data: handlers?.data?.filter(({ index: handlerIndex }) => handlerIndex !== index)
            .map((handler, replaceIndex) => ({ ...handler, index: replaceIndex })),
        });
      }
    };

    openConfirmPopup({
      title: t('Удаление обработчика'),
      message,
      type: 'delete',
      onConfirm,
    });
  };

  const onFormSubmit = (values) => {
    if (!toSave) {
      setCurrentScenario(values);
      return;
    }

    const {
      scenarioType: { value: scenarioType },
      indicatorTypeId: { value: indicatorTypeId },
      lists,
    } = values;
    const formattedLists = lists?.map(({ listId: { value: listId }, isExcept }) => ({ listId, isExcept }));
    const data = {
      ...values, scenarioType, indicatorTypeId, lists: formattedLists,
    };

    if (scenarioId === 'create') {
      lunaCarsClient.scenarios.create(data).then(async ({ data: { data } }) => {
        if (handlers?.data?.length) {
          await Promise.all(handlers.data
            .map(({ camera, index, ...handler }) => lunaCarsClient.scenarios.createHandler(data.id, {
              camId: camera?.id,
              ...handler,
            })));
        }
        createScenarioOption(data);
        history.replace('/scenarios');
        toast.success(`${t('Сценарий успешно создан')}.`);
      })
        .catch(({ response }) => toast.error(`${t('Серверная ошибка')}: ${response.data}`));
    } else {
      lunaCarsClient.scenarios.update(scenarioId, data)
        .then(() => {
          updateScenarioOption(scenarioId, data);
          toast.success(`${t('Сценарий успешно обновлен')}.`);
        })
        .catch(({ response }) => toast.error(`${t('Серверная ошибка')}: ${response.data}`));
      fetchHandlers();
    }
  };

  const onShowHandlerForm = ({ handlerId = 'edit', index, scenarioTitle }) => {
    toSave = false;
    handleSubmit(onFormSubmit)();

    history.push({
      pathname: `/scenarios/${scenarioId}/handlers/${handlerId}`,
      search: qs.stringify({ index, scenarioTitle }),
      state: { handlerId },
    });
  };

  return (
    <>
      <FormProvider {...formMethods}>
        <form
          onSubmit={handleSubmit(onFormSubmit)}
          id="scenario-form"
        >
          <ScenarioForm
            scenarioTypeOptions={SCENARIO_TYPE_OPTIONS.raw}
            scenarioIndicatorTypeOptions={scenarioIndicatorTypeOptions}
            listOptions={listOptions}
          />
        </form>
      </FormProvider>
      <div style={{ padding: '0 32px' }}>
        <Divider small />
        <Grid>
          <GridRow>
            <GridCol cols={6}>
              <Fold title={t('Обработчики')} isOpen>
                <Table
                  {...handlers}
                  columns={[
                    { Header: t('Id'), accessor: 'id' },
                    {
                      Header: t('Обработчик'),
                      accessor: (row) => handlerTypeOptions?.find(({ value }) => value === row?.handlerTemplate)?.label,
                      width: 125,
                    },
                    { Header: t('Камера'), accessor: 'camera.name', width: 90 },
                    { Header: t('Комментарий'), accessor: 'comment', width: 140 },
                    {
                      Header: t('Статус'),
                      accessor: 'statusIsOn',
                      Cell: ({ value }) => (value ? t('Включен') : t('Отключен')),
                      width: 85,
                    },
                    actionColumnProps({ id: 'edit', Cell: TableCells.EditCell, width: 30 }),
                    actionColumnProps({ id: 'delete', Cell: TableCells.DeleteCell, width: 30 }),
                  ]}
                  onActions={{
                    onAddRow: {
                      handler: () => onShowHandlerForm({
                        handlerId: 'create', scenarioTitle: currentScenario.title,
                      }),
                    },
                    onEditRow: {
                      handler: ({ id, index }) => onShowHandlerForm({
                        handlerId: id, index, scenarioTitle: currentScenario.title,
                      }),
                    },
                    onDeleteRow: { handler: onDeleteHandler },
                  }}
                />
              </Fold>
            </GridCol>
          </GridRow>
        </Grid>
      </div>
    </>
  );
}

export default connect((state) => ({
  ...selectVehicleAttributes(state),
  listOptions: selectListOptions(state).listOptions,
  fullCamOptions: selectFullCamOptions(state),
  handlers: selectHandlers(state),
  currentScenario: selectCurrentScenario(state),
}),
(dispatch) => ({
  setHandlers: (handlers) => dispatch(setHandlers(handlers)),
  setCurrentScenario: (scenario) => dispatch(setCurrentScenario(scenario)),
  updateScenarioOption: (scenarioId, data) => dispatch(updateScenarioOption(scenarioId, data)),
  createScenarioOption: (scenario) => dispatch(createScenarioOption(scenario)),
}))(ScenarioPage);
