import {
  FACE_QUALITY_EYE,
  FACE_QUALITY_EYEBROWS_STATE,
  FACE_QUALITY_FACE_COLOR_TYPES,
  FACE_QUALITY_GLASSES,
  FACE_QUALITY_HEADWEAR_TYPE,
  FACE_QUALITY_IMAGE_FORMAT,
  FACE_QUALITY_SMILE_PROPERTIES,
  FACE_QUALITY_SHOULDERS_POSITION,
} from '../handlers/constants';

const optionsMap = {
  image_format: FACE_QUALITY_IMAGE_FORMAT,
  face_color_type: FACE_QUALITY_FACE_COLOR_TYPES,
  glasses: FACE_QUALITY_GLASSES,
  eyebrows_state: FACE_QUALITY_EYEBROWS_STATE,
  smile_properties: FACE_QUALITY_SMILE_PROPERTIES,
  left_eye: FACE_QUALITY_EYE,
  right_eye: FACE_QUALITY_EYE,
  headwear_type: FACE_QUALITY_HEADWEAR_TYPE,
  shoulders_position: FACE_QUALITY_SHOULDERS_POSITION,
};

const booleanValues = ['natural_light', 'radial_distortion', 'red_eyes'];
const valuesInDegrees = ['head_yaw', 'head_pitch', 'head_roll', 'gaze_yaw', 'gaze_pitch'];
const valuesInPercent = ['head_horizontal_center', 'head_vertical_center', 'head_width', 'head_height'];

const getValueType = (name, object_value) => {
  if (object_value === null) return 'default';
  if (booleanValues.includes(name)) return 'boolean';
  if (valuesInDegrees.includes(name)) return 'degrees';
  if (valuesInPercent.includes(name)) return 'percent';
  if (typeof object_value === 'number') return 'number';
  return 'default';
};

const getThresholdType = (name, threshold_value) => {
  if (Array.isArray(threshold_value)) return 'enum';
  if (valuesInPercent.includes(name)) return 'percent';
  if (name === 'eye_distance') return 'min';
  if (typeof threshold_value === 'object') return 'minMax';
  if (typeof threshold_value === 'number') return 'bool';
  return 'default';
};

export const checkSchema = (сheck) => {
  const { threshold_value, name, result, object_value } = сheck;
  const valueType = getValueType(name, object_value);
  const thresholdType = getThresholdType(name, threshold_value);

  return {
    name,
    result,
    valueType,
    thresholdType,
    object_value: (Array.isArray(threshold_value) && object_value !== null)
      ? optionsMap?.[name]?.[object_value]
      : object_value,
    threshold_value: Object.keys(optionsMap)?.includes(name)
      ? threshold_value?.map((v) => optionsMap?.[name]?.[v])
      : threshold_value,
  };
};
