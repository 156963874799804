/* eslint-disable no-underscore-dangle */
/* eslint-disable camelcase */
import { loadProgressBar } from '@vlabs/axios-progress-bar';
import Axios from 'axios';
import formatISO from 'date-fns/formatISO';
import qs from 'qs';

import '@vlabs/axios-progress-bar/dist/nprogress.css';

import { LunaCarsWSClient } from './LunaCarsWSClient';

const convertIntoFormData = (data) => {
  const formData = new FormData();
  Object.entries(data).forEach(([key, value]) => {
    if (value === undefined || value === '') return;
    if (typeof value === 'object' && !(value instanceof File)) {
      const blob = new Blob([JSON.stringify(value)], {
        type: 'application/json',
      });
      formData.append(key, blob);
      return;
    }
    formData.append(key, value);
  });
  return formData;
};

export class LunaCarsClient {
  constructor({
    baseURL = '/api',
  }) {
    this.setBaseURL(baseURL);
    this.wsUrl = 'ws/cars/1/0';
    loadProgressBar('', this.http);
  }

  setBaseURL(url) {
    if (url.startsWith('http://') || url.startsWith('https://')) {
      this.baseURL = url;
    } else this.baseURL = url.replace(/\/\/*/, '/');
    this.http = Axios.create({
      baseURL: this.baseURL,
      withCredentials: true,
      paramsSerializer: (params) => qs.stringify(params, {
        arrayFormat: 'comma',
        serializeDate: formatISO,
      }),
    });
  }

  get auth() {
    const prefix = 'auth';
    return {
      getCurrentAccount: () => this.http.get(`/${prefix}/me`),
      login: (login, password) => this.http.post(`/${prefix}/login`, { login, password }),
      logout: () => this.http.post(`/${prefix}/logout`),
      reset: (accountId) => this.http.post(`/${prefix}/reset`, { accountId }),
      resetPassword: (token, password) => this.http.post(`/${prefix}/reset/${token}`, { password }),
      forgotPassword: (email) => this.http.post(`/${prefix}/reset_password`, { email }),
    };
  }

  get accounts() {
    const prefix = 'accounts';
    return {
      showAll: (params) => this.http.get(`/${prefix}`, { params }),
      create: (data) => this.http.post(`/${prefix}`, data),
      show: (id) => this.http.get(`/${prefix}/${id}`),
      update: (id, data) => this.http.patch(`/${prefix}/${id}`, data),
      delete: (id) => this.http.delete(`/${prefix}/${id}`),
      exists: (params) => this.http.get(`/${prefix}/exists`, { params }),
    };
  }

  get events() {
    const prefix = 'events';
    return {
      showAll: (params) => this.http.get(`/${prefix}`, { params }),
      show: (id) => this.http.get(`/${prefix}/${id}`),
      update: (id, data) => this.http.patch(`/${prefix}/${id}`, data),
    };
  }

  get extendedEvents() {
    const prefix = 'extendedEvents';
    return {
      showAll: (params) => this.http.get(`/${prefix}`, { params }),
      show: (id) => this.http.get(`/${prefix}/${id}`),
      update: (id, data) => this.http.patch(`/${prefix}/${id}`, data),
    };
  }

  get incidents() {
    const prefix = 'incidents';
    return {
      showAll: (params) => this.http.get(`/${prefix}`, { params }),
      show: (id) => this.http.get(`/${prefix}/${id}`),
      update: (id, data) => this.http.patch(`/${prefix}/${id}`, data),
      read: (id) => this.http.post(`/${prefix}/${id}/read`),
    };
  }

  get tasks() {
    const prefix = 'tasks';
    return {
      showAll: (params) => this.http.get(`/${prefix}`, { params }),
      create: (data) => this.http.post(`/${prefix}`, data),
      show: (id) => this.http.get(`/${prefix}/${id}`),
      showResult: (id, params) => this.http.get(`/${prefix}/${id}/results`, {
        params, responseType: 'json',
      }),
      downloadUrl: (id, params) => `${this.baseURL}/${prefix}/${id}/results?${qs.stringify(params)}`,
      delete: (id) => this.http.delete(`/${prefix}/${id}`),
      cancel: (id) => this.http.post(`/${prefix}/${id}/cancel`),
    };
  }

  get lists() {
    const prefix = 'lists';
    return {
      showAll: (params) => this.http.get(`/${prefix}`, { params }),
      show: (listId) => this.http.get(`/${prefix}/${listId}`),
      create: (payload) => this.http.post(`/${prefix}`, payload),
      update: (listId, payload) => this.http.patch(`/${prefix}/${listId}`, payload),
      delete: (listId) => this.http.delete(`/${prefix}/${listId}`),

      showAllItems: (id, params) => this.http.get(`/${prefix}/${id}/items`, { params }),
      showItem: (listId, id) => this.http.get(`/${prefix}/${listId}/items/${id}`),
      createItem: (listId, data) => this.http.post(`/${prefix}/${listId}/items`, data),
      updateItem: (listId, id, data) => this.http.patch(`/${prefix}/${listId}/items/${id}`, data),
      deleteItem: (listId, id) => this.http.delete(`/${prefix}/${listId}/items/${id}`),

      deleteMany: (list_ids, params) => this.http.delete(`/${prefix}`, { params, data: { list_ids } }),
      updateFaces: (listId, payload) => this.http.patch(`/${prefix}/${listId}/faces`, payload),
    };
  }

  get cams() {
    const prefix = 'cams';
    return {
      showAll: (params) => this.http.get(`/${prefix}`, { params }),
      create: (data) => this.http.post(
        `/${prefix}`,
        convertIntoFormData(data),
        { headers: { 'Content-type': 'multipart/form-data' } },
      ),
      show: (id) => this.http.get(`/${prefix}/${id}`),
      update: (id, data) => this.http.patch(
        `/${prefix}/${id}`,
        convertIntoFormData(data),
        { headers: { 'Content-type': 'multipart/form-data' } },
      ),
      delete: (id) => this.http.delete(`/${prefix}/${id}`),
      restart: (id, data) => this.http.post(`/${prefix}/${id}/restart`, data),
      carsInstances: () => this.http.get(`/${prefix}/enabled_cs_instances`),
      anprInstances: () => this.http.get(`/${prefix}/enabled_as_instances`),
      readerInstances: () => this.http.get(`/${prefix}/enabled_reader_instances`),
      showAllRegions: (id) => this.http.get(`/${prefix}/${id}/regions`),
      createRegion: (id, data) => this.http.post(`/${prefix}/${id}/regions`, data),
      showRegion: (regionId, id) => this.http.get(`/${prefix}/${id}/regions/${regionId}`),
      updateRegion: (regionId, id, data) => this.http.patch(`/${prefix}/${id}/regions/${regionId}`, data),
      deleteRegion: (regionId, id) => this.http.delete(`/${prefix}/${id}/regions/${regionId}`),
      fetchPreview: (id) => this.http.post(`/${prefix}/${id}/stream-preview`),
      showParamTypesByID: (id) => this.http.get(`/${prefix}/${id}/param-types`),
      showParamTypesByType: (inputType) => this.http.get(`/${prefix}/${inputType}/param-types`),
    };
  }

  get scenarios() {
    const prefix = 'scenarios';
    return {
      showAll: (params) => this.http.get(`/${prefix}`, { params }),
      create: (data) => this.http.post(`/${prefix}`, data),
      show: (id) => this.http.get(`/${prefix}/${id}`),
      update: (id, data) => this.http.patch(`/${prefix}/${id}`, data),
      delete: (id) => this.http.delete(`/${prefix}/${id}`),
      showAllHandlers: (id, params) => this.http.get(`/${prefix}/${id}/handlers`, { params }),
      createHandler: (id, data) => this.http.post(`/${prefix}/${id}/handlers`, data),
      showHandler: (handlerId, id) => this.http.get(`/${prefix}/${id}/handlers/${handlerId}`),
      updateHandler: (handlerId, id, data) => this.http.patch(`/${prefix}/${id}/handlers/${handlerId}`, data),
      deleteHandler: (handlerId, id) => this.http.delete(`/${prefix}/${id}/handlers/${handlerId}`),
    };
  }

  get filters() {
    const prefix = 'filters';
    return {
      vehicleTypes: () => this.http.get(`/${prefix}/vehicletypes`),
      vehicleModels: () => this.http.get(`/${prefix}/vehiclemodels`),
      vehicleBrands: () => this.http.get(`/${prefix}/vehiclebrands`),
      vehicleColors: () => this.http.get(`/${prefix}/vehiclecolors`),
      vehicleColorTypes: () => this.http.get(`/${prefix}/vehiclecolortypes`),
      vehicleEmergencyTypes: () => this.http.get(`/${prefix}/vehicleemergencytypes`),
      countries: () => this.http.get(`/${prefix}/countries`),
      scenarioIndicatorTypes: () => this.http.get(`/${prefix}/scenarioindicatortypes`),
      handlerTypes: () => this.http.get(`/${prefix}/handlertypes`),
      publicTransportTypes: () => this.http.get(`/${prefix}/publictransporttypes`),
      specialTransportTypes: () => this.http.get(`/${prefix}/specialtransporttypes`),
      licensePlateFeatures: () => this.http.get(`/${prefix}/feature/licenseplate`),
    };
  }

  // eslint-disable-next-line class-methods-use-this
  get images() {
    const prefix = 'images';
    return {
      src: (id) => `/${prefix}/${id}`,
    };
  }

  get tmpImages() {
    const prefix = 'tmp-images';
    return {
      upload: (file) => {
        const formData = new FormData();
        formData.append('image', file);
        return this.http.post(`/${prefix}`, formData, {
          headers: {
            'Content-type': 'multipart/form-data',
          },
        });
      },
    };
  }

  get services() {
    const prefix = 'services';
    return {
      classifyImage: (data) => this.http.post(`/${prefix}/classify-image`, data),
    };
  }

  get settings() {
    const prefix = 'settings';
    return {
      showAll: () => this.http.get(`/${prefix}`),
    };
  }

  get exportResource() {
    const prefix = 'export';
    return {
      xlsx: {
        groups: async () => {
          const { data: { data } } = await this.http.get(`/${prefix}/xlsx/groups`);
          return data;
        },
        columns: async () => {
          const { data: { data } } = await this.http.get(`/${prefix}/xlsx/columns`);
          return data;
        },
      },
      export: (reqData) => this.http.put(`/${prefix}`, reqData),
    };
  }

  get wsEvents() {
    if (!this.wsSocketEvents) {
      this.wsSocketEvents = new LunaCarsWSClient(
        `/${this.wsUrl}/events`,
      );
    }

    return this.wsSocketEvents;
  }

  get wsExtendedEvents() {
    if (!this.wsSocketExtendedEvents) {
      this.wsSocketExtendedEvents = new LunaCarsWSClient(
        `/${this.wsUrl}/extended-events`,
      );
    }

    return this.wsSocketExtendedEvents;
  }

  get wsIncidents() {
    if (!this.wsSocketIncidents) {
      this.wsSocketIncidents = new LunaCarsWSClient(
        `/${this.wsUrl}/incidents`,
      );
    }

    return this.wsSocketIncidents;
  }

  createWSClient(url) {
    const wsUrl = `/${this.wsUrl}/${url}`;
    const wsClient = new LunaCarsWSClient(wsUrl);
    return wsClient;
  }
}
