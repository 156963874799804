import React, { useCallback, useState } from 'react';

import {
  Page, Panel, Table, TableCells, useTableHook, Modal, TableFilters, openConfirmPopup,
} from '@vlabs/uikit';
import { useTranslation } from 'react-i18next';
import { connect } from 'react-redux';
import 'react-toastify/dist/ReactToastify.css';

import { selectAccountRole } from 'features/accounts/selectors';

import { lunaCarsClient } from 'api';
import { PAGE_SIZE_OPTIONS, ROLE_OPTIONS } from 'api-bindings/luna-cars/constants';
import AccountForm from 'features/accounts/AccountForm';
import { can } from 'utils/can';
import { actionColumnProps } from 'utils/helpers';

const AccountsPage = ({
  role,
}) => {
  const { t } = useTranslation();
  const [modalIsOpen, setModalIsOpen] = useState(false);
  const [selectedAccount, setSelectedAccount] = useState(undefined);
  const closeModal = () => {
    setSelectedAccount(undefined);
    setModalIsOpen(false);
  };

  const onFetch = ({ page = 0, ...params }) => lunaCarsClient.accounts
    .showAll({ page: page + 1, ...params })
    .then(({ data }) => data);
  const tableProps = useTableHook(useCallback(onFetch, []));
  const onShowForm = (account) => {
    if (account) {
      setSelectedAccount({
        ...account,
        role: ROLE_OPTIONS[account.role],
      });
    }
    setModalIsOpen(true);
  };

  const onDeleteHandler = (account) => {
    const message = (
      <span>
        {`${t('Вы уверены, что хотите удалить пользователя')}`}
        &nbsp;
        <strong>{account.email}</strong>
        ?
      </span>
    );

    const onConfirm = async () => {
      await lunaCarsClient.accounts.delete(account.id);
      tableProps.onFetch();
    };
    openConfirmPopup({
      title: t('Удаление пользователя'),
      message,
      type: 'delete',
      onConfirm,
    });
  };

  const handleApiCall = () => {
    closeModal();
    tableProps.onFetch();
  };

  const onFormSubmit = ({ role: { value: role }, ...values }) => {
    const data = { role, ...values };
    if (selectedAccount) {
      lunaCarsClient.accounts.update(selectedAccount.id, data).then(handleApiCall);
    } else {
      lunaCarsClient.accounts.create(data).then(handleApiCall);
    }
    setSelectedAccount(undefined);
  };
  const onPasswordReset = (accountId) => {
    lunaCarsClient.auth.reset(accountId).then(closeModal);
  };
  return (
    <Page>
      <Modal
        isOpen={modalIsOpen}
        onRequestClose={closeModal}
        appElement={document.getElementById('root')}
      >
        <AccountForm
          roleOptions={ROLE_OPTIONS.raw}
          onSubmit={onFormSubmit}
          onPasswordReset={selectedAccount ? () => onPasswordReset(selectedAccount.id) : undefined}
          isCreating={!!selectedAccount}
          {...selectedAccount}
        />
      </Modal>
      <Panel>
        <Table
          {...tableProps}
          paginationType="offsetBased"
          pageSizeOptions={PAGE_SIZE_OPTIONS}
          columns={[
            { Header: t('ФИО'), accessor: 'name', Filter: TableFilters.TextFilter() },
            { Header: t('Логин'), accessor: 'email', Filter: TableFilters.TextFilter() },
            {
              Header: t('Роль'),
              Filter: TableFilters.OneOfFilter({ options: ROLE_OPTIONS.raw }),
              accessor: 'role',
              Cell: ({ value: userRole }) => {
                const roleOption = ROLE_OPTIONS[userRole];
                return roleOption ? roleOption.label : userRole;
              },
            },
            actionColumnProps({ id: 'edit', Cell: TableCells.EditCell }),
            actionColumnProps({ id: 'delete', Cell: TableCells.DeleteCell }),
          ]}
          onActions={{
            onClickRow: { handler: onShowForm, can: () => can(role, 'accounts:read') },
            onAddRow: { handler: onShowForm, can: () => can(role, 'accounts:create') },
            onEditRow: { handler: onShowForm, can: () => can(role, 'accounts:update') },
            onDeleteRow: { handler: onDeleteHandler, can: () => can(role, 'accounts:delete') },
          }}
        />
      </Panel>
    </Page>
  );
};

export default connect((state) => ({
  role: selectAccountRole(state),
}))(AccountsPage);
