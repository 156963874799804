/* eslint-disable camelcase */

export function selectElementsEvenly(items, count) {
  if (!items) return null;
  if (items.length <= count) return items.map((_, idx) => idx);

  const step = (items.length - 1) / (count - 1);
  const result = [];
  for (let i = 0; i < count; i++) {
    result.push(Math.round(step * i));
  }

  return result;
}

const getTopMatch = (event) => {
  if (!event?.top_match) return null;
  const topMatchObjectId = event.top_match.face_id || event.top_match.event_id;
  // eslint-disable-next-line no-restricted-syntax
  for (const { candidates } of event.match_result) {
    // eslint-disable-next-line no-restricted-syntax
    for (const candidate of candidates) {
      if (
        candidate.face?.face_id === topMatchObjectId
        || candidate.event?.event_id === topMatchObjectId
      ) {
        return {
          ...event.top_match,
          user_data: candidate.face?.user_data || candidate?.event?.user_data,
          external_id: candidate.face?.external_id || candidate?.event?.external_id,
        };
      }
    }
  }
  return null;
};

export const findFullFrameDetectionId = (detections, defaultValue) => {
  if (detections?.length === undefined || detections.length === 0) return null;

  const detectionWithFullFrame = detections.findIndex(({ image_origin }) => {
    if (!image_origin) return false;
    return !image_origin.startsWith('/6/samples');
  });

  if (detectionWithFullFrame === -1) {
    return defaultValue;
  }
  return detectionWithFullFrame;
};

export const eventToEvent = (lunaClient) => (event) => {
  const body_detections = event?.body_detections?.map((detection) => ({
    ...detection,
    uiSampleURL: lunaClient.samples.getURL('bodies', detection.sample_id),
  })) || null;

  const uiMainBodyDetectionId = findFullFrameDetectionId(
    body_detections,
    body_detections?.length !== undefined
      ? Math.floor(body_detections.length / 2)
      : undefined,
  );
  let uiMainBodyDetection = null;
  if (uiMainBodyDetectionId !== null) uiMainBodyDetection = body_detections[uiMainBodyDetectionId];

  const face_detections = event?.face_detections?.map((detection) => ({
    ...detection,
    uiSampleURL: lunaClient.samples.getURL('faces', detection?.sample_id),
  })) || null;

  const uiMainFaceDetectionId = findFullFrameDetectionId(face_detections, 0);
  let uiMainFaceDetection = null;
  if (uiMainFaceDetectionId !== null) uiMainFaceDetection = face_detections[uiMainFaceDetectionId];

  let uiFullFrame;
  if (uiMainFaceDetectionId !== null
    && face_detections[uiMainFaceDetectionId]?.image_origin
    && !face_detections[uiMainFaceDetectionId].image_origin.startsWith('/6/samples')) {
    uiFullFrame = lunaClient.images.getURL(face_detections[uiMainFaceDetectionId].image_origin);
  } else if (uiMainBodyDetectionId !== null
    && body_detections[uiMainBodyDetectionId].image_origin
    && !body_detections[uiMainBodyDetectionId].image_origin.startsWith('/6/samples')) {
    uiFullFrame = lunaClient.images.getURL(body_detections[uiMainBodyDetectionId].image_origin);
  }

  return {
    ...event,
    face_detections,
    body_detections,
    uiMainFaceDetectionId,
    uiMainBodyDetectionId,
    // FIXME: Перейти на detections + mainDetectionId
    uiMainFaceDetection,
    uiMainBodyDetection,
    uiFullFrame,
    uiFaceTrack: selectElementsEvenly(face_detections, 7),
    uiBodyTrack: selectElementsEvenly(body_detections, 7),
    top_match: getTopMatch(event),
    uiTags: event.tags?.length > 0 ? event.tags.join(', ') : null,
  };
};
