import React from 'react';

import cn from 'classnames';

import './Bestshot.sass';

export default (imageUri, type) => imageUri && (
<a
  href={imageUri}
  target="_blank"
  rel="noopener noreferrer"
>
  <img
    className={cn({
      BestshotCell: true,
      [`BestshotCell_${type}`]: type,
    })}
    alt="Bestshot"
    key={imageUri}
    src={imageUri}
  />
</a>
);
