/* eslint-disable no-unused-vars */
/* eslint-disable consistent-return */
import React, { useState } from 'react';

import {
  FilterIcon, CrossIcon, ArrowStraightLeftIcon, CameraIcon, DownloadIcon, FileTextIcon,
} from '@vlabs/icons';
import {
  Page, Control, Margin, Divider, Modal, Tooltip,
} from '@vlabs/uikit';
import cn from 'classnames';
import { useForm } from 'react-hook-form';
import { useTranslation } from 'react-i18next';
import { toast } from 'react-toastify';

import { lunaCarsClient } from 'api';
import SettingFilter from 'components/setting-filter/SettingFilter';
import { can } from 'utils/can';
import {
  integerValidatorHookForm as integerValidator,
  LP_PATTERN, UUID_PATTERN,
} from 'utils/helpers';

import { filterResetValues } from '../constants';
import AccuracyForm from './AccuracyForm';
import ClassifyImageForm from './ClassifyImageForm';
import st from './EventsFilterForm.module.sass';
import ExportForm from './ExportForm';
import SearchByImageForm from './SearchByImageForm';

const excludedFieldsInitialValues = {
  vehicleTypeId: false,
  vehicleBrandId: false,
  vehicleModelId: false,
  vehicleColorId: false,
  vehicleEmergencyTypeId: false,
  publicTransportTypeId: false,
  specialTransportTypeId: false,
  indicatorTypeId: false,
  scenarioId: false,
  countryId: false,
  camId: false,
  listId: false,
};

function EventsFilterForm({
  vehicleTypeId = [],
  vehicleTypeIdExclude = [],
  vehicleTypeOptions = [],
  vehicleTypeAccuracyGte = '',
  vehicleTypeAccuracyLte = '',
  vehicleBrandId = [],
  vehicleBrandIdExclude = [],
  vehicleBrandOptions = [],
  vehicleBrandAccuracyGte = '',
  vehicleBrandAccuracyLte = '',
  vehicleModelId = [],
  vehicleModelIdExclude = [],
  vehicleModelOptions = [],
  vehicleModelAccuracyGte = '',
  vehicleModelAccuracyLte = '',
  vehicleColorId = [],
  vehicleColorIdExclude = [],
  vehicleColorOptions = [],
  vehicleColorAccuracyGte = '',
  vehicleColorAccuracyLte = '',
  vehicleEmergencyTypeId = [],
  vehicleEmergencyTypeIdExclude = [],
  vehicleEmergencyTypeOptions = [],
  vehicleEmergencyTypeAccuracyGte = '',
  vehicleEmergencyTypeAccuracyLte = '',
  publicTransportTypeId = [],
  publicTransportTypeIdExclude = [],
  publicTransportTypeOptions = [],
  publicTransportTypeAccuracyGte = '',
  publicTransportTypeAccuracyLte = '',
  specialTransportTypeId = [],
  specialTransportTypeIdExclude = [],
  specialTransportTypeOptions = [],
  specialTransportTypeAccuracyGte = '',
  specialTransportTypeAccuracyLte = '',
  indicatorTypeId = [],
  indicatorTypeIdExclude = [],
  scenarioIndicatorTypeOptions = [],
  scenarioId = [],
  scenarioIdExclude = [],
  scenarioOptions = [],
  countryId = [],
  countryIdExclude = [],
  countryOptions = [],
  countryAccuracyGte = '',
  countryAccuracyLte = '',
  vehicleLicensePlate = '',
  vehicleLicensePlateAccuracyGte = '',
  vehicleLicensePlateAccuracyLte = '',
  vehicleAxlesAmount = '',
  vehicleAxlesAccuracyGte = '',
  vehicleAxlesAccuracyLte = '',
  camId = [],
  camIdExclude = [],
  camOptions = [],
  listId = [],
  listIdExclude = [],
  listOptions = [],
  registeredAfter = '',
  registeredBefore = '',
  recordId = '',
  recordIdExclude = '',
  imageId = '',
  imageAccuracyGte = '',
  imageAccuracyLte = '',
  onFilter = undefined,
  onCreateTask = undefined,
  onExport = undefined,
  onReset = undefined,
  isVisible = true,
  setIsVisible = undefined,
  settings = {},
  role,
}) {
  const { t } = useTranslation();
  const defaultValues = {
    vehicleTypeId,
    vehicleTypeIdExclude,
    vehicleBrandId,
    vehicleBrandIdExclude,
    vehicleModelId,
    vehicleModelIdExclude,
    vehicleColorId,
    vehicleColorIdExclude,
    vehicleEmergencyTypeId,
    vehicleEmergencyTypeIdExclude,
    publicTransportTypeId,
    publicTransportTypeIdExclude,
    specialTransportTypeId,
    specialTransportTypeIdExclude,
    indicatorTypeId,
    indicatorTypeIdExclude,
    scenarioId,
    scenarioIdExclude,
    countryId,
    countryIdExclude,
    vehicleLicensePlate,
    vehicleAxlesAmount,
    camId,
    camIdExclude,
    listId,
    listIdExclude,
    registeredAfter,
    registeredBefore,
    recordId,
    recordIdExclude,
    imageId,
  };
  const { handleSubmit, watch, getValues, reset: resetForm, register, control, formState: { errors } } = useForm({
    defaultValues,
    reValidateMode: 'onChange',
  });
  const [accuracyModalIsOpen, setAccuracyModalIsOpen] = useState(false);
  const [searchByImageModalIsOpen, setSearchByImageModalIsOpen] = useState(false);
  const [classifyImageModalIsOpen, setClassifyImageModalIsOpen] = useState(false);
  const [exportFormIsOpen, setExportFormIsOpen] = useState(false);
  const [selectedField, setSelectedField] = useState(undefined);
  const [image, setImage] = useState(undefined);
  const [imageInfo, setImageInfo] = useState({});
  const [accuracies, setAccuracies] = useState({
    vehicleTypeAccuracyGte,
    vehicleTypeAccuracyLte,
    vehicleBrandAccuracyGte,
    vehicleBrandAccuracyLte,
    vehicleModelAccuracyGte,
    vehicleModelAccuracyLte,
    vehicleColorAccuracyGte,
    vehicleColorAccuracyLte,
    vehicleEmergencyTypeAccuracyGte,
    vehicleEmergencyTypeAccuracyLte,
    publicTransportTypeAccuracyGte,
    publicTransportTypeAccuracyLte,
    specialTransportTypeAccuracyGte,
    specialTransportTypeAccuracyLte,
    countryAccuracyGte,
    countryAccuracyLte,
    vehicleLicensePlateAccuracyGte,
    vehicleLicensePlateAccuracyLte,
    vehicleAxlesAccuracyGte,
    vehicleAxlesAccuracyLte,
    imageAccuracyGte,
    imageAccuracyLte,
  });
  const [excludedFields, setExcludedFields] = useState(excludedFieldsInitialValues);
  const values = watch();

  const onShowAccuracyForm = (name, label) => {
    setSelectedField({ name, label });
    setAccuracyModalIsOpen(true);
  };

  const closeAccuracyModal = async () => {
    await setAccuracyModalIsOpen(false);
    setSelectedField(undefined);
  };

  const onAccuracyFormSubmit = async (field) => {
    setAccuracies({ ...accuracies, ...field });
    await closeAccuracyModal();
  };

  const onDeleteAccuracy = (field) => {
    setAccuracies({ ...accuracies, [`${field}Gte`]: '', [`${field}Lte`]: '' });
  };

  const onShowSearchByImageForm = () => {
    setSearchByImageModalIsOpen(true);
  };

  const onSearchByImageUpload = async (file) => {
    if (!file) return;
    const attributeId = await lunaCarsClient.tmpImages.upload(file.raw)
      .then(({ data: { data: { id } } }) => id)
      .catch(({ response: { status } }) => {
        if (status === 413) {
          toast.error(t('Размер загружаемого файла слишком большой'));
        }
        return '';
      });
    return attributeId;
  };
  const closeSearchByImageModal = async () => {
    await setSearchByImageModalIsOpen(false);
  };

  const onSearchByImageFormSubmit = async ({ image, imageInfo }) => {
    setImage(image);
    setImageInfo(imageInfo);
    await closeSearchByImageModal();
  };

  const onShowClassifyImageForm = () => { setClassifyImageModalIsOpen(true); };

  const onClassifyImageUpload = async (file) => {
    const { data } = await lunaCarsClient.services.classifyImage({ image: file.binary });
    return data?.data;
  };

  const closeClassifyImageModal = () => { setClassifyImageModalIsOpen(false); };

  const onClassifyImageFormSubmit = (data) => {
    resetForm({ ...getValues(), ...data });
    closeClassifyImageModal();
  };

  const closeExportForm = () => { setExportFormIsOpen(false); };

  const onExclude = ({ name, isExclude }) => {
    if (isExclude) resetForm({ ...getValues(), [name]: [], [`${name}Exclude`]: [] }); // [`${name}Exclude`]: getValues(name)
    if (!isExclude) resetForm({ ...getValues(), [`${name}Exclude`]: [], [name]: [] }); // [name]: getValues(`${name}Exclude`)
    setExcludedFields({ ...excludedFields, [name]: isExclude });
  };

  const $onReset = () => {
    resetForm(filterResetValues);
    setAccuracies({});
    setImage(undefined);
    setImageInfo({});
    setExcludedFields(excludedFieldsInitialValues);
    onReset();
  };

  const handleExport = async ({ exportSettings }) => {
    await onExport({ ...getValues(), ...accuracies, ...imageInfo, ...exportSettings });
    closeExportForm();
  };
  const axlesValidator = (value) => {
    return ((value === undefined || (value <= 10 && value >= 0)) || t('Введите значение от {{min}} до {{max}}', { min: 0, max: 10 }));
  };

  const selectedBrandIds = values.vehicleBrandId.map(({ value }) => value);
  const modelOptions = values.vehicleBrandId.length === 0
    ? vehicleModelOptions
    : vehicleModelOptions.filter(({ vehicleBrandId }) => (
      selectedBrandIds.includes(vehicleBrandId)
    ));
  const submitIsDisabled = values.imageId === 'processing';
  const count = Object.entries(values).filter(([_, v]) => v !== '' && v?.length).length;
  const isFiltersSelected = count > 0;

  return (
    <div className={cn({
      [st.Col]: true,
      [st.Col_invisible]: !isVisible,
    })}
    >
      <div className={st.Icon_fullHeight}>
        {isVisible && (
        <DownloadIcon
          className={cn({
            [st.Icon]: true,
            [st.Icon_active]: true,
            [st.Icon_disabled]: !isFiltersSelected,
          })}
          onClick={() => {
            if (!isFiltersSelected) return;
            setExportFormIsOpen(true);
          }}
        />
        )}
        {!isVisible && (
        <div className={st.Count_invisible}>
          <FilterIcon className={st.Icon} />
          {count}
        </div>
        )}
        <ArrowStraightLeftIcon
          className={cn({
            [st.Icon]: true,
            [st.Icon_active]: true,
            [st.Icon_opened]: isVisible,
          })}
          onClick={() => setIsVisible(!isVisible)}
        />
        <div />
      </div>

      <Page className={cn({
        [st.Page]: true,
        [st.Page_invisible]: !isVisible,
      })}
      >

        <Modal
          isOpen={accuracyModalIsOpen}
          onRequestClose={closeAccuracyModal}
          appElement={document.getElementById('root')}
        >
          <AccuracyForm
            onSubmit={onAccuracyFormSubmit}
            accuracies={accuracies}
            onDelete={(accuracy) => {
              onDeleteAccuracy(accuracy);
              closeAccuracyModal();
            }}
            {...selectedField}
          />
        </Modal>

        <Modal
          isOpen={searchByImageModalIsOpen}
          onRequestClose={closeSearchByImageModal}
          appElement={document.getElementById('root')}
        >
          <SearchByImageForm
            {...imageInfo}
            image={image}
            onUpload={onSearchByImageUpload}
            onSubmit={onSearchByImageFormSubmit}
          />
        </Modal>

        <Modal
          isOpen={classifyImageModalIsOpen}
          onRequestClose={closeClassifyImageModal}
          appElement={document.getElementById('root')}
        >
          <ClassifyImageForm
            onUpload={onClassifyImageUpload}
            onSubmit={onClassifyImageFormSubmit}
          />
        </Modal>

        <Modal
          isOpen={exportFormIsOpen}
          onRequestClose={closeExportForm}
          appElement={document.getElementById('root')}
        >
          <ExportForm recordType="event" onSubmit={handleExport} />
        </Modal>
        <form>
          <div className={st.Flex}>
            <h5>{t('Фильтры')}</h5>
            <div>
              <FilterIcon className={st.Icon} />
              {count}
              <CrossIcon
                className={cn({
                  [st.Icon]: true,
                  [st.Icon_active]: true,
                  [st.Icon_padding]: true,
                })}
                onClick={$onReset}
              />
            </div>
          </div>

          <Divider small />
          <div className="Subtitle-1">{t('Общие')}</div>
          <Divider small />

          <SettingFilter label={t('Дата от')}>
            <Control.DateInput
              id="registeredAfter"
              name="registeredAfter"
              control={control}
              enableTime
            />
          </SettingFilter>
          <SettingFilter label={t('Дата до')}>
            <Control.DateInput
              id="registeredBefore"
              name="registeredBefore"
              control={control}
              enableTime
            />
          </SettingFilter>
          <Divider small />

          <SettingFilter
            label={t('Сценарий')}
            isExclude={excludedFields.scenarioId}
            onExclude={(isExclude) => onExclude({ isExclude, name: 'scenarioId' })}
          >
            <Control.Select
              isMulti
              id={excludedFields.scenarioId ? 'scenarioIdExclude' : 'scenarioId'}
              name={excludedFields.scenarioId ? 'scenarioIdExclude' : 'scenarioId'}
              control={control}
              options={scenarioOptions}
              errors={errors}
              placeholder={t('Все')}
            />
          </SettingFilter>
          <SettingFilter
            label={t('Тип события')}
            isExclude={excludedFields.indicatorTypeId}
            onExclude={(isExclude) => onExclude({ isExclude, name: 'indicatorTypeId' })}
          >
            <Control.Select
              isMulti
              id={excludedFields.indicatorTypeId ? 'indicatorTypeIdExclude' : 'indicatorTypeId'}
              name={excludedFields.indicatorTypeId ? 'indicatorTypeIdExclude' : 'indicatorTypeId'}
              control={control}
              options={scenarioIndicatorTypeOptions}
              errors={errors}
              placeholder={t('Все')}
            />
          </SettingFilter>
          <SettingFilter
            label={t('Камеры')}
            isExclude={excludedFields.camId}
            onExclude={(isExclude) => onExclude({ isExclude, name: 'camId' })}
          >
            <Control.Select
              isMulti
              id={excludedFields.camId ? 'camIdExclude' : 'camId'}
              name={excludedFields.camId ? 'camIdExclude' : 'camId'}
              control={control}
              options={camOptions}
              errors={errors}
              placeholder={t('Все')}
            />
          </SettingFilter>

          <SettingFilter
            label={t('ID событий')}
            isExclude={excludedFields.recordId}
            onExclude={(isExclude) => onExclude({ isExclude, name: 'recordId' })}
          >
            <Control.Input
              id={excludedFields.recordId ? 'recordIdExclude' : 'recordId'}
              {...register(excludedFields.recordId ? 'recordIdExclude' : 'recordId', {
                pattern: {
                  value: UUID_PATTERN,
                  message: t('Допустим только UUID формат ввода через запятую'),
                },
              })}
              errors={errors}
            />
          </SettingFilter>
          <SettingFilter
            label={t('Списки')}
            isExclude={excludedFields.listId}
            onExclude={(isExclude) => onExclude({ isExclude, name: 'listId' })}
          >
            <Control.Select
              isMulti
              id={excludedFields.listId ? 'listIdExclude' : 'listId'}
              name={excludedFields.listId ? 'listIdExclude' : 'listId'}
              control={control}
              options={listOptions}
              errors={errors}
              placeholder={t('Все')}
            />
          </SettingFilter>

          <Divider small />
          <div className={st.Inline}>
            <div className={cn('Subtitle-1', st.FiltersLabel)}>{t('Транспортное средство')}</div>
            <Tooltip tooltipProps={{ effect: 'solid', place: 'top' }} data-tip={t('Поиск по изображению')} id="searchByImage">
              <CameraIcon
                className={cn({
                  [st.Icon]: true,
                  [st.Icon_active]: true,
                  [st.Icon_right]: true,
                  [st.Icon_full]: image,
                })}
                onClick={onShowSearchByImageForm}
              />
            </Tooltip>
            <Tooltip tooltipProps={{ effect: 'solid', place: 'top' }} data-tip={t('Автозаполнение фильтров по изображению')} id="filtersAutogenerate">
              <FileTextIcon
                className={cn({
                  [st.Icon]: true,
                  [st.Icon_active]: true,
                  [st.Icon_disabled]: !can(role, 'events:autocomplete'),
                })}
                onClick={onShowClassifyImageForm}
              />
            </Tooltip>
          </div>
          <Divider small />

          <SettingFilter
            label={t('Экстренные службы')}
            accuracyName="vehicleEmergencyTypeAccuracy"
            onClick={onShowAccuracyForm}
            accuracies={accuracies}
            onDelete={onDeleteAccuracy}
            isExclude={excludedFields.vehicleEmergencyTypeId}
            onExclude={(isExclude) => onExclude({ isExclude, name: 'vehicleEmergencyTypeId' })}
          >
            <Control.Select
              isMulti
              id={excludedFields.vehicleEmergencyTypeId ? 'vehicleEmergencyTypeIdExclude' : 'vehicleEmergencyTypeId'}
              name={excludedFields.vehicleEmergencyTypeId ? 'vehicleEmergencyTypeIdExclude' : 'vehicleEmergencyTypeId'}
              control={control}
              options={vehicleEmergencyTypeOptions}
              errors={errors}
            />
          </SettingFilter>
          <SettingFilter
            label={t('Спец. техника')}
            accuracyName="specialTransportTypeAccuracy"
            onClick={onShowAccuracyForm}
            accuracies={accuracies}
            onDelete={onDeleteAccuracy}
            isExclude={excludedFields.specialTransportTypeId}
            onExclude={(isExclude) => onExclude({ isExclude, name: 'specialTransportTypeId' })}
          >
            <Control.Select
              isMulti
              id={excludedFields.specialTransportTypeId ? 'specialTransportTypeIdExclude' : 'specialTransportTypeId'}
              name={excludedFields.specialTransportTypeId ? 'specialTransportTypeIdExclude' : 'specialTransportTypeId'}
              control={control}
              options={specialTransportTypeOptions}
              errors={errors}
            />
          </SettingFilter>
          <SettingFilter
            label={t('Общ. транспорт')}
            accuracyName="publicTransportTypeAccuracy"
            onClick={onShowAccuracyForm}
            accuracies={accuracies}
            onDelete={onDeleteAccuracy}
            isExclude={excludedFields.publicTransportTypeId}
            onExclude={(isExclude) => onExclude({ isExclude, name: 'publicTransportTypeId' })}
          >
            <Control.Select
              isMulti
              id={excludedFields.publicTransportTypeId ? 'publicTransportTypeIdExclude' : 'publicTransportTypeId'}
              name={excludedFields.publicTransportTypeId ? 'publicTransportTypeIdExclude' : 'publicTransportTypeId'}
              control={control}
              options={publicTransportTypeOptions}
              errors={errors}
            />
          </SettingFilter>
          <SettingFilter label={t('Количество осей')} accuracyName="vehicleAxlesAccuracy" onClick={onShowAccuracyForm} accuracies={accuracies} onDelete={onDeleteAccuracy}>
            <Control.Input
              id="vehicleAxlesAmount"
              {...register('vehicleAxlesAmount', {
                min: { value: 0, message: t('Значение должно быть больше или равно {{min}}', { min: 0 }) },
                max: { value: 10, message: t('Значение должно быть меньше или равно {{max}}', { max: 10 }) },
                validate: {
                  integerValidator,
                  axlesValidator,
                },
              })}
              errors={errors}
              type="number"
              min="0"
              max="10"
            />
          </SettingFilter>
          <SettingFilter
            label={t('Марки ТС')}
            accuracyName="vehicleBrandAccuracy"
            onClick={onShowAccuracyForm}
            accuracies={accuracies}
            onDelete={onDeleteAccuracy}
            isExclude={excludedFields.vehicleBrandId}
            onExclude={(isExclude) => onExclude({ isExclude, name: 'vehicleBrandId' })}
          >
            <Control.Select
              isMulti
              id={excludedFields.vehicleBrandId ? 'vehicleBrandIdExclude' : 'vehicleBrandId'}
              name={excludedFields.vehicleBrandId ? 'vehicleBrandIdExclude' : 'vehicleBrandId'}
              control={control}
              options={vehicleBrandOptions}
              errors={errors}
            />
          </SettingFilter>
          <SettingFilter
            label={t('Модели ТС')}
            accuracyName="vehicleModelAccuracy"
            onClick={onShowAccuracyForm}
            accuracies={accuracies}
            onDelete={onDeleteAccuracy}
            isExclude={excludedFields.vehicleModelId}
            onExclude={(isExclude) => onExclude({ isExclude, name: 'vehicleModelId' })}
          >
            <Control.Select
              isMulti
              id={excludedFields.vehicleModelId ? 'vehicleModelIdExclude' : 'vehicleModelId'}
              name={excludedFields.vehicleModelId ? 'vehicleModelIdExclude' : 'vehicleModelId'}
              control={control}
              options={modelOptions}
              errors={errors}
            />
          </SettingFilter>
          <SettingFilter
            label={t('Типы ТС')}
            accuracyName="vehicleTypeAccuracy"
            onClick={onShowAccuracyForm}
            accuracies={accuracies}
            onDelete={onDeleteAccuracy}
            isExclude={excludedFields.vehicleTypeId}
            onExclude={(isExclude) => onExclude({ isExclude, name: 'vehicleTypeId' })}
          >
            <Control.Select
              isMulti
              menuPlacement="top"
              id={excludedFields.vehicleTypeId ? 'vehicleTypeIdExclude' : 'vehicleTypeId'}
              name={excludedFields.vehicleTypeId ? 'vehicleTypeIdExclude' : 'vehicleTypeId'}
              control={control}
              options={vehicleTypeOptions}
              errors={errors}
            />
          </SettingFilter>
          <SettingFilter
            label={t('Цвета ТС')}
            accuracyName="vehicleColorAccuracy"
            onClick={onShowAccuracyForm}
            accuracies={accuracies}
            onDelete={onDeleteAccuracy}
            isExclude={excludedFields.vehicleColorId}
            onExclude={(isExclude) => onExclude({ isExclude, name: 'vehicleColorId' })}
          >
            <Control.Select
              isMulti
              menuPlacement="top"
              id={excludedFields.vehicleColorId ? 'vehicleColorIdExclude' : 'vehicleColorId'}
              name={excludedFields.vehicleColorId ? 'vehicleColorIdExclude' : 'vehicleColorId'}
              control={control}
              options={vehicleColorOptions}
              errors={errors}
            />
          </SettingFilter>

          <Divider small />
          <div className="Subtitle-1">{t('ГРЗ')}</div>
          <Divider small />

          <SettingFilter label={t('Символы ГРЗ')} accuracyName="vehicleLicensePlateAccuracy" onClick={onShowAccuracyForm} accuracies={accuracies} onDelete={onDeleteAccuracy}>
            <Control.Input
              id="vehicleLicensePlate"
              {...register('vehicleLicensePlate', {
                pattern: {
                  value: !settings?.enabledUnicodeInput && LP_PATTERN,
                  message: t('Поле может содержать только английские буквы в верхнем регистре, цифры и знаки'),
                },
              })}
              errors={errors}
            />
          </SettingFilter>
          <SettingFilter
            label={t('Страна регистрации')}
            accuracyName="countryAccuracy"
            onClick={onShowAccuracyForm}
            accuracies={accuracies}
            onDelete={onDeleteAccuracy}
            isExclude={excludedFields.countryId}
            onExclude={(isExclude) => onExclude({ isExclude, name: 'countryId' })}
          >
            <Control.Select
              isMulti
              menuPlacement="top"
              id={excludedFields.countryId ? 'countryIdExclude' : 'countryId'}
              name={excludedFields.countryId ? 'countryIdExclude' : 'countryId'}
              control={control}
              options={countryOptions}
              errors={errors}
            />
          </SettingFilter>
          <Divider />

          <Divider />
          <div className={cn({
            [st.ButtonsPosition]: true,
            [st.ButtonsPosition_invisible]: !isVisible,
          })}
          >
            <div className={st.ButtonsContainer}>
              {imageInfo?.imageId
                ? (
                  <Control.Button
                    onClick={() => handleSubmit(() => onCreateTask({ ...values, ...accuracies, imageInfo }))()}
                    disabled={submitIsDisabled}
                  >
                    {t('Создать задачу')}
                  </Control.Button>
                )
                : (
                  <Control.Button
                    onClick={() => handleSubmit(() => onFilter({ ...values, ...accuracies }))()}
                    disabled={submitIsDisabled}
                  >
                    {t('Применить')}
                  </Control.Button>
                )}
              <Margin left>
                <Control.Button type="reset" onClick={$onReset} kind="warning">
                  {t('Сбросить')}
                </Control.Button>
              </Margin>
            </div>
          </div>
        </form>
      </Page>
    </div>
  );
}

export { EventsFilterForm };
