/* eslint-disable jsx-a11y/no-static-element-interactions */
/* eslint-disable jsx-a11y/click-events-have-key-events */
import React, { useCallback } from 'react';

import { Table, Panel, TableCells } from '@vlabs/uikit';
import cn from 'classnames';
import PropTypes from 'prop-types';
import { useTranslation } from 'react-i18next';
import { connect } from 'react-redux';

import { selectAccountRole } from 'features/accounts/selectors';
import { selectIncidents } from 'features/incidents/selectors';
import { selectScenarioOptions } from 'features/scenarios/selectors';
import { selectVehicleAttributes } from 'store/vehicle/selectors';

import CamCell from 'components/cam-cell/CamCell';
import InfoCell from 'components/info-cell/InfoCell';
import { updateParams, readIncident } from 'features/incidents/incidentsSlice';
import { can } from 'utils/can';

import './IncidentsPage.sass';

function IncidentsTable({
  scenarioIndicatorTypeOptions = [],
  scenarioOptions = [],
  incidentsData,
  setSince,
  setUntil,
  setPageSize,
  role,
  onShowScenario,
  isFiltersVisible,
  onReadIncident,
}) {
  const { t } = useTranslation();
  const canViewScenarios = can(role, 'scenarios:read');

  const renderIdCell = useCallback(
    ({ row, value }) => (
      <a className="IncidentsPage__Link" href={`/incidents/${row?.original?.id}`}>
        {value}
      </a>
    ), [],
  );

  const renderScenarioCell = useCallback(({ row, value: scenarioId }) => {
    if (canViewScenarios) {
      return (
        <div
          className="IncidentsPage__Link"
          onClick={() => onShowScenario(row?.original?.scenarioId)}
        >
          {scenarioOptions.find(({ value }) => value === scenarioId)?.label || ''}
        </div>
      );
    }

    return scenarioOptions.find(({ value }) => value === scenarioId)?.label || '';
  }, [canViewScenarios, onShowScenario, scenarioOptions]);

  return (
    <div
      className={cn({
        IncidentsPage__Col: true,
        IncidentsPage__Col_full: !isFiltersVisible,
      })}
    >
      <Panel>
        <Table
          {...incidentsData}
          paginationType="timeBased"
          setPageSize={setPageSize}
          setSince={setSince}
          setUntil={setUntil}
          onActions={{
            onClickRow: {
              handler: ({ id }) => onReadIncident(id),
              can: ({ isUnread }) => isUnread,
            },
          }}
          highlight={({ isUnread }) => !!isUnread}
          columns={[
            {
              Header: t('ID'),
              accessor: 'id',
              Cell: renderIdCell,
              width: 60,
            },
            {
              Header: t('Камера'), accessor: 'cam', Cell: CamCell, width: 40,
            },
            {
              Header: t('Дата'), accessor: 'registeredAt', Cell: TableCells.DateCell, width: 40,
            },
            {
              Header: t('Сценарий'),
              accessor: 'scenarioId',
              Cell: renderScenarioCell,
              width: 30,
            },
            {
              Header: t('Тип инцидента'),
              accessor: 'indicatorTypeId',
              Cell: ({ value: indicatorTypeId }) => scenarioIndicatorTypeOptions
                .find(({ value }) => value === indicatorTypeId)?.label || '',
              width: 30,
            },
            {
              Header: t('Информация'),
              accessor: ({
                vehicleBestshot,
                vehicleBrandAccuracy,
                vehicleBrandId,
                vehicleColorTypeId,
                vehicleColors,
                vehicleModelAccuracy,
                vehicleModelId,
                vehicleTypeAccuracy,
                vehicleTypeId,
                vehicleLicensePlate,
                vehicleLicensePlateAccuracy,
                vehicleLicensePlateBestshot,
                vehicleEmergencyTypeId,
                vehicleEmergencyTypeAccuracy,
                vehicleAxlesAmount,
                vehicleAxlesAccuracy,
                publicTransportTypeId,
                publicTransportTypeAccuracy,
                specialTransportTypeId,
                specialTransportTypeAccuracy,
                scenarioId,
                indicatorTypeId,
                licensePlateFeatures,
                numberOfObjects,
                detections,
              }) => ({
                vehicleBestshot,
                vehicleBrandAccuracy,
                vehicleBrandId,
                vehicleColorTypeId,
                vehicleColors,
                vehicleModelAccuracy,
                vehicleModelId,
                vehicleTypeAccuracy,
                vehicleTypeId,
                vehicleLicensePlate,
                vehicleLicensePlateAccuracy,
                vehicleLicensePlateBestshot,
                vehicleAxlesAmount,
                vehicleAxlesAccuracy,
                vehicleEmergencyTypeId,
                vehicleEmergencyTypeAccuracy,
                publicTransportTypeId,
                publicTransportTypeAccuracy,
                specialTransportTypeId,
                specialTransportTypeAccuracy,
                scenarioId,
                indicatorTypeId,
                licensePlateFeatures,
                numberOfObjects,
                detections,
              }),
              Cell: InfoCell,
            },
          ]}
        />
      </Panel>
    </div>
  );
}

const dropdownOptionPropTypes = PropTypes.shape({
  id: PropTypes.oneOfType([PropTypes.string, PropTypes.number]),
  value: PropTypes.oneOfType([PropTypes.string, PropTypes.number]),
});

IncidentsTable.propTypes = {
  scenarioIndicatorTypeOptions: PropTypes.arrayOf(dropdownOptionPropTypes),
  scenarioOptions: PropTypes.arrayOf(dropdownOptionPropTypes),
  incidentsData: PropTypes.shape({
    data: PropTypes.oneOfType([PropTypes.object, PropTypes.arrayOf(PropTypes.any)]),
  }),
  setSince: PropTypes.func,
  setUntil: PropTypes.func,
  setPageSize: PropTypes.func,
  role: PropTypes.number,
  onShowScenario: PropTypes.func,
  isFiltersVisible: PropTypes.bool,
  onReadIncident: PropTypes.func,
};
IncidentsTable.defaultProps = {
  scenarioIndicatorTypeOptions: [],
  scenarioOptions: [],
  incidentsData: {},
  setSince: undefined,
  setUntil: undefined,
  setPageSize: undefined,
  role: undefined,
  onShowScenario: undefined,
  isFiltersVisible: true,
  onReadIncident: undefined,
};

export default connect((state) => ({
  ...selectVehicleAttributes(state),
  scenarioOptions: selectScenarioOptions(state),
  incidentsData: selectIncidents(state),
  role: selectAccountRole(state),
}), (dispatch) => ({
  setPageSize: (pageSize) => dispatch(updateParams({ pageSize })),
  setSince: (since) => dispatch(updateParams({ since })),
  setUntil: (until) => dispatch(updateParams({ until })),
  onReadIncident: (id) => dispatch(readIncident(id)),
}))(IncidentsTable);
