import React from 'react';

import {
  Layout,
} from '@vlabs/uikit';
import PropTypes from 'prop-types';
import '../translations/i18n';
import { useDispatch, connect } from 'react-redux';
import {
  BrowserRouter,
  Switch,
  Route,
} from 'react-router-dom';

import { selectAccountRole, selectAccount } from 'features/accounts/selectors';
import { init as initExportSettings } from 'store/export-settings/store';

import { lunaCarsClient } from 'api';
import LoginPage from 'features/auth/LoginPage';
import ResetPasswordPage from 'features/auth/ResetPasswordPage';
import { fetchCamOptions, setCamsFetchInterval } from 'features/cams/camsSlice';
import { connectUnreadIncidentsCount, disconnectUnreadIncidentsCount } from 'features/incidents/incidentsSlice';
import { fetchListOptions } from 'features/lists/listsSlice';
import { fetchScenarioOptions } from 'features/scenarios/scenariosSlice';
import { fetchSettings } from 'store/app-settings/appSettingsSlice';
import { fetchVehicleOptions } from 'store/vehicle/vehicleAttributesSlice';

import Navigation from './Navigation';
import Routes from './router';

import './App.sass';

function App({ isAuthenticated, role }) {
  const dispatch = useDispatch();

  React.useEffect(() => {
    if (isAuthenticated) {
      dispatch(fetchVehicleOptions);
      dispatch(fetchCamOptions);
      dispatch(fetchListOptions);
      dispatch(fetchScenarioOptions);
      dispatch(setCamsFetchInterval);
      dispatch(fetchSettings);
      dispatch(initExportSettings);

      lunaCarsClient.wsIncidents.connect(null, null, { startClosed: false }, '10.16.4.226:18306');
      dispatch(connectUnreadIncidentsCount);
      lunaCarsClient.wsEvents.connect(null, null, { startClosed: false }, '10.16.4.226:18306');
      lunaCarsClient.wsExtendedEvents.connect(null, null, { startClosed: false }, '10.16.4.226:18306');
    }
    if (!isAuthenticated) {
      dispatch(disconnectUnreadIncidentsCount);
      lunaCarsClient.wsIncidents.disconnect();
      lunaCarsClient.wsEvents.disconnect();
      lunaCarsClient.wsExtendedEvents.disconnect();
    }
  }, [dispatch, isAuthenticated]);

  return (
    <BrowserRouter>
      <Layout.Base>
        {!isAuthenticated && (
          <Switch>
            <Route path="/reset-password/:token" component={ResetPasswordPage} />
            <Route path="*" component={LoginPage} />
          </Switch>
        )}
        {isAuthenticated && (
          <>
            <Navigation />
            <Layout.Content>
              <Routes role={role} />
            </Layout.Content>
          </>
        )}
      </Layout.Base>
    </BrowserRouter>
  );
}

App.propTypes = {
  isAuthenticated: PropTypes.bool,
  role: PropTypes.number,
};

App.defaultProps = {
  isAuthenticated: false,
  role: undefined,
};

export default connect((state) => ({
  isAuthenticated: selectAccount(state).isAuthenticated,
  role: selectAccountRole(state),
}))(App);
