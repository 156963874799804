import React from 'react';

import { CopyButton, Divider } from '@vlabs/uikit';
import PropTypes from 'prop-types';
import { useTranslation } from 'react-i18next';
import { toast } from 'react-toastify';

import st from './errors.module.sass';

const ErrorToast = ({
  error,
}) => {
  const { t } = useTranslation();
  // console.log(error);

  const copyValue = JSON.stringify({
    message: error?.message,
    stack: error?.stack,
  });

  return (
    <div className={st.Error}>
      <h5>{t('Неизвестная ошибка')}</h5>

      <p>
        <strong>{t('Message:')}</strong>
        {' '}
        {error?.message}
      </p>

      <p>
        <strong>{t('Stack:')}</strong>
      </p>

      <pre>
        {error?.stack}
      </pre>

      <Divider small />

      <p>
        <CopyButton label={t('Скопировать информацию об ошибке')} value={copyValue} />
      </p>
    </div>
  );
};

ErrorToast.propTypes = {
  error: PropTypes.instanceOf(Error).isRequired,
};

export { ErrorToast };
export const showErrorToast = (error) => {
  toast.error(<ErrorToast error={error} />);
};
