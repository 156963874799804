import React from 'react';

import PropTypes from 'prop-types';

import './ArrayCell.sass';

function ArrayCell({
  value,
}) {
  return (
    <div className="ArrayCell">
      {value.map((item) => (
        <span className="ArrayCell__Item" key={item}>
          {item}
        </span>
      ))}
    </div>
  );
}

ArrayCell.propTypes = {
  value: PropTypes.arrayOf(PropTypes.any),
};
ArrayCell.defaultProps = {
  value: [],
};
export default ArrayCell;
